import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import All from "./components/All";
import { setCurrentTab } from "../../redux/slices/CurrentTab";
import "./index.css";

import { Tabs } from "antd";
import MyFiles from "./components/FileList/MyFiles";
import Uploaded from "./components/FileList/Uploaded";
import Community from "./components/FileList/Community";
const { TabPane } = Tabs;

const Colection = function () {
  const currentTab = useSelector((state) => state.currentTab.tab);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(currentTab);

  const handleTabChange = (key) => {
    setActiveTab(key);
    dispatch(setCurrentTab(key));
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <Tabs
      activeKey={activeTab}
      onChange={handleTabChange}
      className="no-animation-tabs"
    >
      <TabPane tab="Recent Files" key="carousel">
        <All />
      </TabPane>
      {/* <TabPane tab="Uploaded" key="uploaded">
        <Uploaded />
      </TabPane>
      <TabPane tab="Generated" key="myfiles">
        <MyFiles />
      </TabPane> */}
    </Tabs>
  );
};
export default Colection;
