import { IonRouterLink } from "@ionic/react";
import "./style.css";

const AuthPrompt = ({ text, ctaText, link }) => {
  return (
    <p className="prompt_text">
      {text}{" "}
      <IonRouterLink routerLink={`/${link}`} className="cta_text">
        {ctaText}
      </IonRouterLink>
    </p>
  );
};

export default AuthPrompt;
