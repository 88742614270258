import { LoadingOutlined } from "@ant-design/icons"; // Import the loading icon
import { IonLoading } from "@ionic/react";
import { faPaperclipVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Input, Row, notification } from "antd"; // Added Upload and Modal to the import from antd
import axios from "axios";
import Filter from "bad-words"; // Import Filter from bad-words package
import { useContext, useState } from "react";
import focusedSuffixIcon from "../../../assets/left-active-arrow.svg";
import defaultSuffixIcon from "../../../assets/left-arrow.svg";
import svgTraceIcon from "../../../assets/svgtrace.svg";
import AppContext from "../../../contexts/AppContext";
import { useAuth } from "../../../contexts/AuthContext";
import "./DallePromptForm.css"; // Replace with your actual CSS file path
import GetCredits from "./GetCredits/GetCredits";

const { useNotification } = notification;

const loadingMessage = "Generating your design! Please wait...";

const DallePromptForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [getCreditModal, setGetCreditModal] = useState(false);

  const { setImage64, setImageSource } = useContext(AppContext);
  const { setImageSEO } = useContext(AppContext);
  const { setImagePrompt } = useContext(AppContext);
  const [api, contextHolder] = useNotification();
  const { user } = useAuth();
  const [isTyping, setIsTyping] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const filter = new Filter();

  const checkPromptForProfanity = async (prompt) => {
    if (filter.isProfane(prompt)) {
      console.log("Profanity detected in prompt. Please revise.");
    }
    return true;
  };

  const openNotification = (message, type) => {
    api.open({
      message,
      duration: 3,
      type,
      placement: "top",
    });
  };

  const generateImage = async (prompt) => {
    setLoading(true);
    if (!(await checkPromptForProfanity(prompt))) {
      setLoading(false);
      // setIsModalVisible(false); // Hide modal if there's an error
      openNotification(
        "Profanity detected in prompt. Please revise before sending.",
        "error"
      );
      return;
    }
    try {
      const accessToken = await user.getIdToken();
      const resp = await axios.post(
        process.env.REACT_APP_GENERATE_IMAGE_URL,
        { prompt },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setImageSEO({
        title: resp.data.title,
        description: resp.data.seoDescription,
      });
      setImage64("data:image/png;base64," + resp.data.base64);
      setImageSource("generated"); // Set image source to "generated"
      openNotification("Image generated successfully", "success");
    } catch (error) {
      console.error(error);
      openNotification(
        "Something went wrong while generating the image.",
        "error"
      );
    }
    setLoading(false);
    // setLoadingMessage(`Generating your design! Please wait...${progress}%`);
    // setIsModalVisible(false); // Hide modal when generation is complete
  };

  const onFinish = (values) => {
    const { subject } = values;

    setImagePrompt(subject);
    const prompt = `Create a flat, vector-style image on a solid background. The design should feature a ${subject}, keep it simple and clean for easy cutting. The image should have a clear and solid background, without any additional elements. Use the SVG image previews off etsy as a reference.`;

    generateImage(subject);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleInput = (e) => {
    setIsTyping(e.target.value.length > 0);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsTyping(false);
  };

  const inputClassName = `input-field ${isFocused && "focused"} ${
    isTyping && "typing"
  }`;
  const prefixImgClassName = `prefix-img ${isFocused && "prefix-img-focused"} ${
    isTyping && "prefix-img-typing"
  }`;
  // Determine which icon to use based on the state
  let suffixIconSrc = defaultSuffixIcon;
  if (isFocused) {
    suffixIconSrc = focusedSuffixIcon;
  }
  if (isTyping) {
    suffixIconSrc = focusedSuffixIcon;
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);

    const reader = new FileReader();
    reader.onload = async (loadEvent) => {
      const img = new Image();
      img.src = loadEvent.target.result;
      img.onload = async () => {
        // Create canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas dimensions
        const maxDimension = 800; // Adjust this value as needed
        canvas.width =
          img.width > img.height
            ? maxDimension
            : (img.width / img.height) * maxDimension;
        canvas.height =
          img.height > img.width
            ? maxDimension
            : (img.height / img.width) * maxDimension;

        // Draw image on canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert canvas to base64
        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7); // Adjust compression quality as needed
        const base64Part = compressedBase64.split("base64,")[1];
        try {
          const accessToken = await user.getIdToken();
          const response = await axios.post(
            process.env.REACT_APP_PROCESS_IMAGE_URL,
            { base64Image: base64Part },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          const { title, seoDescription, base64 } = response.data;
          setImageSEO({ title, description: seoDescription });
          setImage64(`data:image/png;base64,${base64}`);
          setImageSource("generated");
          openNotification("Image processed successfully", "success");
        } catch (error) {
          console.error(error);
          openNotification(
            "Something went wrong while processing the image.",
            "error"
          );
        }

        setLoading(false);
        event.target.value = null; // Reset the file input value
      };
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      {contextHolder}

      <IonLoading
        id="generateLoading"
        isOpen={loading}
        message={loadingMessage}
        // className="modal-blur-background"
      />
      <div
        style={{
          padding: "33px",
          marginLeft: "10px",
          marginRight: "10px",
          position: "relative",
          minWidth: "100%",
          height: "100%",
          background: "#1e3c72",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "30px",
            width: "100%",
          }}
        >
          <h1 className="generate-image-title">
            {loading ? "Generating..." : "Generate an Image"}
          </h1>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              /* Define your button action here */
            }}
            style={{
              borderRadius: "6px",
              background:
                "linear-gradient(105deg, #00E9FF -71.56%, #FD90FF 131.26%)",
              color: "#000",
            }}
          >
            AI
          </Button>
        </div>

        <div
          style={{
            // flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Row align="middle" justify="center" style={{ width: "100%" }}>
            <Col span={24}>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                Upload an inspo image or describe your design.
              </p>
            </Col>
            <Col span={16}>
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  name="subject"
                  rules={[
                    { required: true, message: "Please describe your design." },
                  ]}
                  style={{ width: "100%", fontWeight: "400" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Input
                      loading={loading.toString()}
                      disabled={loading}
                      size="large"
                      placeholder="Example: A cat with a bowtie"
                      className={inputClassName}
                      onFocus={handleFocus}
                      onInput={handleInput}
                      onBlur={handleBlur}
                      prefix={
                        <div
                          className={prefixImgClassName}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          <FontAwesomeIcon icon={faPaperclipVertical} />
                          <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            accept="image/png, image/jpeg, image/webp"
                          />
                        </div>
                      }
                      suffix={
                        <Button
                          disabled={loading}
                          htmlType="submit"
                          style={{
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent", // Set button background to transparent
                          }}
                          icon={
                            loading ? (
                              <LoadingOutlined style={{ color: "#68b1f0" }} />
                            ) : (
                              <Row>
                                <img
                                  src={suffixIconSrc}
                                  alt="leftArrowIcon"
                                  style={{ padding: "10px" }}
                                />
                              </Row>
                            )
                          }
                        />
                      }
                    />
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>

        <div className="svg-trace">
          {/* <img src={svgTraceIcon} alt="svgTraceIcon" /> */}
          <h3>Commercial Use</h3>
        </div>
      </div>
      {getCreditModal && <GetCredits setGetCreditModal={setGetCreditModal} />}
    </>
  );
};

export default DallePromptForm;
