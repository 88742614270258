import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  uploadedSVGsInStorage,
} from "../../../lib/firebase/firebase";
import { setUploadedFiles, appendUploadedFiles, setLastDoc, setHasMore } from "../../../redux/slices/UploadedFiles";
import "./styles.css";
import Card from "./Card";
import noDataImage from "../../../assets/no_data_image_2.svg";

const All = () => {
  const dispatch = useDispatch();
  const { uploadedFiles, lastDoc, hasMore } = useSelector((state) => state.uploadedFiles);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 24;
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const observerRef = useRef();
  const loadingRef = useRef(null);

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      getUploadedFiles();
    }
  }, [uploadedFiles.length]);

  const getUploadedFiles = async () => {
    try {
      setLoading(true);
      const { files, lastDoc: newLastDoc, hasMore: newHasMore } = await uploadedSVGsInStorage(null, ITEMS_PER_PAGE);
      dispatch(setUploadedFiles(files));
      dispatch(setLastDoc(newLastDoc));
      dispatch(setHasMore(newHasMore));
      setPage(1);
    } catch (error) {
      console.error("error getting files: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreData = async () => {
    if (loading || isLoadingMore || !hasMore) return;

    try {
      setIsLoadingMore(true);
      const result = await uploadedSVGsInStorage(lastDoc, ITEMS_PER_PAGE);
      
      if (result.files.length > 0) {
        dispatch(setLastDoc(result.lastDoc));
        const newHasMore = result.hasMore && result.files.length === ITEMS_PER_PAGE;
        dispatch(setHasMore(newHasMore));
        await dispatch(appendUploadedFiles(result.files));
        setPage(prev => prev + 1);
      } else {
        dispatch(setHasMore(false));
      }
    } catch (error) {
      console.error("error fetching more files: ", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const recentDesigns = useMemo(() => {
    const uploadedFilesArray = Array.isArray(uploadedFiles) ? uploadedFiles : [];
    return uploadedFilesArray
      .map((file) => ({
        ...file,
        timestamp: new Date(file.updatedAt),
      }))
      .sort((a, b) => b.timestamp - a.timestamp);
  }, [uploadedFiles]);

  const lastElementRef = useCallback((node) => {
    if (loading || isLoadingMore) return;
    
    if (observerRef.current) observerRef.current.disconnect();
    
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMoreData();
      }
    });

    if (node) observerRef.current.observe(node);
  }, [loading, isLoadingMore, hasMore]);

  if (recentDesigns.length === 0 && !loading) {
    return (
      <div className="no-data-container">
        <img src={noDataImage} alt="no data" style={{ maxWidth: '150px' }} />
        <p className="no-text">Get started by uploading or generating an image above.</p>
      </div>
    );
  }

  return (
    <div className="all-container">
      <div className="cards-container">
        <div className="inner-container">
          {recentDesigns.map((file, index) => (
            <div key={`${file.id}-${index}`}>
              <Card file={file} index={index} />
            </div>
          ))}
          
          {hasMore && (
            <div 
              ref={lastElementRef}
              style={{ 
                width: '100%',
                padding: '20px',
                textAlign: 'center',
                gridColumn: '1 / -1'
              }}
            >
              {isLoadingMore && 'Loading more...'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default All;
