import { IonCol, IonGrid, IonRadio, IonRadioGroup, IonRow } from "@ionic/react";
import { Download, Layers, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  removeImage,
  setSelectedImageIndex,
} from "../../redux/slices/BulkConversion";

const Sidebar = () => {
  const dispatch = useDispatch();
  const originalImages = useSelector(
    (state) => state.BulkConversion.originalImages
  );
  const convertedSvgs = useSelector(
    (state) => state.BulkConversion.convertedSvgs
  );
  const selectedImageIndex = useSelector(
    (state) => state.BulkConversion.selectedImageIndex
  );

  const handleDelete = () => {
    if (selectedImageIndex !== null) {
      dispatch(removeImage());
    }
  };

  const handleDownload = () => {
    if (
      selectedImageIndex !== null &&
      originalImages[selectedImageIndex].status === "completed"
    ) {
      const svg = convertedSvgs[selectedImageIndex];
      if (svg && svg.downloadSVGLink) {
        const link = document.createElement("a");
        link.href = svg.downloadSVGLink;
        link.download = `image-${selectedImageIndex}.svg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the DOM
      }
    }
  };

  return (
    <IonGrid
      style={{
        width: "100%",
        margin: "0px",
        padding: "0px",
        fontFamily: "Clash Grotesk",
        color: "#202020",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <IonRow
        style={{
          backgroundColor: "#3D5EB3",
          fontWeight: 700,
          fontSize: "16px",
          color: "#fff",
          padding: "14px",
          display: "flex",
          gap: "11px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Layers />
        Bulk Conversion
      </IonRow>

      <IonRow
        style={{
          fontSize: "10px",
          display: "flex",
          gap: "4px",
          padding: "0px 4px",
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <IonCol
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 4px",
            cursor:
              selectedImageIndex === null ||
              originalImages[selectedImageIndex]?.status !== "completed"
                ? "not-allowed"
                : "pointer",
            opacity:
              selectedImageIndex === null ||
              originalImages[selectedImageIndex]?.status !== "completed"
                ? 0.5
                : 1,
          }}
          onClick={handleDownload}
        >
          <Download />
          Download
        </IonCol>
        <IonCol
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 4px",
            cursor: selectedImageIndex === null ? "not-allowed" : "pointer",
            opacity: selectedImageIndex === null ? 0.5 : 1,
          }}
          onClick={handleDelete}
        >
          <Trash />
          Delete
        </IonCol>
      </IonRow>

      <IonRadioGroup
        value={selectedImageIndex}
        onIonChange={(e) => dispatch(setSelectedImageIndex(e.detail.value))}
        style={{
          padding: "8px 6px",
          background: "rgba(0, 0, 0, 0.04)",
          borderRadius: "8px",
          fontSize: "10px",
          fontWeight: 500,
          margin: "0 6px",
          border: "1px solid #E3E3E3",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {originalImages.map(({ image, status, name }, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #E3E3E3",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", padding: "4px" }}
            >
              <IonRadio value={index} style={{ marginRight: "8px" }} />
              <img
                src={image}
                style={{ width: "calc(100% - 28px)", height: "auto" }}
                alt={name}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px 6px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <span>{name}</span>
              <span
                style={{
                  textTransform: "capitalize",
                  padding: "2px 4px",
                  borderRadius: "2px",
                  fontSize: "8px",
                  backgroundColor:
                    status === "idle"
                      ? "#ccc"
                      : status === "started"
                        ? "#ffa500"
                        : status === "completed"
                          ? "#4caf50"
                          : status === "failed"
                            ? "#f44336"
                            : "#ccc",
                  color: "#fff",
                }}
              >
                {status}
              </span>
            </div>
          </div>
        ))}
      </IonRadioGroup>
    </IonGrid>
  );
};

export default Sidebar;
