import { IonRange } from "@ionic/react";

const circleSizes = [4, 6, 7, 8, 9, 10, 11, 12];

const SizeSelector = ({ minSize, maxSize, size, setSize, stepSize = 1 }) => {
  return (
    <div>
      <p
        style={{
          fontSize: "10px",
          fontWeight: 500,
          fontFamily: "Clash Grotesk",
          color: "#202020",
          marginBottom: 0,
        }}
      >
        Select Size
      </p>
      <IonRange
        min={minSize}
        max={maxSize}
        step={stepSize}
        value={size}
        onIonChange={(e) => setSize(e.detail.value)}
        style={{
          "--bar-background": "#8E8E8E",
          "--bar-background-active": "#8E8E8E",
          "--bar-height": "1px",
          "--bar-border-radius": "8px",
          "--knob-background": "#F453C4",
          "--knob-size": "18px",
          "--pin-background": "#e83e8c",
          "--height": "20px",
          "--knob-border-radius": "2px",
          "--knob-size": "7px",
          "--knob-box-shadow": "0px 0px 0px 1px #000",
          padding: 0,
          padding: 0,
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {circleSizes.map((size, index) => (
          <div
            key={index}
            style={{
              width: `${size}px`,
              height: `${size}px`,
              borderRadius: "50%",
              backgroundColor: "#202020",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SizeSelector;
