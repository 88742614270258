import { useContext, createContext } from "react";
import { auth } from "../lib/firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import usePremiumStatus from "../lib/stripe/usePremiumStatus";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, loading] = useAuthState(auth);
  const [userIsPremium, loadingStatus] = usePremiumStatus(user);
  const isLoading = loading || loadingStatus;

  const value = {
    user,
    userIsPremium,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}
