import { loadingController, alertController } from '@ionic/core';
import { isPlatform } from '@ionic/react';
import process from '../../../../../components/Converter/helpers/runonnx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
const { Dragger } = Upload;

const Resize = () => {

  const saveImage = async (dataUrl, scale, fileName) => {
    const newFileName = `${fileName.split('.')[0]}_${scale}x.png`;

    if (isPlatform('desktop') || isPlatform('mobileweb') || isPlatform('electron')) {
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = dataUrl;
      downloadLink.download = newFileName;

      const alert = await alertController.create({
        header: 'Success!',
        subHeader: 'The images have been resized.',
        buttons: [{
          text: 'Download',
          handler: () => {
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        }]
      });

      await alert.present();
    }
  }

  const uploadAction = async (file) => {
    if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
      console.log("Unsupported file format");
      return Upload.LIST_IGNORE; // Prevents the upload
    }

    const settings = await alertController.create({
      header: 'Resize Options',
      inputs: [
        { name: '2x', type: 'radio', label: '2x', value: '2x', checked: true },
        { name: '4x', type: 'radio', label: '4x', value: '4x', checked: false },
      ],
      buttons: [
        { text: 'Cancel', role: 'cancel', handler: () => console.log('Cancel clicked') },
        { text: 'Resize', handler: resizeHandler }
      ]
    });

    await settings.present();

    async function resizeHandler(scale) {
      const loading = await loadingController.create({
        message: 'Preparing files...',
        id: 'loading-msg',
      });
      await loading.present();

      const fileSizeMB = file.size / 1000000;
      if (fileSizeMB > 5) {
        await showAlert('File Too Large', 'File size exceeds 5MB. Please choose a smaller file.');
        loading.dismiss();
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(new Blob([file]));
      img.onload = async () => {
        let { width, height } = img;
        const aspectRatio = width / height;

        if (width > 750 || height > 750) {
          loading.message = 'Image too large, compressing image...';
          if (width > height) {
            width = 750;
            height = 750 / aspectRatio;
          } else {
            height = 750;
            width = 750 * aspectRatio;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, width, height);
        const imageData = context.getImageData(0, 0, width, height);

        const result = await process(imageData, scale === '2x' ? 2 : 4);
        loading.dismiss();
        await saveImage(result, scale, file.name);
      };
    }

    async function showAlert(header, subHeader) {
      const alert = await alertController.create({
        header,
        subHeader,
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  return (
    <div hidden className={`no-data `}>
      <h5>Resize Image</h5>
      <div className="custom-card">
        <div className="nft__item" style={{ height: "180px", margin: 0 }}>
          <div className="nft__item_wrap" style={{ height: `178px` }}>
            <Dragger
              id="ResizerInput"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              multiple={false}
              beforeUpload={uploadAction}
              showUploadList={false}
              style={{ marginTop: "-20px" }}
            >
              <p className="ant-upload-drag-icon">
                <FontAwesomeIcon icon={faUpload} size="3x" />
              </p>
              <p className="ant-upload-text">Click or drag image to this area</p>
            </Dragger>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resize;