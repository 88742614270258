import { Row, Col } from "antd";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faRedo } from "@fortawesome/free-solid-svg-icons";

const TopBar = ({ undo, redo, undoActions, redoActions }) => {
  return (
    <>
      <Col style={{ marginLeft: 10 }}>
        <Button
          type="text"
          disabled={undoActions <= 1}
          onClick={() => undo()}
          value="undo"
        >
          <Row justify="center">
            <FontAwesomeIcon icon={faUndo} size={"sm"} />
          </Row>
        </Button>
        <Button
          type="text"
          disabled={redoActions <= 0}
          onClick={() => redo()}
          value="redo"
        >
          <Row justify="center">
            <FontAwesomeIcon icon={faRedo} size={"sm"} />
          </Row>
        </Button>
      </Col>
    </>
  );
};

export default TopBar;
