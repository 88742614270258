import { IonCard, IonCardContent, IonIcon, IonInput } from "@ionic/react";
import { useState } from "react";
import TrashIcon from "../../../assets/Converter/Trash.svg";

const ColorLayer = ({ code, onChange, onRemove }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <IonCard
      style={{
        margin: 0,
        boxShadow: "none",
        border: isFocused ? "2px solid #5282FF" : "2px solid #E2E2E2",
        borderRadius: "8px",
      }}
    >
      <IonCardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "46px",
          padding: 0,
        }}
      >
        <div
          style={{
            width: "32px",
            height: "38px",
            backgroundColor: `#${code}`,
            borderRadius: "4px",
            marginLeft: "4px",
            marginRight: "6px",
            border: "1px solid #979797",
          }}
        ></div>
        <IonInput
          style={{
            flexGrow: 1,
            fontFamily: "sans-serif",
            fontSize: "14px",
          }}
          value={code}
          onIonChange={(e) => {
            console.log(e.target.value);
            onChange(e.target.value);
          }}
          onIonFocus={() => setIsFocused(true)}
          onIonBlur={() => setIsFocused(false)}
        />
        <IonIcon
          icon={TrashIcon}
          style={{
            fontSize: "20px",
            color: "#888",
            cursor: "pointer",
            padding: "15px 10px",
          }}
          onClick={onRemove}
        />
      </IonCardContent>
    </IonCard>
  );
};

export default ColorLayer;
