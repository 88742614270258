import { faCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../assets/logo1.svg";
import "./style.css#";

const AuthHeader = ({ text, flexStart }) => {
  return (
    <div className="card-header-auth">
      <div className={`logo-auth ${flexStart && "logo-auth-custom"} `}>
        <img src={logo} alt="logo" width="200px" />
        <div className="pro-tag">
          <FontAwesomeIcon
            style={{ marginRight: "5px" }}
            fontSize={"16px"}
            color="rgba(219, 165, 47, 1)"
            icon={faCrown}
          />
          <p>Pro</p>
        </div>
      </div>
      {text && (
        <div className="warm-welcome">
          <p>{text.heading}</p>
          <p>{text.text}</p>
        </div>
      )}
    </div>
  );
};

export default AuthHeader;
