import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadedFiles: [],
  lastDoc: null,
  hasMore: true,
};

export const uploadedSlice = createSlice({
  name: "uploadedSlice",
  initialState,
  reducers: {
    setUploadedFiles: (state, action) => {
      state.uploadedFiles = action.payload;
    },
    appendUploadedFiles: (state, action) => {
      state.uploadedFiles = [...state.uploadedFiles, ...action.payload];
    },
    setLastDoc: (state, action) => {
      state.lastDoc = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    addNewlyUploadedFile: (state, action) => {
      state.uploadedFiles.unshift(action.payload);
    },
    updateUploadedFileInRedux: (state, action) => {
      const { url, filePath, id } = action.payload;
      const fileToUpdate = state.uploadedFiles.find(
        (file) => file.filePath === filePath || file.id === id
      );
      if (fileToUpdate) {
        fileToUpdate.url = url;
        fileToUpdate.type = "uploaded";
      }
    },
    deleteUploadedFileInRedux: (state, action) => {
      state.uploadedFiles = state.uploadedFiles.filter(
        (file) => file.id !== action.payload.id
      );
    },
  },
});

export const {
  setUploadedFiles,
  appendUploadedFiles,
  setLastDoc,
  setHasMore,
  addNewlyUploadedFile,
  updateUploadedFileInRedux,
  deleteUploadedFileInRedux,
} = uploadedSlice.actions;
export default uploadedSlice.reducer;
