import { IonCol, IonContent, IonLoading, IonPage, IonRow } from "@ionic/react";
import "antd/dist/antd.css";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BulkConverter from "../../components/BulkConverter";
import { useBulkImageProcessing } from "../../components/BulkConverter/useBulkImageProcessing";
import Converter from "../../components/Converter";
import Explore from "../../components/Explore";
import Editor from "../../components/SvgEditor";
import WebNavbar from "../../components/WebNavbar";
import AppContext from "../../contexts/AppContext";
import { setBulkMode } from "../../redux/slices/BulkConversion";
import { useAppDispatch } from "../../redux/store";
import Carousel1 from "./components/Carousel/Carousel"; // Import Carousel1 component
import "./index.css";

const Home = () => {
  const [rerender, setRerender] = useState(1);
  const [svgStore, setSvgStore] = useState();
  const [converterOpen, setConverterOpen] = useState(false);
  const [image64, setImage64] = useState();
  const [imagePrompt, setImagePrompt] = useState();
  const [imageSource, setImageSource] = useState();
  const [imageSEO, setImageSEO] = useState();
  const [image64Resize, setImage64Resize] = useState();
  const [fileName, setFileName] = useState();
  const [loadingConverter, setLoadingConverter] = useState(false);
  const dispatch = useAppDispatch();
  const bulkMode = useSelector((state) => state.BulkConversion.bulkMode);
  const images = useSelector((state) => state.BulkConversion.originalImages);
  const convertedCount = useSelector(
    (state) => state.BulkConversion.convertedCount
  );
  const { setCurrentProcessingIndex } = useBulkImageProcessing();

  const contextValue = useMemo(
    () => ({
      svgStore,
      setSvgStore,
      image64,
      setImage64,
      imagePrompt,
      setImagePrompt,
      imageSource,
      setImageSource,
      imageSEO,
      setImageSEO,
      image64Resize,
      setImage64Resize,
    }),
    [svgStore, image64, imagePrompt, imageSource, imageSEO, image64Resize]
  );

  const handleAutoConvertClick = () => {
    document.getElementById("AutoConvertUpload").click();
  };

  const handleResizeImageClick = () => {
    document.getElementById("ResizerInput").click();
  };

  useEffect(() => {
    if (image64) {
      setLoadingConverter(true);
      handleAutoConvertClick();
    }
  }, [image64]);

  useEffect(() => {
    if (image64Resize) {
      handleResizeImageClick();
    }
  }, [image64Resize]);

  useEffect(() => {
    if (images.length >= 2) {
      dispatch(setBulkMode(true));
      setLoadingConverter(true);
    }
  }, [images]);

  useEffect(() => {
    if (images.length >= 2 && images.length === convertedCount) {
      setConverterOpen(true);
    }
    const loadingMsg = document.querySelector('[id^="loading-"][id$="-msg"]');
    if (images.length >= 2 && loadingMsg) {
      loadingMsg.textContent = `Converting images (${convertedCount}/${images.length})...`;
    }
  }, [images, convertedCount]);

  return (
    <IonPage>
      <IonLoading
        isOpen={loadingConverter}
        id={!bulkMode ? "loading-msg" : undefined}
        message={
          bulkMode
            ? `Converting images (${convertedCount}/${images.length})...`
            : "Opening Converter..."
        }
      />
      <AppContext.Provider value={contextValue}>
        {svgStore === undefined && images.length < 2 && !bulkMode && (
          <Converter
            loadingConverter={loadingConverter}
            setLoadingConverter={setLoadingConverter}
            converterOpen={converterOpen}
            setConverterOpen={setConverterOpen}
          />
        )}
        {(images.length >= 2 || bulkMode) && converterOpen && (
          <BulkConverter
            setLoadingConverter={setLoadingConverter}
            setConverterOpen={setConverterOpen}
            setCurrentProcessingIndex={setCurrentProcessingIndex}
          />
        )}
        {svgStore === undefined && !converterOpen && (
          <>
            <WebNavbar showFileName={false} />
            <IonContent className="ion-padding">
              {/* Use Carousel1 here and pass necessary props */}
              <Carousel1
                // data={someData} // Ensure you define 'someData' or the appropriate prop
                isUpload={true}
                setImage64={setImage64}
                setFileName={setFileName}
                setImage64Resize={setImage64Resize}
                setRerender={setRerender}
                rerender={rerender}
                fileName={fileName}
              />
              <div style={{ paddingTop: "10px", overflow: "hidden" }}>
                <IonRow>
                  <IonCol>
                    <Explore setRerender={setRerender} rerender={rerender} />
                  </IonCol>
                </IonRow>
              </div>
            </IonContent>
          </>
        )}
        {svgStore !== undefined && <Editor />}
      </AppContext.Provider>
    </IonPage>
  );
};

export default Home;
