import { useGesture } from "@use-gesture/react";
import { useEffect, useState } from "react";
import "./Canvas.css";
import Brush from "./components/Brush";
import { useSelector } from "react-redux";
import { incrementViewOrDownloadCount } from "../../../../lib/firebase/firebase";

const isCanvasDisabled = false;
const currentRotation = 0;

const Canvas = (props) => {
  const [renderState, setRenderState] = useState(0);
  const [currentScale, setCurrentScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [touchesState, setTouchesState] = useState(0);
  const currentFile = useSelector((state) => state.files.currentFile);

  function simulateSquareClicks(centerX, centerY, halfLength) {
    // draw on the canvas

    try {
      const numDivisions = 4;
      const topLeftX = centerX - halfLength;
      const topLeftY = centerY - halfLength;
      const topRightX = centerX + halfLength;
      const bottomRightX = centerX + halfLength;
      const bottomRightY = centerY + halfLength;
      const bottomLeftX = centerX - halfLength;

      const points = [];
      for (let i = 0; i < numDivisions; i++) {
        points.push([
          topLeftX + ((topRightX - topLeftX) / (numDivisions - 1)) * i,
          topLeftY,
        ]);
        points.push([
          topRightX,
          topLeftY + ((bottomRightY - topLeftY) / (numDivisions - 1)) * i,
        ]);
        points.push([
          topRightX - ((topRightX - bottomRightX) / (numDivisions - 1)) * i,
          bottomRightY,
        ]);
        points.push([
          bottomLeftX,
          bottomRightY - ((bottomRightY - topLeftY) / (numDivisions - 1)) * i,
        ]);
      }

      for (let i = 0; i < points.length; i++) {
        const [x, y] = points[i];
        var el = document.elementFromPoint(x, y);
        const evt = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
          clientX: x,
          clientY: y,
        });
        el.dispatchEvent(evt);
      }
    } catch (error) {
      // Handle the error here, or log it for debugging purposes.
      console.error("An error occurred while simulating square clicks:", error);
    }
  }

  const bind = useGesture({
    onDrag: ({ event, xy, delta, dragging, pinching, touches }) => {
      setTouchesState(touches);
      if (
        touches === 2 ||
        props.toolSelected === "move" ||
        props.layerSelected === "all"
      ) {
        setPosition({
          x: position.x + delta[0] / currentScale,
          y: position.y + delta[1] / currentScale,
        });
      }

      if (
        props.layerSelected !== "all" &&
        props.toolSelected === "erase" &&
        touches === 1
      ) {
        // // Get the coordinates of the point on the page where the mouse cursor is currently located

        // Get the coordinates of the point on the page where the mouse cursor is currently located
        let x = xy[0];
        let y = xy[1];
        // Specify the radius around the point
        let radius = props.eraserRadius;

        simulateSquareClicks(x, y, radius / 2);
        // Create an array to store the elements within the radius
        let elements = [];
        // Get all path elements on the page
        let allElements = document.getElementsByTagName("path");
        // Iterate over the elements and check if they are within the radius
        for (let i = 0; i < allElements.length; i++) {
          let element = allElements[i];
          // Get the bounding rectangles of the element
          let rects = element.getClientRects();
          // Iterate over the rectangles and check if any of them intersect with the circle defined by the radius
          for (let j = 0; j < rects.length; j++) {
            let rect = rects[j];
            // Calculate the distance between the center of the rectangle and the point
            let distance = Math.sqrt(
              Math.pow(x - (rect.left + rect.right) / 2, 2) +
                Math.pow(y - (rect.top + rect.bottom) / 2, 2)
            );
            // If the distance is within the radius amd the client rect w & h is smaller than the radius, add the element to the array
            if (distance <= radius) {
              elements.push(element);
              break;
            }
          }
        }
        // Log the elements if array is not empty
        if (elements.length > 0) {
          // get the index of the path from the class
          // loop through elements and get the index of the path
          const index = elements[0].className.baseVal.split("-")[1];
          deletePath(index);
        }
      }

      if (
        props.layerSelected !== "all" &&
        props.toolSelected === "vacuum" &&
        touches === 1
      ) {
        // Get the coordinates of the point on the page where the mouse cursor is currently located
        let x = xy[0];
        let y = xy[1];
        // Specify the radius around the point
        let radius = props.vaccumRadius;
        // Create an array to store the elements within the radius
        let elements = [];
        // Get all path elements on the page
        let allElements = document.getElementsByTagName("path");
        // Iterate over the elements and check if they are within the radius
        for (let i = 0; i < allElements.length; i++) {
          let element = allElements[i];
          // Get the bounding rectangles of the element
          let rects = element.getClientRects();
          // Iterate over the rectangles and check if any of them intersect with the circle defined by the radius
          for (let j = 0; j < rects.length; j++) {
            let rect = rects[j];
            // Calculate the distance between the center of the rectangle and the point
            let distance = Math.sqrt(
              Math.pow(x - (rect.left + rect.right) / 2, 2) +
                Math.pow(y - (rect.top + rect.bottom) / 2, 2)
            );
            // If the distance is within the radius amd the client rect w & h is smaller than the radius, add the element to the array
            if (
              distance <= radius &&
              rect.width < radius &&
              rect.height < radius
            ) {
              elements.push(element);
              break;
            }
          }
        }
        // Log the elements if array is not empty
        if (elements.length > 0) {
          // get the index of the path from the class
          // loop through elements and get the index of the path
          const index = elements[0].className.baseVal.split("-")[1];
          deletePath(index);
        }
      }
    },
    onPinch: ({ event, delta, touches, vxvy, last, origin, offset }) => {
      var svg = document.getElementById("svgtest");
      var group = svg.getElementById("transformGroup");
      var x = origin[0] - svg.getBoundingClientRect().left;
      var y = origin[1] - svg.getBoundingClientRect().top;
      group.setAttribute("transform-origin", x + " " + y);
      setCurrentScale(offset[0]);
    },
    onWheel: ({ event }) => {
      var svg = document.getElementById("svgtest");
      var group = svg.getElementById("transformGroup");
      var x = event.clientX - svg.getBoundingClientRect().left;
      var y = event.clientY - svg.getBoundingClientRect().top;
      group.setAttribute("transform-origin", x + " " + y);

      // Here we assume that the initial scale is 1
      // and that each wheel event changes the scale by a factor of 0.1
      // You might need to adjust these values for your own use case
      var newScale = Math.max(0.1, currentScale - event.deltaY * 0.01);
      setCurrentScale(newScale);
    },
  });

  useEffect(() => {
    (async () => {
      if (currentFile.isPublic)
        await incrementViewOrDownloadCount(currentFile.id, "view");
    })();
  }, [currentFile]);

  useEffect(() => {
    setRenderState(renderState + 1);
  }, [props.svg]);

  const changeColor = (i) => {
    var tempSvg = props.svg;
    tempSvg.children[i].attributes["fill"] = props.color;
    props.setSvg(tempSvg);
    setRenderState(renderState + 1);
  };

  const deletePath = (i) => {
    var tempSvg = JSON.parse(JSON.stringify(props.svg));
    tempSvg.children[props.layerSelected].children.splice(i, 1);
    props.setSvg(tempSvg);
    setRenderState(renderState + 1);
  };

  const clickHandler = (i) => {
    if (isCanvasDisabled) {
      return;
    }
    if (props.toolSelected === "fill") {
      changeColor(i);
    } else if (props.toolSelected === "cut") {
      deletePath(i);
    } else if (props.toolSelected === "erase") {
      deletePath(i);
    }
  };

  if (props.svg?.attributes && props.svgJoined?.attributes) {
    // if it is not erase or draw then render the svg
    return (
      <div
        {...bind()}
        className="svg-container"
        style={{ height: "100%", width: "100%", overflow: "hidden" }}
      >
        <svg
          id="svgtest"
          width={"100%"}
          height="100%"
          // if it is erase or vacuum, set the cursor to none
          style={{
            cursor:
              props.toolSelected === "erase" || props.toolSelected === "vacuum"
                ? "none"
                : props.cursor,
          }}
        >
          <g
            id="transformGroup"
            transform={`scale(${currentScale}) translate(${position.x} ${position.y}) rotate(${currentRotation})`}
          >
            <svg
              viewBox={props.svg.attributes.viewBox}
              id="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              width={props.svg.attributes.width}
              height={props.svg.attributes.height}
            >
              {props.layerSelected === "all" &&
                props.svgJoined.children.map((child, i) => {
                  return (
                    <path
                      key={i}
                      d={child.attributes["d"]}
                      stroke="none"
                      fill={child.attributes["fill"]}
                      fillRule={child.attributes["fill-rule"]}
                    />
                  );
                })}

              {props.layerSelected !== "all" && (
                <>
                  <path
                    d={
                      props.svgJoined.children[props.layerSelected].attributes[
                        "d"
                      ]
                    }
                    stroke="none"
                    fill={
                      props.svgJoined.children[props.layerSelected].attributes[
                        "fill"
                      ]
                    }
                    fillRule={
                      props.svgJoined.children[props.layerSelected].attributes[
                        "fill-rule"
                      ]
                    }
                  />
                  {props.svg.children[props.layerSelected].children.map(
                    (object, i) => (
                      <path
                        key={i}
                        className={`path-${i}`}
                        onClick={() => clickHandler(i)}
                        d={object.attributes["d"]}
                        strokeWidth="1"
                        stroke="none"
                        fill={object.attributes["fill"]}
                        fillOpacity="0%"
                      />
                    )
                  )}
                </>
              )}
            </svg>
          </g>
          {props.toolSelected === "erase" && touchesState !== 2 && (
            <Brush
              radius={props.eraserRadius}
              toolSelected={props.toolSelected}
            />
          )}
          {props.toolSelected === "vacuum" && touchesState !== 2 && (
            <Brush
              radius={props.vaccumRadius}
              toolSelected={props.toolSelected}
            />
          )}
        </svg>
      </div>
    );
  } else {
    return <div className="svg-container" key={props.svg}></div>;
  }
};

export default Canvas;
