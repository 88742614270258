import { QuestionCircleOutlined } from "@ant-design/icons";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button, Col, Row, Slider, Switch, Tooltip } from "antd";
import { useState } from "react";

function AdvancedSettings(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Col style={{ paddingRight: "10px" }}>
        <Button
          type="text"
          onClick={() => {
            setShowModal(true);
          }}
          style={{ width: "100%" }}
        >
          <Row justify="center">
            <FontAwesomeIcon icon={faSliders} text="Color" />
          </Row>
          <Row justify="center" style={{ fontSize: "10px", maxWidth: "20px" }}>
            Settings
          </Row>
        </Button>
      </Col>

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        style={{ "--height": "450px" }}
      >
        <IonToolbar>
          <IonTitle>Advanced Options</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>Done</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="ion-padding" style={{ margin: "15px" }}>
          <IonCol>
            <center>
              <Row>
                <Col span={24}>
                  Smoothness{" "}
                  <Slider
                    defaultValue={props.smoothness}
                    min={0}
                    max={1.34}
                    step={0.01}
                    onChange={(value) => {
                      props.setSmoothness(value);
                    }}
                  />
                </Col>
                <Col span={24}>
                  Despeckle{" "}
                  <Tooltip title="Remove small color blobs on the final image by increasing this slider.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                  <Slider
                    defaultValue={props.despeckle}
                    min={0}
                    max={
                      props.imageDimensions[0] / 4 > 20
                        ? props.imageDimensions[0] / 4
                        : 20
                    }
                    value={props.despeckle}
                    step={1}
                    onChange={(value) => {
                      props.setDespeckle(value);
                    }}
                  />
                </Col>
                <Col span={24}>
                  Simplify Curves{" "}
                  <Tooltip title="Increase how round and smooth curves appear with this slider.">
                    <QuestionCircleOutlined />{" "}
                  </Tooltip>{" "}
                  <Slider
                    defaultValue={props.simplifyCurves}
                    min={0}
                    max={1000}
                    onChange={(value) => {
                      props.setSimplifyCurves(value);
                    }}
                    step={0.01}
                  />
                </Col>
                {/* add a on off toggle for the AI*/}
                <Col span={24}>
                  AI{" "}
                  <Tooltip title="AI enhancement for low resolution images during conversion">
                    <QuestionCircleOutlined />{" "}
                  </Tooltip>{" "}
                  <Switch
                    defaultChecked={props.ai}
                    onChange={(value) => {
                      props.setAi(value);
                    }}
                  />
                </Col>
              </Row>
            </center>
          </IonCol>
        </IonContent>
      </IonModal>
    </>
  );
}

export default AdvancedSettings;
