import {
  faCaretLeft,
  faWandSparkles,
  faArrowsAlt,
  faSyncAlt,
  faImage,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useEffect, useLayoutEffect } from "react";
import "./Carousel.css";
import { IonRow, IonCol } from "@ionic/react"; // Import necessary Ionic components
import UploadSection from "../UploadSection"; // Adjust the import path as necessary
import ImageGenerator from "../../../../components/ImageGenerator";
import Resizer from "./components/Resizer"; // Adjust the import path as necessary
import { Button } from "antd"; // Import Ant Design Button component
import {
  faArrowUpRightAndArrowDownLeftFromCenter,
  faWand,
} from "@fortawesome/pro-regular-svg-icons";
import { IonModal } from "@ionic/react"; // Import Ionic Modal component
import { addImage, setBulkMode } from "../../../../redux/slices/BulkConversion";
import { useAppDispatch } from "../../../../redux/store";

const Carousel1 = ({
  setImage64,
  setFileName,
  setRerender,
  rerender,
  fileName,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const fileInputRef = useRef(null); // Reference to hidden file input
  const dispatch = useAppDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleResizerInputClick = () => {
    const element = document.getElementById("ResizerInput");
    element.click();
  };

  // Function to open the file picker
  const handleBulkConvertClick = () => {
    fileInputRef.current.click(); // Programmatically click the input
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length < 2) {
      alert("Please select at least 2 images to bulk convert");
      return;
    }
    dispatch(setBulkMode(true)); // Set bulk mode to true
    Array.from(files).forEach((file) => {
      if (["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageData = e.target.result;
          dispatch(addImage(imageData)); // Dispatch each image's data
        };

        reader.readAsDataURL(file); // Read each file
      }
    });
  };

  return (
    <div className="upload-container" style={{ textAlign: "center" }}>
      <div
        className="upload-section"
        style={{ width: "100%", marginBottom: "20px" }}
      >
        <UploadSection
          setImage={setImage64}
          setFileName={setFileName}
          background="/assets/convert_background.png"
          text1="Upload to Convert to SVG"
          text2="Click or drag a PNG, JPG, WEBP"
          id="AutoConvertUpload"
        />
      </div>
      <div
        className="tools-section"
        style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}
      >
        <Button
          className="pill-button"
          icon={
            <FontAwesomeIcon
              icon={faArrowUpRightAndArrowDownLeftFromCenter}
              style={{ paddingRight: "5px" }}
            />
          }
          onClick={handleResizerInputClick}
          style={{
            backgroundColor: "#E0E7FF",
            color: "#1E3A8A",
            border: "1px solid #1E3A8A",
            ":hover": {
              backgroundColor: "#C7D2FE", // Darker on hover
            },
          }}
        >
          Resize Image
        </Button>
        <Resizer />
        <>
          <Button
            className="pill-button"
            icon={
              <FontAwesomeIcon
                icon={faSyncAlt}
                style={{ paddingRight: "5px" }}
              />
            }
            onClick={handleBulkConvertClick}
            style={{
              backgroundColor: "#ffe6f2", // Lighter pink color
              color: "#721C24",
              border: "1px solid #721C24",
              ":hover": {
                backgroundColor: "#FFC0CB", // Changed to a more pink color
              },
            }}
          >
            Bulk Convert
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/jpeg, image/png, image/webp"
            onChange={handleFileChange}
            multiple={true}
            min={2}
          />
        </>
        <Button
          className="pill-button"
          icon={
            <FontAwesomeIcon
              icon={faWandSparkles}
              style={{ paddingRight: "5px" }}
            />
          }
          onClick={showModal}
          style={{
            backgroundColor: "#B2F5EA",
            color: "#234E52",
            border: "1px solid #234E52",
            ":hover": {
              backgroundColor: "#81E6D9", // Darker on hover
            },
          }}
        >
          Generate Image
        </Button>
        {/* Add more buttons as needed */}
      </div>
      <IonModal
        isOpen={isModalVisible}
        onDidDismiss={handleCancel}
        cssClass="my-custom-class"
        style={{
          "--height": "250px",
          "--border-radius": "15px",
        }}
      >
        <div
          style={{ position: "relative", height: "100%", borderRadius: "15px" }}
        >
          <Button
            onClick={handleCancel}
            icon={<FontAwesomeIcon icon={faTimes} />}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1000,
              background: "transparent",
              border: "none",
              color: "white",
              fontSize: "20px",
              padding: "5px",
              minWidth: "auto",
              height: "auto",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              height: "100%",
            }}
          >
            <ImageGenerator />
          </div>
        </div>
      </IonModal>
    </div>
  );
};

export default Carousel1;
