export function hello() {
  return "hello";
}

// write a function that converts int to RGBA
function intToRGBA(int) {
  return {
    r: (int >> 24) & 255,
    g: (int >> 16) & 255,
    b: (int >> 8) & 255,
    a: int & 255,
  };
}

// write a function that converts RGBA to int
function RGBAToInt(rgba) {
  return (rgba.r << 24) + (rgba.g << 16) + (rgba.b << 8) + rgba.a;
}

export function closestColor(hex, COLORS, numLayers) {
  //rgba['r']
  const rgba = intToRGBA(hex);

  const r = rgba["r"];
  const g = rgba["g"];
  const b = rgba["b"];
  var cr = COLORS[0][0];
  var cg = COLORS[0][1];
  var cb = COLORS[0][2];

  var minIndex = 0;
  var minColorDiff = Math.abs(
    (r - cr) ** 2 * 0.3 + (g - cg) ** 2 * 0.59 + (b - cb) ** 2 * 0.11
  );

  for (let i = 1; i < numLayers; i++) {
    cr = COLORS[i][0];
    cg = COLORS[i][1];
    cb = COLORS[i][2];

    const colorDiff = Math.abs(
      (r - cr) ** 2 * 0.3 + (g - cg) ** 2 * 0.59 + (b - cb) ** 2 * 0.11
    );
    // console.log('colorDif, min',colorDiff, ',' ,minColorDiff);

    if (colorDiff <= minColorDiff) {
      minColorDiff = colorDiff;
      minIndex = i;
    }
  }

  return {
    r: COLORS[minIndex][0],
    g: COLORS[minIndex][1],
    b: COLORS[minIndex][2],
    a: 1,
  };
}

export function exactColor(hex, COLORS, numLayers) {
  const rgba = intToRGBA(hex);

  //   Convert the hexadecimal color value to RGB
  const r = rgba["r"];
  const g = rgba["g"];
  const b = rgba["b"];

  // Loop through each color in the COLORS array and check if it matches the hexadecimal color
  for (let i = 0; i < numLayers; i++) {
    // Convert the color to RGB
    let colorR = COLORS[i][0];
    let colorG = COLORS[i][1];
    let colorB = COLORS[i][2];

    // Check if the color is within 1% of the hexadecimal color
    if (
      Math.abs(r - colorR) <= 10 &&
      Math.abs(g - colorG) <= 10 &&
      Math.abs(b - colorB) <= 10
    ) {
      return { r: colorR, g: colorG, b: colorB, a: 1 };
    }
  }

  return false;
}

export function rgbToHex(r, g, b) {
  function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function rgbToHex2(r, g, b) {
  if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
  return ((r << 16) | (g << 8) | b).toString(16);
}

// Function to convert Blob to base64
export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
