const { loadStripe } = require("@stripe/stripe-js");

let stripePromise = null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );
  }
  return stripePromise;
};

export default initializeStripe;
