import { useState, useEffect } from "react";
import { isPlatform } from "@ionic/react";

function Brush(props) {
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);
  const [left, setLeft] = useState();
  const [top, setTop] = useState();
  const [isTouchDown, setIsTouchDown] = useState(false);
  const [isMouseIn, setIsMouseIn] = useState(false); // New state variable

  useEffect(() => {
    const svg = document.getElementById("svgtest");
    // svg.style.cursor = 'none'; // Hide the cursor

    if (isPlatform("ios") && !isPlatform("mobileweb")) {
      setIsMouseIn(true); // Mouse is inside the SVG
    }
    if (isPlatform("mobileweb")) {
      setIsMouseIn(true); // Mouse is inside the SVG
    }
    const { left, top } = svg.getBoundingClientRect();
    setLeft(left);
    setTop(top);
    svg.addEventListener("touchstart", handleTouchStart);
    svg.addEventListener("touchmove", handleTouchMove);
    svg.addEventListener("touchend", handleTouchEnd);
    svg.addEventListener("mousedown", handleMouseDown);
    svg.addEventListener("mousemove", handleMouseMove);
    svg.addEventListener("mouseup", handleMouseUp);
    svg.addEventListener("mouseover", handleMouseOver);
    svg.addEventListener("mouseout", handleMouseOut); // New event listener

    return () => {
      svg.removeEventListener("touchstart", handleTouchStart);
      svg.removeEventListener("touchmove", handleTouchMove);
      svg.removeEventListener("touchend", handleTouchEnd);
      svg.removeEventListener("mousedown", handleMouseDown);
      svg.removeEventListener("mousemove", handleMouseMove);
      svg.removeEventListener("mouseup", handleMouseUp);
      svg.removeEventListener("mouseover", handleMouseOver);
      svg.removeEventListener("mouseout", handleMouseOut); // New event listener
    };
  });

  function handleTouchStart(e) {
    if (top && left) {
      setX(e.touches[0].clientX - left);
      setY(e.touches[0].clientY - top);
      setIsTouchDown(true);
    }
  }

  function handleMouseOver(e) {
    setX(e.clientX - left);
    setY(e.clientY - top);
    setIsMouseIn(true); // Mouse is inside the SVG
  }

  function handleMouseOut(e) {
    // New function
    setIsMouseIn(false); // Mouse is outside the SVG
  }

  function handleTouchMove(e) {
    if (isTouchDown === false) {
      setIsTouchDown(true);
    }
    if (isTouchDown) {
      setX(e.touches[0].clientX - left);
      setY(e.touches[0].clientY - top);
    }
  }

  function handleTouchEnd(e) {
    setIsTouchDown(false);
  }

  function handleMouseDown(e) {
    if (top && left) {
      setX(e.clientX - left);
      setY(e.clientY - top);
      setIsTouchDown(true);
    }
  }

  function handleMouseMove(e) {
    if (isTouchDown === false) {
      setIsTouchDown(true);
      setX(e.clientX - left);
      setY(e.clientY - top);
    }
    if (isTouchDown) {
      setX(e.clientX - left);
      setY(e.clientY - top);
    }
  }

  function handleMouseUp(e) {
    setIsTouchDown(false);
  }

  return (
    <>
      {x &&
        y &&
        isMouseIn && ( // Only render when the mouse is inside the SVG
          <rect
            x={x - props.radius / 2}
            y={y - props.radius / 2}
            width={props.radius}
            height={props.radius}
            fill="#FFFFFF"
            stroke="#000000"
            strokeWidth="1"
            display={isTouchDown ? "inline" : "none"}
          />
        )}
    </>
  );
}

export default Brush;
