import { IonButton, isPlatform } from "@ionic/react";
import DownArrowIcon from "../../../assets/SvgEditor/DownArrow.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";

const DownloadButton = ({ onClick, shareLink, loading }) => {
  return (
    <IonButton
      expand="block"
      onClick={onClick}
      style={{
        background: "#F453C4",
        color: "#F8F8F8",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 700,
        height: "52px",
        textTransform: "capitalize",
        borderRadius: "6px",
        marginTop: "40px",
      }}
    >
      {loading && (
        <FontAwesomeIcon
          icon={faSpinnerThird}
          spin
          style={{ marginRight: "8px" }}
        />
      )}
      {!loading && <img src={DownArrowIcon} alt="Download" />}
      {shareLink && "Create"}
      {!shareLink &&
        (isPlatform("ios") && !isPlatform("mobileweb") ? "Save" : "Download")}
    </IonButton>
  );
};

export default DownloadButton;
