import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertController } from "@ionic/core";
import { IonCol } from "@ionic/react";
import closeCircle from "../../../assets/close-circle.svg";

const ColorPreview = (props) => {
  return (
    <>
      {props.colors.map((color, index) => {
        return (
          <IonCol style={{ maxWidth: "50px", paddingTop: "0px" }}>
            <img
              src={closeCircle}
              style={{
                width: "20px",
                display: "inline",
                marginBottom: "-30px",
              }}
              alt="remove-circle"
              onClick={() => {
                var newColors = Object.assign([], props.colors);
                newColors.splice(index, 1);
                props.setColors(newColors);
              }}
            />
            <div
              key={index}
              style={{
                padding: "10px",
                marginRight: "5px",
                borderStyle: "solid",
                borderColor: "#f0f0f0",
                backgroundColor: "#" + color[0],
                width: "40px",
                height: "40px",
                marginLeft: "5px",
              }}
            ></div>
          </IonCol>
        );
      })}

      <IonCol
        onClick={async () => {
          const success = await alertController.create({
            header: "Tap the image to add colors",
            buttons: ["OK"],
          });
          await success.present();
        }}
        style={{ maxWidth: "50px", paddingTop: "0px" }}
      >
        <div style={{ color: "#8c8c8c", padding: "10px", marginTop: "17px" }}>
          <FontAwesomeIcon icon={faSquarePlus} size="2x" />
        </div>
      </IonCol>
    </>
  );
};

export default ColorPreview;
