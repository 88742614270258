import { Share } from "@capacitor/share";
import { isPlatform } from "@ionic/react";
import { Input, Checkbox, Popover } from "antd";
import { useContext, useEffect, useState } from "react";
import { stringify } from "svgson";
import { Filesystem } from "@capacitor/filesystem";
import AppContext from "../../../contexts/AppContext";
import DownloadButton from "./DownloadButton";
import { useSelector } from "react-redux";
import { incrementViewOrDownloadCount } from "../../../lib/firebase/firebase";
import { HexAlphaColorPicker } from "react-colorful";

function SVGtoPNGResizer({ svgJson, exportName, maintainAspectRatio, setMaintainAspectRatio }) {
  const { svgStore } = useContext(AppContext);
  const currentFile = useSelector((state) => state.files.currentFile);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [fillColor, setFillColor] = useState("#FFFFFF00");
  const defaultWidth = 1500;

  const updateAspectRatio = (svgJson) => {
    const svgWidth = svgJson.attributes.viewBox.split(" ")[2];
    const svgHeight = svgJson.attributes.viewBox.split(" ")[3];
    const newAspectRatio = svgHeight / svgWidth;
    setAspectRatio(newAspectRatio);
    setWidth(defaultWidth);
    setHeight(Math.round(defaultWidth * newAspectRatio));
  };

  useEffect(() => {
    if (svgJson) {
      updateAspectRatio(svgJson);
    } else {
      setWidth(defaultWidth);
      setHeight(
        aspectRatio ? Math.round(defaultWidth * aspectRatio) : defaultWidth
      );
    }
  }, [svgJson, defaultWidth]);

  useEffect(() => {
    if (maintainAspectRatio && aspectRatio) {
      setHeight(Math.round(width * aspectRatio));
    }
  }, [width, maintainAspectRatio, aspectRatio]);

  const handleWidthChange = (e) => {
    const newWidth = parseInt(e.target.value, 10);
    setWidth(newWidth);
    if (maintainAspectRatio && aspectRatio) {
      setHeight(Math.round(newWidth * aspectRatio));
    }
  };

  const handleHeightChange = (e) => {
    const newHeight = parseInt(e.target.value, 10);
    setHeight(newHeight);
    if (maintainAspectRatio && aspectRatio) {
      setWidth(Math.round(newHeight / aspectRatio));
    }
  };

  const handleExportPng = async () => {
    if (!svgJson) {
      return;
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const svgToSave = await stringify(svgJson);

    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svgToSave);

    img.onload = async function () {
      const ctx = canvas.getContext("2d");

      // If aspect ratio is not maintained, add padding with fill color
      if (!maintainAspectRatio) {
        // Fill the entire canvas with the selected fill color
        ctx.fillStyle = fillColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Calculate dimensions to maintain original aspect ratio
        const originalAspectRatio = img.height / img.width;
        let drawWidth = width;
        let drawHeight = height;
        let offsetX = 0;
        let offsetY = 0;

        if (width / height > img.width / img.height) {
          // Width is relatively larger, adjust width to maintain aspect ratio
          drawWidth = height * (img.width / img.height);
          offsetX = (width - drawWidth) / 2;
        } else {
          // Height is relatively larger, adjust height to maintain aspect ratio
          drawHeight = width * (img.height / img.width);
          offsetY = (height - drawHeight) / 2;
        }

        ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
      } else {
        // If maintaining aspect ratio, draw normally
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }

      const dataURL = canvas.toDataURL("image/png");
      const base64Data = btoa(svgToSave);

      if (
        !isPlatform("mobileweb") &&
        (isPlatform("ios") || isPlatform("android"))
      ) {
        Filesystem.writeFile({
          path: svgStore.uri,
          data: base64Data,
          recursive: true,
        }).then(async (svgUri) => {
          let pngLocation = svgUri.uri.replace(".svg", ".png");

          Filesystem.writeFile({
            path: pngLocation,
            data: dataURL,
            recursive: true,
          }).then(async (pngUri) => {
            await Share.share({
              title: "Share PNG",
              url: pngUri.uri,
              dialogTitle: "Share PNG",
            });
          });
        });
        if (currentFile.isPublic)
          await incrementViewOrDownloadCount(currentFile.id, "download");
      } else if (isPlatform("desktop") || isPlatform("mobileweb")) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = dataURL;
        a.download = `${exportName || "File Name"}.png`;
        a.click();
        a.parentNode.removeChild(a);
        if (currentFile.isPublic)
          await incrementViewOrDownloadCount(currentFile.id, "download");
      }
    };
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div className="dimensions-group">
        <div className="dimension-input">
          <label className="dimension-label">Width (px)</label>
          <Input
            value={width}
            type="number"
            onChange={handleWidthChange}
            min={10}
          />
        </div>
        <div className="dimension-input">
          <label className="dimension-label">Height (px)</label>
          <Input
            value={height}
            type="number"
            onChange={handleHeightChange}
            min={10}
          />
        </div>
      </div>

      <div className="aspect-ratio-checkbox">
        <Checkbox
          checked={maintainAspectRatio}
          onChange={(e) => setMaintainAspectRatio(e.target.checked)}
        >
          Maintain aspect ratio
        </Checkbox>
      </div>

      {!maintainAspectRatio && (
        <div className="fill-color-picker" style={{ marginTop: "16px" }}>
          <label
            className="dimension-label"
            style={{ marginBottom: "8px", display: "block" }}
          >
            Fill Color
          </label>
          <Popover
            trigger="click"
            content={
              <HexAlphaColorPicker color={fillColor} onChange={setFillColor} />
            }
            placement="right"
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid #CCC",
                borderRadius: 4,
                position: "relative",
                cursor: "pointer",
                backgroundImage: "linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(-45deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(-45deg, transparent 75%, #ccc 75%)",
                backgroundSize: "10px 10px",
                backgroundPosition: "0 0, 0 5px, 5px -5px, -5px 0px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: fillColor,
                  borderRadius: 4,
                }}
              />
            </div>
          </Popover>
        </div>
      )}

      <DownloadButton onClick={handleExportPng} />
    </div>
  );
}

export default SVGtoPNGResizer;
