import React from "react";
import { IonButtons, IonCol, IonGrid, IonRow } from "@ionic/react";
import { Button } from "antd";
import TickIcon from "../../../assets/Converter/Tick.svg";
import BackArrowIcon from "../../../assets/SvgEditor/BackArrow.svg";
import SaveIcon from "../../../assets/SvgEditor/Save.svg";
import DesktopToolbar from "./DesktopToolBar";
import { resetBulkConversionState } from "../../../redux/slices/BulkConversion";
import { useAppDispatch } from "../../../redux/store";
import { ChevronRight } from "react-feather";

const downloadPNG = () => {
  const canvas = document.getElementById("canvas");
  if (canvas) {
    const image = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const link = document.createElement("a");
    link.download = "download.png";
    link.href = image;
    link.click();
  }
};

const buttonStyles = {
  pngButton: {
    fontWeight: 600,
    fontFamily: "Clash Grotesk",
    color: "#F453C4",
    background: "#fff",
    border: "1px solid #F453C4",
    padding: "6px 12px",
    borderRadius: "4px",
    transition: "background-color 0.2s",
    cursor: "pointer",
  },
  previewButton: {
    background: "#F453C4",
    borderRadius: "4px",
    padding: "6px 12px",
    height: "32px",
    color: "#F8F8F8",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Clash Grotesk",
    display: "flex",
    transition: "background-color 0.2s",
    cursor: "pointer",
  },
  backButton: {
    width: "188px",
    height: "100%",
    borderRight: "1px solid #fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "11px",
    background: "transparent",
    cursor: "pointer",
    transition: "background-color 0.2s",
  },
};

const DesktopTopBar = ({
  scale,
  setScale,
  file,
  clearFields,
  crop,
  setIsCropVisible,
  previewLoading,
  setConverterOpen,
  setOpenPreviewModal,
}) => {
  const dispatch = useAppDispatch();

  // Using React's useState to manage hover states
  const [isPngHovered, setIsPngHovered] = React.useState(false);
  const [isPreviewHovered, setIsPreviewHovered] = React.useState(false);
  const [isBackHovered, setIsBackHovered] = React.useState(false);

  return (
    <IonGrid
      style={{
        backgroundColor: "#F4F4F4",
        width: "100%",
        padding: 0,
        color: "#202020",
        fontFamily: "Clash Grotesk",
      }}
    >
      <IonRow>
        <IonCol size="auto" style={{ padding: 0, width: "188px" }}>
          <IonButtons
            onClick={() => {
              clearFields();
              setConverterOpen(false);
              dispatch(resetBulkConversionState());
            }}
            onMouseEnter={() => setIsBackHovered(true)}
            onMouseLeave={() => setIsBackHovered(false)}
            style={{
              ...buttonStyles.backButton,
              backgroundColor: isBackHovered ? "#EBEBEB" : "transparent",
            }}
          >
            <img
              src={BackArrowIcon}
              style={{ width: "20px", height: "20px" }}
            />
            <span style={{ fontSize: "14px" }}>Back to files</span>
          </IonButtons>
        </IonCol>
        <IonCol style={{ marginLeft: "25px" }}>
          <DesktopToolbar
            scale={scale}
            setScale={setScale}
            file={file}
            crop={crop}
            setIsCropVisible={setIsCropVisible}
          />
        </IonCol>
        <IonCol
          style={{
            fontSize: "12px",
            display: "flex",
            gap: "6px",
            justifyContent: "flex-end",
            marginRight: "14px",
            padding: "10px 0",
          }}
        >
          <button
            style={{
              ...buttonStyles.pngButton,
              backgroundColor: isPngHovered ? "#F2F2F2" : "#fff",
            }}
            onMouseEnter={() => setIsPngHovered(true)}
            onMouseLeave={() => setIsPngHovered(false)}
            onClick={async () => downloadPNG()}
          >
            <img src={SaveIcon} alt="save" style={{ marginRight: "4px" }} />
            PNG
          </button>
          <Button
            disabled={previewLoading}
            onClick={() => setOpenPreviewModal(true)}
            onMouseEnter={() => setIsPreviewHovered(true)}
            onMouseLeave={() => setIsPreviewHovered(false)}
            style={{
              ...buttonStyles.previewButton,
              backgroundColor: isPreviewHovered ? "#E13CAF" : "#F453C4",
            }}
          >
            {previewLoading ? (
              <span>Loading...</span>
            ) : (
              <>
                <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  Next: Preview
                  <ChevronRight size={20} />
                </span>
              </>
            )}
          </Button>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DesktopTopBar;
