import React from 'react';
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { INITIAL_POSITION, INITIAL_SCALE } from "../../CONST";
import "./style.css";

const EditorFooter = ({ currentScale, setCurrentScale, setPosition }) => {
  const handleReset = () => {
    setPosition(INITIAL_POSITION);
    setCurrentScale(INITIAL_SCALE);
  };

  const handleZoomIn = () => {
    setCurrentScale((prevScale) => Math.min(prevScale + 0.1, 5)); // Max scale of 500%
  };

  const handleZoomOut = () => {
    setCurrentScale((prevScale) => Math.max(prevScale - 0.1, 0.1)); // Min scale of 10%
  };

  return (
    <div className="editor-footer">
      <div className="zoom-control">
        <button className="zoom-button" onClick={handleZoomOut}>-</button>
        <div className="zoom-value">{Math.round(currentScale * 100)}%</div>
        <button className="zoom-button" onClick={handleZoomIn}>+</button>
      </div>
      <Button
        icon={<FontAwesomeIcon icon={faRotateLeft} />}
        onClick={handleReset}
        className="reset-button"
      >
        Reset View
      </Button>
    </div>
  );
};

export default EditorFooter;
