import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ConvertedSVG {
  svg: string;
  svgPreview: string;
  downloadSVGLink: string;
  scaledImageHeight: number;
  scaledImageWidth: number;
}

interface BulkConversionState {
  originalImages: {
    image: string;
    status: "started" | "completed" | "failed" | "idle";
    name: string;
  }[];
  convertedSvgs: ConvertedSVG[];
  selectedImageIndex: number | null;
  bulkMode: boolean;
  convertedCount: number;
}

const initialState: BulkConversionState = {
  originalImages: [],
  convertedSvgs: [],
  selectedImageIndex: 0,
  bulkMode: false,
  convertedCount: 0,
};

export const bulkConversionSlice = createSlice({
  name: "bulkConversionSlice",
  initialState,
  reducers: {
    addImage: (state, action: PayloadAction<string>) => {
      const noOfImages = state.originalImages.length;
      state.originalImages.push({
        image: action.payload,
        status: "idle",
        name: "image - " + (noOfImages + 1),
      });
    },
    addConvertedSVG: (
      state,
      action: PayloadAction<{ svg: ConvertedSVG; index: number }>
    ) => {
      const { index, svg } = action.payload;
      // First check if the original image at that index still exists
      if (!state.originalImages[index]) {
        return;
      }
      state.convertedSvgs[index] = svg;
    },
    updateConversionStatus: (
      state,
      action: PayloadAction<{
        index: number;
        status: "started" | "completed" | "failed";
      }>
    ) => {
      const { index, status } = action.payload;
      if (state.originalImages[index]) {
        state.originalImages[index].status = status;
        if (status === "completed") state.convertedCount += 1;
      }
    },
    removeImage: (state) => {
      if (state.selectedImageIndex !== null) {
        state.originalImages.splice(state.selectedImageIndex, 1);
        state.convertedSvgs.splice(state.selectedImageIndex, 1);
        if (state.selectedImageIndex >= state.originalImages.length) {
          state.selectedImageIndex = state.originalImages.length - 1;
        }
        if (state.originalImages.length === 0) {
          state.selectedImageIndex = null;
        }
      }
    },

    setSelectedImageIndex: (state, action: PayloadAction<number | null>) => {
      state.selectedImageIndex = action.payload;
    },
    setBulkMode: (state, action: PayloadAction<boolean>) => {
      state.bulkMode = action.payload;
    },
    resetBulkConversionState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  addImage,
  addConvertedSVG,
  updateConversionStatus,
  removeImage,
  setSelectedImageIndex,
  setBulkMode,
  resetBulkConversionState,
} = bulkConversionSlice.actions;

export default bulkConversionSlice.reducer;
