import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where,
  orderBy,
  startAfter,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
// import firestore so i can store the files based off user accounts
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadString,
} from "firebase/storage";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";

import { getPublicSVGsWithAlgolia } from "../algolia/algolia";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export default app;

export const auth = getAuth(app);

export const handleSignUp = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );

  // Update the user profile with the provided name
  // await updateProfile(auth.currentUser, {
  //     displayName: name,
  // });

  // send email verification
  await sendEmailVerification(auth.currentUser);
  const db = getFirestore();
  const createdAt = new Date();
  await addDoc(collection(db, "users"), {
    uid: userCredential.user.uid,
    email,
    createdAt,
  });

  // Handle successful sign-up here
  console.log("User created:", userCredential.user);
};
export const handleSignIn = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  // Handle successful sign-in here
  console.log("User signed in:", userCredential.user);
};

export const signOutUser = async () => await signOut(auth);

export const resetPassworEmail = async (email) =>
  await sendPasswordResetEmail(auth, email);

export const verifyEmail = async () => {
  await sendEmailVerification(auth.currentUser);
};

const functions = getFunctions(app);
export const createPortalLinkFunc = httpsCallable(
  functions,
  "ext-firestore-stripe-payments-createPortalLink"
);

// Initialize Storage
const storage = getStorage(app);

export const uploadSVGToStorage = async (data, folder) => {
  // get uid from auth.currentUser
  const user = auth.currentUser;
  const userId = user.uid;
  const storageRef = ref(
    storage,
    `${userId}/${folder}/${new Date().getTime()}.svg`
  );
  const res = await uploadString(storageRef, data, "data_url");
  const sr = ref(storage, res.metadata.fullPath);
  const url = await getDownloadURL(sr);
  return { res, url };
};

export const writeSVGToDatabase = async (filePath, prompt = "", imageSEO) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("No authenticated user found");

    const userId = user.uid;

    // remove any quotations from seotitle and seodescription
    const seoTitle = imageSEO.title.replace(/"/g, "");
    const seoDescription = imageSEO.description.replace(/"/g, "");
    // Write directly to the 'svgMetadata' collection
    const docRef = await addDoc(collection(db, "svgMetadata"), {
      filePath,
      prompt, // Optional prompt parameter
      createdAt: serverTimestamp(), // Automatically generate the timestamp
      isPublic: true, // Assuming you want this to be public by default
      ownerUid: userId, // Owner's UID from the authenticated user
      shareLink: "", // Assume you will handle this separately or update later
      downloadCount: 0,
      seoTitle: seoTitle,
      seoDescription: seoDescription,
    });

    // console.log("SVG written to Database", docRef);
    return docRef;
  } catch (error) {
    console.error("Error writing SVG to Database", error);
    throw error; // Rethrow or handle as needed
  }
};

//updatesvg in storage
export const updateSVGInStorage = async (storageRef, data) => {
  const user = auth.currentUser;
  const userId = user.uid;
  console.log("userId: ", userId);
  const res = await uploadString(storageRef, data, "data_url");
  const sr = ref(storage, res.metadata.fullPath);
  const url = await getDownloadURL(sr);

  const response = {
    url,
    filePath: res.metadata.fullPath,
  };
  return response;
};

// get svg from database by file path
export const getSVGFromDatabaseByFilePath = async (filePath) => {
  const svgRef = collection(db, "svgMetadata");
  const q = query(svgRef, where("filePath", "==", filePath));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    console.log(doc.id, " => ", doc.data());
  });
};

export const getPublicSVGs = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("No authenticated user found");

    const svgMetadataRef = collection(db, "svgMetadata");
    const q = query(svgMetadataRef, where("isPublic", "==", true), limit(20));

    const querySnapshot = await getDocs(q);

    const svgFiles = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log({ svgFiles });
    return svgFiles;
  } catch (error) {
    console.error("Error fetching SVGs for user:", error);
    throw error;
  }
};

export const getSVGFromDatabase = async (docId) => {
  const docRef = doc(db, "svgMetadata", docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("SVG data:", docSnap.data());
    return docSnap.data();
  } else {
    console.log("No such document!");
    return null;
  }
};

export const downloadSVGFromStorage = async (storageRef) => {
  // const storageRef = ref(storage, `${userId}/svg_generated/${fileName}`);
  // const url = await getDownloadURL(storageRef);

  const url = await getDownloadURL(storageRef);
  return url;
};

export const listAllSVGsInStorage = async () => {
  const user = auth.currentUser;
  const userId = user.uid;
  const listRef = ref(storage, `${userId}/svg_generated`);
  const res = await listAll(listRef);
  return res;
};
export const listAllUploadedSVGs = async () => {
  const user = auth.currentUser;
  const userId = user.uid;
  const listRef = ref(storage, `${userId}/svg`);
  const res = await listAll(listRef);
  // const urls = await Promise.all(res.items.map(item => getDownloadURL(item)));
  return res;
};

// export const uploadedSVGsInStorage = async () => {
//   const user = auth.currentUser;
//   const userId = user.uid;
//   const listRef = ref(storage, `${userId}/svg`);
//   const res = await listAll(listRef);
//   return res;
// };

export const uploadedSVGsInStorage = async (inputDoc = null, pageSize = 12) => {
  const user = auth.currentUser;
  if (!user) throw new Error("No authenticated user found");

  try {
    const filesRef = collection(db, "accountData", user.uid, "files");
    let q = query(
      filesRef,
      orderBy("timeCreated", "desc"),
      limit(pageSize)
    );
    if (inputDoc) {
      console.log("lastDoc: ", inputDoc.id);
    }

    if (inputDoc) {
      q = query(
        filesRef,
        orderBy("timeCreated", "desc"),
        startAfter(inputDoc),
        limit(pageSize)
      );
    }

    const snapshot = await getDocs(q);
    const lastVisible = snapshot.docs[snapshot.docs.length - 1];

    const files = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const data = doc.data();
        try {
          const storageRef = ref(storage, data.filepath);
          const url = await getDownloadURL(storageRef);
          
          return {
            ...data,
            id: doc.id,
            url,
            uri: storageRef,
            type: data.type || "uploaded",
            ownerUid: user.uid,
          };
        } catch (error) {
          console.error(`Error fetching file ${doc.id}:`, error);
          return null;
        }
      })
    );

    if (lastVisible) {
      console.log("lastVisible fetch: ", lastVisible.id);
    }

    return {
      files: files.filter(file => file !== null),
      lastDoc: lastVisible,
      hasMore: snapshot.docs.length === pageSize
    };
  } catch (error) {
    console.error("Error fetching uploaded files:", error);
    return { files: [], lastDoc: null, hasMore: false };
  }
};

// make a delete file function
export const deleteFileInStorage = async (fileName) => {
  const user = auth.currentUser;
  const userId = user.uid;
  const storageRef = ref(storage, `${userId}/svg_generated/${fileName}`);
  // use listAll to get all the files in the folder
  await deleteObject(storageRef);
};

// make a call to a cloud function
export const analyzeImageSEO = async (image64) => {
  const callFunction = httpsCallable(functions, "analyzeImageForSEO");
  const prompt =
    "Can you generate a 150 char SEO description for the attached image?";
  const resp = await callFunction({ image64, prompt });
  return resp;
};

// export const updateSVGInStorage = async (storageRef, data, svgId) => {
//   const user = auth.currentUser;
//   const userId = user.uid;
//   console.log("userId: ", userId);
//   const res = await uploadString(storageRef, data, "data_url");
//   console.log("SVG updated in Storage", res.doc.id);

//   // Fetch the updated SVG
//   const svgMetadataRef = collection(db, "svgMetadata");
//   const docRef = doc(svgMetadataRef, svgId);
//   const docSnap = await getDoc(docRef);

//   if (docSnap.exists()) {
//     console.log("Document data:", docSnap.data());
//     return { id: docSnap.id, ...docSnap.data() };
//   } else {
//     console.log("No such document!");
//     throw new Error("No such document!");
//   }
// };

// updatesvginstorage

export const fetchSVGsForUser = async () => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("No authenticated user found");

    const svgMetadataRef = collection(db, "svgMetadata");
    const q = query(svgMetadataRef, where("ownerUid", "==", user.uid));

    const querySnapshot = await getDocs(q);

    const svgFiles = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });

    return svgFiles;
  } catch (error) {
    console.error("Error fetching SVGs for user:", error);
    throw error;
  }
};

export const fetchFilesWithUrls = async () => {
  try {
    let svgFiles = await fetchSVGsForUser();

    // Sort by newest to oldest based on the createdAt timestamp
    svgFiles = svgFiles.sort(
      (a, b) => b.createdAt.seconds - a.createdAt.seconds
    );

    const storage = getStorage();

    const filesWithUrls = await Promise.all(
      svgFiles.map(async (file) => {
        try {
          const storageRef = ref(storage, file.filePath);
          const url = await getDownloadURL(storageRef);
          return {
            ...file,
            uri: storageRef,
            type: "generated",
            url, // Add the URL to the file object
            date: new Date(file.createdAt.seconds * 1000).toLocaleDateString(
              "en-US",
              {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }
            ),
            name: file.prompt,
          };
        } catch (error) {
          console.error(`Error fetching URL for file ${file.filePath}:`, error);
          return null; // Or handle differently
        }
      })
    );

    // Filter out any null values that were returned due to errors
    return filesWithUrls.filter((file) => file !== null);
  } catch (error) {
    console.error("Error fetching files:", error);
    throw error;
  }
};

export const fetchPublicFilesWithUrls = async () => {
  try {
    let svgFiles = await getPublicSVGsWithAlgolia();
    // Sort by newest to oldest based on the createdAt timestamp
    svgFiles = svgFiles.sort(
      (a, b) => b.createdAt.seconds - a.createdAt.seconds
    );

    const storage = getStorage();

    const filesWithUrls = await Promise.all(
      svgFiles.map(async (file) => {
        try {
          const storageRef = ref(storage, file.filePath);
          const url = await getDownloadURL(storageRef);
          return {
            ...file,
            uri: storageRef,
            url, // Add the URL to the file object
            date: new Date(file.createdAt.seconds * 1000).toLocaleDateString(
              "en-US",
              {
                month: "short",
                day: "numeric",
                year: "numeric",
              }
            ),
            name: file.prompt,
          };
        } catch (error) {
          console.error(`Error fetching URL for file ${file.filePath}:`, error);
          return null; // Or handle differently
        }
      })
    );

    // Filter out any null values that were returned due to errors
    return filesWithUrls.filter((file) => file !== null);
  } catch (error) {
    console.error("Error fetching files:", error);
    throw error;
  }
};

// Function to delete a file from both Firebase Storage and Firestore
export const deleteFileFromStorage = async (storageRef) => {
  const user = auth.currentUser;
  if (!user) throw new Error("User not authenticated");
  // Delete from Firebase Storage
  await deleteObject(storageRef);
  // File document in Firestore will be deleted using the storage trigger function, hence no need to delete here
};

// export const updateFilePublicStatus = (storageRef, isPublic) => {
//   const user = auth.currentUser;
//   if (!user) throw new Error("User not authenticated");

//   const filePath = storageRef._location.path_;
//   console.log(filePath);
//   const svgMetadataRef = collection(db, "svgMetadata");
//   const q = query(
//     svgMetadataRef,
//     where("ownerUid", "==", user.uid),
//     where("filePath", "==", filePath)
//   );

//   getDocs(q)
//     .then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         const svgDocRef = doc.ref;
//         updateDoc(svgDocRef, { isPublic: isPublic })
//           .then(() => {
//             console.log(
//               `File ${filePath} public status updated to ${isPublic}`
//             );
//           })
//           .catch((error) => {
//             console.error("Error updating document: ", error);
//           });
//       });
//     })
//     .catch((error) => {
//       console.error("Error getting documents: ", error);
//     });
// };
export const updateFilePublicStatus = async (storageRef, isPublic) => {
  const user = auth.currentUser;
  if (!user) throw new Error("User not authenticated");

  const svgMetadataRef = collection(db, "svgMetadata");
  const q = query(
    svgMetadataRef,
    where("ownerUid", "==", user.uid),
    where("filePath", "==", storageRef._location.path_)
  );

  try {
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      const svgDocRef = doc.ref;
      try {
        await updateDoc(svgDocRef, { isPublic: isPublic });
        return isPublic;
      } catch (error) {
        throw error;
      }
    }
  } catch (error) {
    throw error;
  }
  return !isPublic;
};

export const incrementDownloadCount = async (storageRef) => {
  const user = auth.currentUser;
  if (!user) throw new Error("User not authenticated");

  const filePath = storageRef._location.path_;
  const svgMetadataRef = collection(db, "svgMetadata");
  const q = query(
    svgMetadataRef,
    where("ownerUid", "==", user.uid),
    where("filePath", "==", filePath)
  );

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach(async (doc) => {
    const svgDocRef = doc.ref;
    const currentDownloadCount = doc.data().downloadCount || 0;
    await updateDoc(svgDocRef, { downloadCount: currentDownloadCount + 1 });
    console.log(`Download count for file ${filePath} incremented successfully`);
  });
};
/**
 * Increment the view or download count for a file in Firestore
 * @param {string} docId - The id of the file metadata document in firebase
 * @param {"view" | "download"} type - The type of count to increment (view or download)
 * @returns {Promise<void>}
 */
export const incrementViewOrDownloadCount = async (docId, type) => {
  const svgDocRef = doc(db, "svgMetadata", docId);

  if (type === "view") await updateDoc(svgDocRef, { viewCount: increment(1) });
  else if (type === "download")
    await updateDoc(svgDocRef, { downloadCount: increment(1) });
  console.log(`${type} count for file ${docId} incremented successfully`);
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    // The signed-in user info.
    const user = result.user;
    console.log("Google sign-in successful:", user);
    return user;
  } catch (error) {
    console.error("Google sign-in error:", error);
    throw error;
  }
};

export const signInWithApple = async () => {
  const provider = new OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log("Apple sign-in successful:", user);
    return user;
  } catch (error) {
    console.error("Apple sign-in error:", error);
    throw error;
  }
};
