import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";

const LayerSelector = (props) => {
  return (
    <IonGrid style={{ width: "100%" }}>
      <IonRow>
        <IonCol style={{ maxWidth: "50px", paddingTop: "0px" }}>
          <div
            style={{ padding: "10px", width: "40px", height: "40px" }}
            onClick={() => {
              props.setLayerSelected("all");
            }}
          >
            <FontAwesomeIcon
              icon={faLayerGroup}
              style={{
                color: props.layerSelected === "all" ? "#007aff" : "#000",
                fontSize: "20px",
              }}
            />
          </div>
        </IonCol>
        <div style={{ maxWidth: `calc(95% - 50px) `, overflowX: "auto" }}>
          <IonRow style={{ minWidth: `${props.colors.length * 50}px` }}>
            {props.colors.map((color, index) => {
              return (
                <IonCol
                  key={color}
                  style={{ maxWidth: "50px", paddingTop: "0px" }}
                >
                  <div
                    onClick={() => {
                      props.setLayerSelected(index);
                    }}
                    style={{
                      padding: "10px",
                      borderStyle: "solid",
                      borderColor:
                        props.layerSelected === index ? "#007aff" : "#f0f0f0",
                      backgroundColor: color,
                      width: "40px",
                      height: "40px",
                    }}
                  ></div>
                </IonCol>
              );
            })}
          </IonRow>
        </div>
      </IonRow>
    </IonGrid>
  );
};

export default LayerSelector;
