import { IonAlert, IonIcon, IonSpinner, IonToggle } from "@ionic/react";
import { closeOutline, ellipsisVertical } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../assets/delete_icon.svg";
import deleteHoverIcon from "../../../assets/delete_icon_hover.svg";
import downloadIcon from "../../../assets/download_icon.svg";
import downloadHoverIcon from "../../../assets/download_icon_hover.svg";
import AppContext from "../../../contexts/AppContext";
import {
  deleteFileFromStorage,
  downloadSVGFromStorage,
  incrementViewOrDownloadCount,
  updateFilePublicStatus,
} from "../../../lib/firebase/firebase";
import {
  deleteFileInRedux,
  setShowEditor,
  updateFileIsPublicStatus,
} from "../../../redux/slices/MyFiles";
import { deleteUploadedFileInRedux } from "../../../redux/slices/UploadedFiles";
import "./styles.css";

const Card = ({ file, index, onImageLoaded }) => {
  const [activeOverlayId, setActiveOverlayId] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { setSvgStore } = useContext(AppContext);
  const dispatch = useDispatch();
  const cardRef = useRef(null);
  const alertRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [hovered1, setHovered1] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const currentTab = useSelector((state) => state.currentTab.tab);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cardRef.current &&
        !cardRef.current.contains(event.target) &&
        (!alertRef.current || !alertRef.current.contains(event.target))
      ) {
        setActiveOverlayId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOverlay = (identifier) => {
    setActiveOverlayId((prev) => (prev === identifier ? null : identifier));
  };

  const deleteFile = async ({ uri, type, id }) => {
    try {
      console.log("deleting uri", uri);
      await deleteFileFromStorage(uri);
      dispatch(deleteUploadedFileInRedux({ id }))
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    if (onImageLoaded) {
      onImageLoaded();
    }
  };

  const handleDownloadClick = async (uri, name, docId) => {
    try {
      const url = await downloadSVGFromStorage(uri);
      const response = await fetch(url);
      const svgString = await response.text();

      const blob = new Blob([svgString], { type: "image/svg+xml" });
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement("a");

      document.body.appendChild(a);
      a.href = objectURL;
      a.download = name;
      a.click();

      // Clean up and remove the link
      a.parentNode.removeChild(a);
      URL.revokeObjectURL(objectURL);
      await incrementViewOrDownloadCount(docId, "download");
    } catch (error) {
      console.error("Error downloading SVG:", error);
    }
  };

  return (
    <div className="custom-card" ref={cardRef}>
      <div
        className="nft__item"
        style={{
          height: "175px",
          margin: 0,
        }}
      >
        {activeOverlayId === file.id && (
          <div className="overlay">
            <div className="close-btn" onClick={() => toggleOverlay(file.id)}>
              <IonIcon size="small" icon={closeOutline} slot="end" />
            </div>
            <div className="overlay-content">
              {currentTab !== "community" && (
                <div
                  className="nft__item_action nft__item_action--margin-bottom"
                  onClick={() => setShowDeleteAlert(true)}
                  onMouseEnter={() => setHovered(true)} // Step 2: Change state on mouse enter
                  onMouseLeave={() => setHovered(false)} // Step 3: Change state on mouse leave
                >
                  <img
                    src={hovered ? deleteHoverIcon : deleteIcon}
                    alt="deleteIcon"
                    className="overlay-icon"
                  />
                  <span>Delete</span>
                </div>
              )}
              <div
                className="nft__item_action nft__item_action--margin-bottom"
                onMouseEnter={() => setHovered1(true)} // Step 2: Change state on mouse enter
                onMouseLeave={() => setHovered1(false)}
                onClick={() =>
                  handleDownloadClick(file.uri, file.name, file.id)
                }
              >
                <img
                  src={hovered1 ? downloadHoverIcon : downloadIcon}
                  alt="downloadIcon"
                  className="overlay-icon"
                />
                <span>Download</span>
              </div>

              <IonAlert
                ref={alertRef}
                isOpen={showDeleteAlert}
                onDidDismiss={() => setShowDeleteAlert(false)}
                header="Are you sure?"
                message="This action cannot be undone."
                buttons={[
                  {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: () => {
                      console.log("cancelling");
                    },
                  },
                  {
                    text: "Delete",
                    handler: () => {
                      console.log("deleting file", file);
                      deleteFile({
                        uri: file.uri,
                        type: file.type,
                        id: file.id,
                      });
                    },
                  },
                ]}
              />
              {false && file.type === "generated" && (
                <div className="nft__item_action nft__item_action--flex">
                  <span style={{ color: "#f453c4" }}>Public</span>
                  <IonToggle
                    color="secondary"
                    checked={file.isPublic}
                    onIonChange={async (e) => {
                      const res = await updateFilePublicStatus(
                        file.uri,
                        !file.isPublic
                      );
                      if (res !== null) {
                        dispatch(
                          updateFileIsPublicStatus({
                            filePath: file.filePath,
                            isPublic: res,
                          })
                        );
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex-end">
          <div className="icon-wrapper">
            <IonIcon
              size="small"
              cursor="pointer"
              icon={ellipsisVertical}
              slot="end"
              onClick={() => toggleOverlay(file.id)}
            />
          </div>
        </div>
        <div className="nft__item_wrap" style={{ height: `178px` }}>
          <div className="image_div">
            {!imageLoaded && <IonSpinner name="circular" />}
            <img
              style={{
                maxHeight: `158px`,
                display: imageLoaded ? "block" : "none",
                width: "100%",
              }}
              src={file.url}
              onClick={() => {
                dispatch(setShowEditor({ currentFile: file }));
                setSvgStore({ firebase: file.uri });
              }}
              alt={`Uploaded ${index}`}
              onLoad={handleImageLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
