import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "carousel",
};

export const currentTab = createSlice({
  name: "currentTab",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { setCurrentTab } = currentTab.actions;
export default currentTab.reducer;
