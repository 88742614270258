import { createContext } from "react";

export const AppContext = createContext({
  svgStore: undefined,
  setSvgStore: () => {},
  image64: undefined,
  setImage64: (base64) => {},
  imagePrompt: undefined,
  setImagePrompt: (prompt) => {},
  imageSource: undefined,
  setImageSource: (source) => {},
  imageSEO: undefined,
  setImageSEO: (seo) => {},
  image64Resize: undefined,
  setImage64Resize: (base64) => {},
});

export default AppContext;
