import { getFirestore } from "firebase/firestore";
import { collection, doc, addDoc, onSnapshot } from "firebase/firestore";
import getStripe from "./initializeStripe";

const PRICE_ID = {
  designer: process.env.REACT_APP_DESIGNER_PRICE_ID,
  designerYearly: process.env.REACT_APP_DESIGNER_YEARLY_PRICE_ID,
  monthly: process.env.REACT_APP_MONTHLY,
  yearly: process.env.REACT_APP_YEARLY,
  lifetime: process.env.REACT_APP_LIFETIME,
  credits5: process.env.REACT_APP_5_CREDITS,
  credits10: process.env.REACT_APP_10_CREDITS,
  credits15: process.env.REACT_APP_15_CREDITS,
};

export async function createCheckoutSession(uid, subscriptionType) {
  const firestore = getFirestore();

  const trialEndTimestamp = new Date();
  trialEndTimestamp.setDate(trialEndTimestamp.getDate() + 7); // Add 7 days

  const checkoutSessionRef = await addDoc(
    collection(doc(firestore, "users", uid), "checkout_sessions"),
    {
      price: PRICE_ID[subscriptionType],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      mode: ["monthly", "yearly", "designer", "designerYearly"].includes(
        subscriptionType
      )
        ? "subscription"
        : "payment",
      trial_end: trialEndTimestamp.getTime() / 1000, // Convert to seconds
    }
  );

  onSnapshot(checkoutSessionRef, async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      try {
        const stripe = await getStripe();
        stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error("Error redirecting to Stripe checkout:", error);
      }
    }
  });
}
