import { Device } from "@capacitor/device";
import { Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import {
  IonContent,
  IonInput,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { Button, Input, message, Select, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { stringify } from "svgson";
import saveToFilesImg from "../../../assets/save_to_files.png";
import SVGtoPNGResizer from "./SVGtoPNGResizer";

import { CopyOutlined } from "@ant-design/icons";
import DownloadButton from "./DownloadButton";
import AppContext from "../../../contexts/AppContext";
import { incrementViewOrDownloadCount } from "../../../lib/firebase/firebase";
import { useSelector } from "react-redux";
import { useCopyToClipboard } from "usehooks-ts";

const ExportFile = ({ svgJoined, children, buttonType = "text", fileName }) => {
  const { svgStore } = useContext(AppContext);
  const currentFile = useSelector((state) => state.files.currentFile);
  const [showModal, setShowModal] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("svg");
  const [height, setHeight] = useState(200);
  const [publicLink, setPublicLink] = useState("");
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [, copy] = useCopyToClipboard();
  const [isHovered, setIsHovered] = useState(false);
  const [exportName, setExportName] = useState(() => {
    let modifiedFileName = fileName || "File Name";
    if (modifiedFileName.endsWith(".svg")) {
      modifiedFileName = modifiedFileName.replace(/\.svg$/, "");
    }
    return "SvgTrace-" + modifiedFileName;
  });

  // useeffecte selected format
  useEffect(() => {
    if (selectedFormat === "svg") {
      // if is platform ios
      if (isPlatform("ios") && !isPlatform("mobileweb")) {
        setHeight(450);
      } else {
        setHeight(245);
      }
    } else if (selectedFormat === "png") {
      setHeight(320);
    } else if (selectedFormat === "share") {
      if (isPlatform("ios") && !isPlatform("mobileweb")) {
        setHeight(300);
      } else {
        setHeight(430);
      }
    }
  }, [selectedFormat]);

  const handleCreate = async () => {
    setIsCreatingLink(true);
    let todaysDate = new Date().toISOString().split("T")[0];
    let isoDate = new Date().toISOString();
    let time = new Date(isoDate).getTime();
    const convertedBy = await Device.getId();
    let deviceType = "other";
    if (isPlatform("android")) deviceType = "AndroidApp";
    else if (isPlatform("ios")) deviceType = "IosApp";
    let fileName = `${deviceType}_${convertedBy.uuid}_${time}`;
    let extension = "svg";
    const bucketFolder = "svgtrace-upload-results-bucket";
    const svgToSave = stringify(svgJoined);

    try {
      const response = await fetch(
        `https://sikpmvrhv9.execute-api.us-east-1.amazonaws.com/Prod/${bucketFolder}/${todaysDate}/${fileName}.${extension}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "image/svg+xml",
          },
          body: svgToSave,
        }
      );

      const responseData = await response.json();
      console.log("responseData: ", responseData);
    } catch (error) {
      console.log("error: ", error);
    }

    var link =
      "https://svgtrace.com/share.html?date=" +
      todaysDate +
      "&fileName=" +
      fileName;
    console.log("Link:", link);
    setPublicLink(link);
    setIsCreatingLink(false);
    // if the platform is ios
    if (isPlatform("ios") && !isPlatform("mobileweb")) {
      await Share.share({
        title: "Share",
        url: link,
        dialogTitle: "Share",
      });
      setShowModal(false);
    }
  };

  const handleExportSvg = async () => {
    // create a png from the svg using canvas
    const canvas = document.createElement("canvas");
    const viewBoxValues = svgJoined.attributes.viewBox.split(" ");
    const originalWidth = parseFloat(viewBoxValues[2]);
    const originalHeight = parseFloat(viewBoxValues[3]);

    canvas.width = originalWidth;
    canvas.height = originalHeight;

    if (canvas.width < 1500) {
      canvas.width = 1500;
      canvas.height = (1500 / originalWidth) * originalHeight;
    }
    const svgToSave = stringify(svgJoined);
    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svgToSave);

    img.onload = async function () {
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL("image/png");

      const base64Data = btoa(svgToSave);

      if (isPlatform("ios") && !isPlatform("mobileweb")) {
        Filesystem.writeFile({
          path: svgStore.uri,
          data: base64Data,
          recursive: true,
        }).then(async (svgUri) => {
          let pngLocation = svgUri.uri.replace(".svg", ".png");
          console.log("pngLocation: ", pngLocation);

          Filesystem.writeFile({
            path: pngLocation,
            data: dataURL,
            recursive: true,
          }).then(async (pngUri) => {
            if (selectedFormat === "svg") {
              await Share.share({
                title: "Share SVG",
                url: svgUri.uri,
                dialogTitle: "Share SVG",
              });
            } else if (selectedFormat === "png") {
              await Share.share({
                title: "Share PNG",
                url: pngUri.uri,
                dialogTitle: "Share PNG",
              });
            }
          });
        });
        if (currentFile.isPublic)
          await incrementViewOrDownloadCount(currentFile.id, "download");
      } else if (
        isPlatform("electron") ||
        isPlatform("desktop") ||
        isPlatform("mobileweb")
      ) {
        // make a download link and click it
        const blob = new Blob([svgToSave], { type: "image/svg+xml" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");

        document.body.appendChild(a);
        a.href = url;
        // a.download = `svgtrace_${milliseconds}.svg`;
        a.download = exportName + ".svg";

        a.click();
        // Clean up and remove the link
        a.parentNode.removeChild(a);
        if (currentFile.isPublic)
          await incrementViewOrDownloadCount(currentFile.id, "download");
      }
    };

    setShowModal(false);
  };

  const handleCopyLink = async () => {
    try {
      await copy(publicLink);
      message.success("Link copied successfully!", 2);
    } catch (error) {
      console.error(error);
      message.error("Failed to copy link. Please try again.", 2);
    }
  };

  return (
    <>
      <button
        type={buttonType}
        onClick={() => setShowModal(true)}
        style={{
          fontWeight: 600,
          fontFamily: "Clash Grotesk",
          background: isHovered ? "#c7429f" : "#F453C4",
          transition: "background 0.3s ease",
          border: "1px solid #F453C4",
          color: "#F8F8F8",
          padding: "6px 12px",
          borderRadius: "4px",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </button>

      <IonModal
        id="export-modal"
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        style={{ "--height": `${height}px` }}
      >
        <IonToolbar>
          <IonTitle
            style={{
              color: "#2A2A2A",
              fontSize: "26px",
              fontWeight: 500,
              padding: 0,
            }}
          >
            Export Settings
          </IonTitle>
        </IonToolbar>
        <IonContent>
          <div style={{ display: "flex", gap: "8px" }}>
            {selectedFormat !== "share" && (
              <div style={{ flex: 1 }}>
                <IonLabel
                  style={{
                    color: "#666",
                    fontFamily: "Clash Grotesk",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Title
                </IonLabel>
                <IonInput
                  aria-label="File Name"
                  placeholder="File Name"
                  className="file-name-input"
                  value={exportName}
                  onIonChange={(e) => setExportName(e.target.value)}
                ></IonInput>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                width: selectedFormat === "share" ? "100%" : "auto",
              }}
            >
              <IonLabel
                style={{
                  color: "#666",
                  fontFamily: "Clash Grotesk",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                File Type
              </IonLabel>
              <Select
                value={selectedFormat}
                size="large"
                style={{
                  width: selectedFormat === "share" ? "100%" : 104,
                  border: "1px solid #D7D7D7",
                  borderRadius: "6px",
                  background: "#F8FAFF",
                  color: "#343434",
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                }}
                onChange={(value) => setSelectedFormat(value)}
                options={[
                  {
                    value: "svg",
                    label: "SVG",
                  },
                  {
                    value: "png",
                    label: "PNG",
                  },
                  {
                    value: "share",
                    label: "SHARE LINK",
                  },
                ]}
              />
            </div>
          </div>

          {/*  if svg is the selected format then render the following */}
          {selectedFormat === "svg" &&
            isPlatform("ios") &&
            !isPlatform("mobileweb") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // add padding on the top and bottom
                  padding: "10px 0",
                }}
              >
                <p style={{ color: "red" }}>
                  Note: Make sure to "Save to files" when exporting SVG
                </p>
                <img
                  style={{ maxHeight: "200px" }}
                  src={saveToFilesImg}
                  alt="Save to Files instructions"
                />
              </div>
            )}
          {selectedFormat === "png" && (
            <SVGtoPNGResizer svgJson={svgJoined} exportName={exportName} />
          )}

          {selectedFormat === "share" && (
            <div>
              <p
                style={{
                  // add padding on the top and bottom
                  padding: "20px 20px 20px 20px",
                }}
              >
                The link will be live for 1 month and will be publicly
                accessible to anyone with the link.
              </p>

              <DownloadButton
                onClick={handleCreate}
                shareLink={true}
                loading={isCreatingLink}
              />

              {/* if the platform is not ios */}
              {!(isPlatform("ios") && !isPlatform("mobileweb")) && (
                // make a link copy box
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // add padding on the top and bottom
                    padding: "10px 0",
                  }}
                >
                  <p>Copy the link below and share it with anyone.</p>
                  <Input.Group compact style={{ display: "flex" }}>
                    <Input
                      style={{
                        fontWeight: 700,
                        border: "1px solid #F453C4",
                      }}
                      value={publicLink}
                    />
                    <Tooltip title="copy svg link" color="#000">
                      <Button
                        style={{
                          border: "1px solid #F453C4",
                          background: "#F453C4",
                          color: "#fff",
                          opacity: publicLink ? 1 : 0.6,
                        }}
                        icon={<CopyOutlined />}
                        disabled={!publicLink}
                        onClick={async () => handleCopyLink()}
                      />
                    </Tooltip>
                  </Input.Group>
                </div>
              )}
            </div>
          )}
          {selectedFormat === "svg" && (
            <DownloadButton onClick={handleExportSvg} />
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ExportFile;
