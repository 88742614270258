import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonSpinner } from "@ionic/react";
import "./style.css";

const SubmitButton = ({ loading, onClick, title }) => {
  return (
    <button
      className="auth_button"
      type="submit"
      disabled={loading}
      onClick={onClick}
    >
      {loading ? (
        <IonSpinner name="lines" />
      ) : (
        <div>
          {title} <FontAwesomeIcon icon={faArrowRight} />
        </div>
      )}
    </button>
  );
};

export default SubmitButton;
