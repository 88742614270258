import { IonButtons, IonCol, IonGrid, IonRow } from "@ionic/react";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { ChevronLeft, Save } from "react-feather";
import { useSelector } from "react-redux";
import { resetBulkConversionState } from "../../redux/slices/BulkConversion";
import { useAppDispatch } from "../../redux/store";

const TopBar = ({ setConverterOpen, setCurrentProcessingIndex }) => {
  const dispatch = useAppDispatch();
  const originalImages = useSelector(
    (state) => state.BulkConversion.originalImages
  );
  const convertedSvgs = useSelector(
    (state) => state.BulkConversion.convertedSvgs
  );

  const allCompleted = originalImages.every(
    (img) => img.status === "completed"
  );

  const downloadAll = async () => {
    const zip = new JSZip();

    convertedSvgs.forEach((svg, index) => {
      zip.file(`image_${index + 1}.svg`, svg.svg);
    });

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "all_images.zip");
  };

  return (
    <IonGrid
      style={{
        backgroundColor: "#F4F4F4",
        width: "100%",
        padding: 0,
        color: "#202020",
        fontFamily: "Clash Grotesk",
      }}
    >
      <IonRow>
        <IonCol size="auto" style={{ padding: 0, width: "188px" }}>
          <IonButtons
            onClick={() => {
              dispatch(resetBulkConversionState());
              setConverterOpen(false);
              setCurrentProcessingIndex(null);
            }}
            style={{
              width: "188px",
              height: "100%",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "11px",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            <ChevronLeft size={20} />
            <span style={{ fontSize: "14px" }}>Back to files</span>
          </IonButtons>
        </IonCol>
        <IonCol
          style={{
            fontSize: "12px",
            display: "flex",
            gap: "6px",
            justifyContent: "flex-end",
            marginRight: "14px",
            padding: "10px 0",
          }}
        >
          <button
            style={{
              fontWeight: 600,
              fontFamily: "Clash Grotesk",
              color: "#FFF",
              background: allCompleted ? "#F453C4" : "#ccc",
              border: `1px solid ${allCompleted ? "#F453C4" : "#ccc"}`,
              padding: "6px 12px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              cursor: allCompleted ? "pointer" : "not-allowed",
            }}
            onClick={downloadAll}
            disabled={!allCompleted}
          >
            <Save size={16} style={{ marginRight: "4px" }} />
            Download All
          </button>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TopBar;
