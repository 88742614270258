import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  files: [],
};

export const communitySlice = createSlice({
  name: "communitySlice",
  initialState,
  reducers: {
    setCommunityFiles: (state, action) => {
      state.files = action.payload;
    },
  },
});

export const { setCommunityFiles } = communitySlice.actions;
export default communitySlice.reducer;
