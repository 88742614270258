import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "antd";

const IconButton = (props) => {
  const isSelected = props.isSelected || false;
  return (
    <Col>
      <Button
        type="text"
        onClick={props.onClickHandler}
        disabled={props.disabled ? props.disabled : false}
        style={{ minHeight: "50px" }}
      >
        <Row justify="center">
          <FontAwesomeIcon
            icon={props.icon}
            style={{ color: `${isSelected ? "#007aff" : "#000000"}` }}
          />
        </Row>
        <Row
          justify="center"
          style={{
            fontSize: "10px",
            paddingTop: "5px",
            // minHeight: '0px',
            // maxWidth: '50px',
          }}
        >
          {props.text}
        </Row>
      </Button>
    </Col>
  );
};

export default IconButton;
