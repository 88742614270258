import {
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../Converter/components/CropImage.css";

const initialCrop = {
  unit: "%", // Can be 'px' or '%'
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

const CropSvg = (props) => {
  const imgRef = useRef(null);
  const [modalHeight, setModalHeight] = useState("50%");
  const [crop, setCrop] = useState(initialCrop);
  const [completedCrop, setCompletedCrop] = useState(initialCrop);

  function onImageLoad(e) {
    imgRef.current = e.currentTarget;
    console.log(props.originalDimensions);
    if (!props.originalDimensions) return;
    const [width, height] = fitToModal(
      props.originalDimensions[0],
      props.originalDimensions[1]
    );
    setModalHeight(`${height + 56 * 3}px`);
    const image = document.getElementById("cropRefImage");
    image.width = width;
    image.height = height;

    const imageBackground = document.getElementById("imageBackground");
    imageBackground.style.width = `${width + 10}px`;
  }

  const fitToModal = (imageWidth, imageHeight) => {
    const exampleModal = document.getElementById("contentid");
    const containerWidth = exampleModal.offsetWidth - 50;
    const containerHeight = (2 * window.innerHeight) / 3;

    let scale = Math.min(
      containerWidth / imageWidth,
      containerHeight / imageHeight
    );

    if (scale < 1) {
      const scaledImageHeight = Math.floor(imageHeight * scale);
      const scaledImageWidth = Math.floor(imageWidth * scale);
      if (props.setScale) props.setScale(scale);
      return [scaledImageWidth, scaledImageHeight];
    }

    return [imageWidth, imageHeight];
  };

  const handleSave = () => {
    const img = new Image();
    img.onload = () => {
      const svgElement = document.getElementById("svgContainer");
      const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = svgElement
        .getAttribute("viewBox")
        .split(" ")
        .map((num) => Math.round(Number(num)));
      const newCrop = {
        unit: "px",
        x: viewBoxX + viewBoxWidth * (completedCrop.x / 100),
        y: viewBoxY + viewBoxHeight * (completedCrop.y / 100),
        width: viewBoxWidth * (completedCrop.width / 100),
        height: viewBoxHeight * (completedCrop.height / 100),
      };
      console.log({ newCrop, crop, completedCrop });
      const newViewBoxValue = `${newCrop.x} ${newCrop.y} ${newCrop.width} ${newCrop.height}`;
      svgElement.setAttribute("viewBox", newViewBoxValue);
      svgElement.setAttribute("height", newCrop.height);
      svgElement.setAttribute("width", newCrop.width);
      props.setCropGlobal(newCrop);
      setCrop(initialCrop);
      setCompletedCrop(initialCrop);
      const tempSvg = {
        ...props.svg,
        attributes: {
          ...props.svg.attributes,
          viewBox: newViewBoxValue,
          height: newCrop.height,
          width: newCrop.width,
        },
      };
      props.updateSvg(tempSvg);
      const newDimensions = [newCrop.width, newCrop.height];
      if (props.setImageDimensions) props.setImageDimensions(newDimensions);
      props.setOriginalDimensions(newDimensions);
      props.setIsModalVisible(false);
    };
    img.src = `${props.file}`;
  };

  return (
    <div className="test">
      <IonModal
        id="example-modal"
        style={{ "--height": modalHeight }}
        isOpen={props.isModalVisible}
      >
        <IonToolbar>
          <IonTitle>Crop</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.setIsModalVisible(false)}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent
          id="contentid"
          className="ion-padding"
          style={{ margin: "15px" }}
        >
          {Boolean(`${props.file}`) && props.originalDimensions && (
            <div
              id="imageBackground"
              style={{
                margin: "auto",
                backgroundColor: "#7f7f7f",
                padding: "10px",
                paddingBottom: "5px",
              }}
            >
              <ReactCrop
                crop={crop}
                ruleOfThirds={true}
                onChange={(_, percentCrop) => {
                  setCrop(percentCrop);
                }}
                onComplete={(c, percentCrop) => {
                  setCompletedCrop(percentCrop);
                }}
              >
                <img
                  id="cropRefImage"
                  alt="Crop me"
                  src={`${props.file}`}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          )}
          <IonButton
            style={{ marginTop: "15px" }}
            color="secondary"
            expand="block"
            key="back"
            onClick={handleSave}
          >
            Save
          </IonButton>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default CropSvg;
