import { IonIcon, IonPopover } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import AutoFillIcon from "../../../assets/SvgEditor/Autofill.svg";
import ColorFillIcon from "../../../assets/SvgEditor/ColorFill.svg";
import CropIcon from "../../../assets/SvgEditor/Crop.svg";
import DragIcon from "../../../assets/SvgEditor/DragMove.svg";
import EraseIcon from "../../../assets/SvgEditor/Erase.svg";
import RedoIcon from "../../../assets/SvgEditor/Redo.svg";
import SmartCutIcon from "../../../assets/SvgEditor/SmartCut.svg";
import UndoIcon from "../../../assets/SvgEditor/Undo.svg";
import VaccumIcon from "../../../assets/SvgEditor/Vaccum.svg";
import ColorPicker from "./ColorPicker";
import SizeSelector from "./SizeSelector";
import { useAuth } from "../../../contexts/AuthContext";

const Button = ({
  icon,
  label,
  disable,
  onClick,
  showRightIcon = false,
  isPopoverOpen,
  isSelected,
  styles,
  onArrowClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isIconHovered, setIsIconHovered] = useState(false);

  return (
    <button
      style={{
        display: "flex",
        gap: "2px",
        alignItems: "center",
        background:
          isSelected || isPopoverOpen
            ? "#E5E5E5"
            : isHovered
              ? "#eaeaea"
              : "transparent",
        fontFamily: "Clash Grotesk",
        fontSize: "10px",
        whiteSpace: "nowrap",
        opacity: disable ? "0.4" : "1",
        position: "relative",
        borderRadius: "8px",
        padding: "2px 10px",
        transition: "background-color 0.2s ease-in-out",
        cursor: disable ? "not-allowed" : "pointer",
        ...styles,
      }}
      disabled={disable}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          gap: "4px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {icon && (
          <img
            src={icon}
            alt={label}
            style={{ width: "20px", height: "20px" }}
          />
        )}
        <span>{label}</span>
      </div>
      {showRightIcon && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onArrowClick(e);
          }}
          style={{
            padding: "2px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            background: isIconHovered ? "#C2C2C2" : "transparent",
            borderRadius: "4px",
            transition: "background-color 0.3s ease",
          }}
          onMouseEnter={() => setIsIconHovered(true)}
          onMouseLeave={() => setIsIconHovered(false)}
        >
          <IonIcon
            icon={chevronDown}
            style={{
              background: isPopoverOpen ? "#C2C2C2" : "transparent",
              height: "18px",
              width: "18px",
              borderRadius: "4px",
              color: "#202020",
            }}
          />
        </div>
      )}
    </button>
  );
};

const DesktopToolbar = ({
  toolSelected,
  setToolSelected,
  layerSelected,
  setIsCropVisible,
  svg,
  updateSvg,
  undo,
  redo,
  redoActions,
  undoActions,
  colors,
  setColors,
  eraserRadius,
  setEraserRadius,
  vaccumRadius,
  setVaccumRadius,
  currentFile,
}) => {
  const isMultipleLayersSelected = layerSelected === "all";
  const { user } = useAuth();
  const isOwner = user.uid === currentFile.ownerUid;

  const popover = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [openPopoverButton, setOpenPopoverButton] = useState(null);

  const openPopover = (e, buttonLabel) => {
    popover.current.event = e;
    setPopoverOpen(true);
    setOpenPopoverButton(buttonLabel);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "z") {
        event.preventDefault();
        undo();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "y") {
        event.preventDefault();
        redo();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [undo, redo]);

  const tools = [
    {
      icon: UndoIcon,
      label: "Undo",
      onClick: undo,
      disable: undoActions <= 1,
    },
    {
      icon: RedoIcon,
      label: "Redo",
      onClick: redo,
      disable: redoActions <= 0,
    },
    {
      icon: CropIcon,
      label: "Crop",
      onClick: () => {
        document.getElementById("AutoConvertUpload").click();
        setIsCropVisible(true);
      },
      disable: !isMultipleLayersSelected,
    },
    {
      icon: DragIcon,
      label: "Pan",
      tool: "move",
      onClick: () => {
        setToolSelected("move");
      },
      disable: false,
    },
    {
      icon: SmartCutIcon,
      label: "Smart Cut",
      tool: "cut",
      onClick: () => {
        setToolSelected("cut");
      },
      disable: isMultipleLayersSelected,
    },
    {
      icon: EraseIcon,
      label: "Erase",
      tool: "erase",
      onClick: () => {
        setToolSelected("erase");
      },
      disable: isMultipleLayersSelected,
      showRightIcon: true,
    },
    {
      icon: VaccumIcon,
      tool: "vacuum",
      label: "Vaccum",
      onClick: () => {
        setToolSelected("vacuum");
      },
      disable: isMultipleLayersSelected,
      showRightIcon: true,
    },
    {
      icon: AutoFillIcon,
      label: "Auto Fill",
      onClick: () => {
        let largestBox = 0;
        let largestBoxIndex = 0;
        let svgCopy = JSON.parse(JSON.stringify(svg));
        let index = layerSelected;
        let layer = svgCopy.children[index];
        for (let i = 0; i < layer.children.length; i++) {
          let box = layer.children[i].width * layer.children[i].height;
          if (box > largestBox) {
            largestBox = box;
            largestBoxIndex = i;
          }
        }
        layer.children.splice(0, largestBoxIndex);
        layer.children.splice(1, layer.children.length - 1);
        updateSvg(svgCopy);
      },
      disable: isMultipleLayersSelected,
    },
    {
      icon: ColorFillIcon,
      label: "Color",
      disable: isMultipleLayersSelected,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        gap: "12px",
      }}
    >
      {tools.map((button, index) =>
        button.label === "Color" ? (
          <div key={index} style={{ position: "relative" }}>
            {(layerSelected && layerSelected !== "all") ||
            layerSelected === 0 ? (
              <ColorPicker
                svg={svg}
                updateSvg={updateSvg}
                colors={colors}
                layerSelected={layerSelected}
                setColors={setColors}
              >
                <Button
                  label={button.label}
                  disable={button.disable || (currentFile.isPublic && !isOwner)}
                  icon={button.icon}
                  isSelected={false}
                />
                <div
                  style={{
                    border: "1px solid #202020",
                    width: "12px",
                    height: "12px",
                    backgroundColor: colors[layerSelected],
                    borderRadius: "100%",
                    position: "absolute",
                    top: "6px",
                    right: "-2px",
                  }}
                />
              </ColorPicker>
            ) : (
              <Button
                label={button.label}
                disable={button.disable}
                icon={button.icon}
                isSelected={false}
              />
            )}
          </div>
        ) : (
          <Button
            key={index}
            icon={button.icon}
            label={button.label}
            disable={button.disable || (currentFile.isPublic && !isOwner)}
            onClick={button.onClick}
            showRightIcon={button.showRightIcon}
            isPopoverOpen={openPopoverButton === button.label}
            isSelected={toolSelected === button?.tool}
            onArrowClick={
              button.showRightIcon
                ? (e) => openPopover(e, button.label)
                : undefined
            }
          />
        )
      )}
      <IonPopover
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => {
          setPopoverOpen(false);
          setOpenPopoverButton(null);
        }}
        style={{
          "--offset-y": "16px",
          "--offset-x": "-32px",
        }}
      >
        <div style={{ padding: "4px 6px" }}>
          <SizeSelector
            minSize={10}
            maxSize={100}
            size={openPopoverButton === "Erase" ? eraserRadius : vaccumRadius}
            setSize={
              openPopoverButton === "Erase" ? setEraserRadius : setVaccumRadius
            }
          />
        </div>
      </IonPopover>
    </div>
  );
};

export default DesktopToolbar;
