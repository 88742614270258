import Jimp from "jimp";
import Potrace from "potrace";
import { closestColor, exactColor, rgbToHex } from "../helpers/helpers";
import process from "../helpers/runonnx";

const convertAlgorithm = "closest";

const processJimpImage = async (imageData, COLORS, numLayers, options) => {
  const { despeckle, simplifyCurves, smoothness, colors, originalDimensions } =
    options;
  console.log("Processing image with Jimp", imageData);
  const image = await Jimp.read(imageData);
  const height = image.getHeight();
  const width = image.getWidth();
  const colorLayers = new Array(numLayers);

  for (let i = 0; i < numLayers; i++) {
    colorLayers[i] = new Jimp(width, height, 0xffffffff);
  }

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      const rgba = Jimp.intToRGBA(image.getPixelColor(x, y));
      if (rgba.a < 25) continue;

      let pixelColorRGB;
      if (convertAlgorithm === "closest") {
        pixelColorRGB = closestColor(
          image.getPixelColor(x, y),
          COLORS,
          numLayers
        );
      } else if (convertAlgorithm === "exact") {
        pixelColorRGB = exactColor(
          image.getPixelColor(x, y),
          COLORS,
          numLayers
        );
        if (pixelColorRGB === false) continue;
      }

      for (let color_i = 0; color_i < colorLayers.length; color_i++) {
        if (
          pixelColorRGB.r === COLORS[color_i][0] &&
          pixelColorRGB.g === COLORS[color_i][1] &&
          pixelColorRGB.b === COLORS[color_i][2]
        ) {
          colorLayers[color_i].setPixelColor(0x000000ff, x, y);
        }
      }
    }
  }

  const traces = Array(numLayers);
  for (let i = 0; i < numLayers; i++) {
    traces[i] = new Potrace.Potrace();
    traces[i].setParameters({
      color: rgbToHex(COLORS[i][0], COLORS[i][1], COLORS[i][2]),
      turdSize: despeckle,
      optTolerance: simplifyCurves,
      alphaMax: smoothness,
    });
  }

  const promises = colorLayers.map(
    (layer, i) =>
      new Promise((resolve) => {
        layer.getBase64(Jimp.MIME_JPEG, (err, src) => {
          traces[i].loadImage(src, () => resolve());
        });
      })
  );

  await Promise.all(promises);

  const svgHead = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" version="1.1">`;
  const svgTail = "</svg>";
  let allPaths = "";
  let blackIndex = -1;

  for (let index = 0; index < colors.length; index++) {
    if (colors[index][0] === "000000") {
      blackIndex = index;
      continue;
    }
    console.log("tracing layer: ", colors[index][1]);
    allPaths += traces[index].getPathTag();
  }

  if (blackIndex !== -1) {
    console.log("tracing layer: ", colors[blackIndex][1]);
    allPaths = traces[blackIndex].getPathTag() + allPaths;
  }

  const newSVG = svgHead + allPaths + svgTail;
  const svgPreview = "data:image/svg+xml;base64," + window.btoa(newSVG);

  const containerWidth = window.innerWidth - 50;
  const containerHeight = (2 * window.innerHeight) / 3;

  let scaledImageHeight = originalDimensions[1];
  let scaledImageWidth = originalDimensions[0];

  let scale = Math.min(
    containerWidth / originalDimensions[0],
    containerHeight / originalDimensions[1]
  );

  if (scale < 1) {
    scaledImageHeight = Math.floor(originalDimensions[1] * scale);
    scaledImageWidth = Math.floor(originalDimensions[0] * scale);
  }

  const blob = new Blob([newSVG], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);

  return {
    svg: newSVG,
    svgPreview,
    downloadSVGLink: url,
    scaledImageHeight,
    scaledImageWidth,
  };
};

const evaluateHardwareCapabilities = () => {
  // Check available memory (if supported)
  const memory = navigator?.deviceMemory || 4; // defaults to 4GB if not supported
  
  // Check number of logical processors
  const cpuCores = navigator?.hardwareConcurrency || 4; // defaults to 4 cores
  
  // Check if running on mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  // Calculate max pixels based on hardware
  let maxPixels = 2000000; // Base case (2M pixels)
  let deviceType = 'medium'; // Default device type
  
//  if (memory >= 8 && cpuCores >= 8 && !isMobile) {
//     maxPixels = 4000000; // 4M pixels for high-end devices
//     deviceType = 'high-end';
//   } else if (memory <= 2 || cpuCores <= 2 || isMobile) {
//     maxPixels = 1000000; // 1M pixels for low-end devices
//     deviceType = 'low-end';
//   }

  console.log(`Device categorized as: ${deviceType} (${memory}GB RAM, ${cpuCores} cores, ${isMobile ? 'mobile' : 'desktop'})`);

  return {
    maxPixels,
    memory,
    cpuCores,
    isMobile,
    deviceType,
  };
};

export const processImage = async (params) => {
  const {
    colors,
    file,
    crop,
    despeckle,
    simplifyCurves,
    smoothness,
    ai,
    originalDimensions,
  } = params;

  console.log("Starting to process image", params);

  // Evaluate hardware capabilities
  const hardware = evaluateHardwareCapabilities();
  console.log("Hardware capabilities:", hardware);

  let numLayers = colors.length;
  let COLORS = colors.map((color) => {
    const rgba = Jimp.intToRGBA(parseInt("0x" + color[0] + "00"));
    return [rgba.r, rgba.g, rgba.b];
  });

  const img = new Image();
  img.src = file;

  return new Promise((resolve, reject) => {
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Evaluate hardware
      const MAX_PIXELS = hardware.maxPixels;

      // Original crop dimensions from the user
      let cropWidth = crop.width;
      let cropHeight = crop.height;

      // 1) Scale if the cropped area is larger than our hardware threshold
      if (cropWidth * cropHeight > MAX_PIXELS) {
        const scale = Math.sqrt(MAX_PIXELS / (cropWidth * cropHeight));
        cropWidth = Math.floor(cropWidth * scale);
        cropHeight = Math.floor(cropHeight * scale);
        console.log(
          `Scaled cropped region down to ${cropWidth}x${cropHeight}` +
          ` due to hardware limits (${hardware.memory}GB, ${hardware.cpuCores} cores).`
        );
      }

      // 2) Optionally clamp final dimensions to 3000×3000 (or another limit)
      const SIZE_LIMIT = 3000;
      if (cropWidth > SIZE_LIMIT || cropHeight > SIZE_LIMIT) {
        const ratio = cropWidth / cropHeight;
        if (ratio >= 1) {
          cropWidth = SIZE_LIMIT;
          cropHeight = Math.floor(SIZE_LIMIT / ratio);
        } else {
          cropHeight = SIZE_LIMIT;
          cropWidth = Math.floor(SIZE_LIMIT * ratio);
        }
        console.log(`Further clamped to ${cropWidth}x${cropHeight} due to size limit.`);
      }

      // Now set the canvas to these final scaled dimensions
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      // 3) Draw the cropped portion of the image onto our scaled canvas
      context.drawImage(
        img,
        // Source rectangle (the cropped portion of the original image):
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        // Destination rectangle (scaled result in our smaller canvas):
        0,
        0,
        cropWidth,
        cropHeight
      );

      // 4) Retrieve the scaled & cropped pixel data
      const processedImageData = context.getImageData(0, 0, cropWidth, cropHeight);

      // 5) Pass it on to your processing workflow (like processJimpImage, AI upscaling, etc.)
      try {
        const result = await processJimpImage(
          processedImageData,
          COLORS,
          numLayers,
          {
            despeckle,
            simplifyCurves,
            smoothness,
            colors,
            originalDimensions,
          }
        );
        resolve(result);
      } catch (error) {
        console.log(error);
        // reject(error);
      }
    };
  });
};
