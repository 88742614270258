import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";

import googleicon from "../../assets/google.svg";

import { lockClosedOutline, logoApple, mailOutline } from "ionicons/icons";
import { signInWithApple, signInWithGoogle } from "../../lib/firebase/firebase";

import { useHistory } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { handleSignUp } from "../../lib/firebase/firebase";
import AuthHeader from "./components/AuthHeader/AuthHeader";
import AuthPrompt from "./components/AuthPrompt";

import { faCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "antd";
import logo from "../../assets/logo1.svg";
import "./SignIn.css";

const SignUp = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getReadableError = (errorCode) => {
    switch (errorCode) {
      case "auth/email-already-exists":
      case "auth/email-already-in-use":
        return "The email you have entered is already in use. Please use a different email.";
      case "auth/invalid-email":
        return "The email you have entered is not valid. Please check your email and try again.";
      case "auth/invalid-password":
        return "The password you have entered is not valid. It must be at least six characters long.";
      case "auth/user-not-found":
        return "The entered credentials do not match any account. Please try again.";
      case "auth/phone-number-already-exists":
        return "The phone number you have entered is already in use. Please use a different phone number.";
      case "auth/invalid-uid":
        return "The user ID is not valid. Please try again.";
      case "auth/weak-password":
        return "The password you have entered is too weak. Please enter a stronger password.";
      default:
        return errorCode;
    }
  };

  function validatePassword(password) {
    // At least 8 characters long, one uppercase letter, one lowercase letter, one number, one special character
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    return regex.test(password);
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // 13 is the key code for the Enter key
      onSubmit();
    }
  };

  const onSubmit = async () => {
    if (!validatePassword(password)) {
      // can you add what special characters are allowed?  setError("Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.");         const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      setError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character out of !@#$%^&*"
      );
      return;
    }

    console.log(email, password);
    if (!email || !password || !passwordCheck) {
      setError("Please fill in all fields");
      return;
    }
    if (password !== passwordCheck) {
      setError("Passwords do not match");
      return;
    }
    try {
      setLoading(true);
      await handleSignUp(email, password);
    } catch (error) {
      // Handle sign-up error here
      console.error("Sign-up error:", error);
      setError(getReadableError(error.code));
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      // setLoading(true);
      const result = await signInWithGoogle();
      const user = result.user;
      if (result.additionalUserInfo?.isNewUser) {
        console.log("New user signed up with Google:", user);
        // You can add additional sign-up logic here, e.g., redirecting to a profile completion page
        history.push("/Home");
      } else {
        console.log("Existing user signed in with Google:", user);
        // Optionally handle cases where the user already exists
        history.push("/home");
      }
    } catch (error) {
      console.error("Error during Google sign-up:", error);
      setError("Failed to sign up with Google. Please try again.");
    } finally {
      // setLoading(false);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      // setLoading(true);
      const result = await signInWithApple(); // This function needs to be implemented in your firebase interactions
      const user = result.user;
      if (result.additionalUserInfo?.isNewUser) {
        console.log("New user signed up with Apple:", user);
        history.push("/home");
      } else {
        console.log("Existing user signed in with Apple:", user);
        history.push("/home");
      }
    } catch (error) {
      console.error("Error during Apple sign-up:", error);
      setError("Failed to sign up with Apple. Please try again.");
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (user) history.push("/home");
  }, [user]);

  return (
    <IonPage>
      <IonContent className="background">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="logo" width="200px" />
            <Tag color="gold">
              <FontAwesomeIcon icon={faCrown} size="1x" /> PRO
            </Tag>
          </div>

          <form className="form" style={{ marginBottom: "10px" }}>
            <AuthHeader
              text={{
                heading: "Create an Account",
                text: "Unlock premium features today by creating an account.",
              }}
            />
            {error && <IonText className="error">{error}</IonText>}
            <IonItem>
              <IonLabel position="floating">
                <IonIcon
                  icon={mailOutline}
                  style={{ transform: "translateY(2px)", paddingRight: "5px" }}
                />
                Email
              </IonLabel>
              <IonInput
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value)}
                required
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">
                <IonIcon
                  icon={lockClosedOutline}
                  style={{ transform: "translateY(2px)", paddingRight: "5px" }}
                />
                Password
              </IonLabel>
              <IonInput
                type="password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value)}
                required
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">
                <IonIcon
                  icon={lockClosedOutline}
                  style={{ transform: "translateY(2px)", paddingRight: "5px" }}
                />
                Verify Password
              </IonLabel>
              <IonInput
                type="password"
                value={passwordCheck}
                onIonChange={(e) => setPasswordCheck(e.detail.value)}
                onKeyDown={handleKeyDown} // Add the keydown event listener
                required
              />
            </IonItem>

            <IonButton
              expand="block"
              onClick={onSubmit}
              disabled={loading}
              className="submit-button"
            >
              {loading ? <IonSpinner name="lines" /> : "Get Started"}
            </IonButton>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#ccc" }}
              ></div>
              <div style={{ padding: "0 10px" }}>OR</div>
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#ccc" }}
              ></div>
            </div>
            {/* Existing login form elements... */}
            <div className="social-buttons-container">
              <IonButton
                color="default"
                style={{
                  fontSize: "11px",
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
                onClick={handleGoogleSignUp} // Updated to use the new handler for sign-up
              >
                <IonIcon icon={googleicon} style={{ marginRight: "5px" }} />{" "}
                Google
              </IonButton>
              <IonButton
                color="default"
                style={{
                  fontSize: "11px",
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
                onClick={handleAppleSignIn}
              >
                <IonIcon icon={logoApple} style={{ marginRight: "5px" }} />{" "}
                Apple
              </IonButton>
            </div>
          </form>
          <AuthPrompt
            ctaText={"Sign In"}
            text={"Already have an account? "}
            link={"signin"}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
