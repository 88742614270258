import {
  faArrowLeft,
  faArrowRight,
  faCircleInfo,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button, Carousel, Row, Select } from "antd";

const { Option } = Select;

const tools = [
  {
    name: "Selecting Colors",
    description:
      "In order to get a clean convert it is important to make sure you have the right colors selected for your image. Tap the image to add more colors and click the x next to the color in the colors panel to delete it.",
  },
  {
    name: "Image Compatibility",
    description:
      "SvgTrace is designed to work with flat 2D style images that have a handfull of colors in the photo. Images with gradients and realistic shading will not work well with SvgTrace.",
  },
  {
    name: "Crop",
    description: "Click the crop tool to crop your image prior to converting.",
  },
  {
    name: "Advanced Settings",
    description:
      "The sliders in advanced settings allow you to have more precise control over the smoothness and speckle suppression of the trace.",
  },
];

const Tutorial = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // Add a state variable to store the current index
  const carouselRef = useRef(null);

  const handleSelect = (value) => {
    setSelectedIndex(value); // Update the selectedIndex state when dropdown value changes
    carouselRef.current.goTo(value, false);
  };

  const handlePrev = () => {
    const prev = (selectedIndex - 1 + tools.length) % tools.length;
    setSelectedIndex(prev); // Update the selectedIndex state when left arrow is clicked
    carouselRef.current.prev();
    handleSelect(prev);
  };

  const handleNext = () => {
    const next = (selectedIndex + 1) % tools.length;
    setSelectedIndex(next); // Update the selectedIndex state when right arrow is clicked
    carouselRef.current.next();
    handleSelect(next);
  };

  return (
    <>
      <Button type="text" onClick={() => setShowModal(true)}>
        <Row justify="center">
          <FontAwesomeIcon icon={faCircleInfo} size={14} />
        </Row>
        <Row justify="center" style={{ fontSize: "10px" }}>
          Tutorial
        </Row>
      </Button>

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        style={{ "--height": "350px" }}
      >
        <IonToolbar>
          <IonTitle>Tutorial</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="ion-padding" style={{ margin: "15px" }}>
          <Select
            defaultValue={selectedIndex}
            value={selectedIndex}
            onChange={handleSelect}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            {tools.map((tool, index) => (
              <Option key={tool.name} value={index}>
                {tool.name}
              </Option>
            ))}
          </Select>

          <Carousel ref={carouselRef}>
            {tools.map((tool, index) => (
              <div key={tool.name}>
                <h3 style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={tool.icon}
                    style={{ marginRight: "5px" }}
                  />
                  {tool.name}
                </h3>
                <p>{tool.description}</p>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                ></div>
              </div>
            ))}
          </Carousel>

          <Row justify="center" style={{ marginTop: "20px" }}>
            <Button type="primary" onClick={handlePrev}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Button
              type="primary"
              onClick={handleNext}
              style={{ marginLeft: "10px" }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Row>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Tutorial;
