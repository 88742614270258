class ColorAnalyzer {
  static processImage(base64Image) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const maxDimension = 800;
        const scale = Math.min(
          1,
          maxDimension / Math.max(img.width, img.height)
        );
        const canvas = document.createElement("canvas");
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        ).data;
        const colors = this.analyzeColors(
          imageData,
          canvas.width * canvas.height
        );
        resolve(colors);
      };
      img.onerror = reject;
      img.src = base64Image;
    });
  }

  static analyzeColors(
    data,
    totalPixels,
    numClusters = 10,
    threshold = 0.05,
    mergeThreshold = 30
  ) {
    const pixels = [];
    for (let i = 0; i < data.length; i += 4) {
      // Check for white or near-white pixels
      if (data[i] > 250 && data[i + 1] > 250 && data[i + 2] > 250) {
        pixels.push([255, 255, 255]); // Treat as white
      } else if (data[i + 3] < 255) {
        continue; // Skip transparent pixels
      } else {
        pixels.push([data[i], data[i + 1], data[i + 2]]);
      }
    }
    const { centroids, labelCounts } = this.kMeans(pixels, numClusters);

    // Filter significant colors based on the threshold
    let significantColors = centroids
      .map((color, index) => ({
        color,
        count: labelCounts[index],
      }))
      .filter(({ count }) => count / pixels.length > threshold); // Use pixels.length to adjust for ignored pixels

    // Merge similar colors
    significantColors = this.mergeSimilarColors(
      significantColors,
      mergeThreshold
    );

    const hexColors = significantColors.map(({ color }) =>
      this.rgbToHex(color[0], color[1], color[2])
    );

    return hexColors;
  }

  static mergeSimilarColors(colors, threshold) {
    const mergedColors = [];

    colors.forEach((currentColor) => {
      let merged = false;
      mergedColors.forEach((mergedColor) => {
        if (
          this.euclideanDistance(currentColor.color, mergedColor.color) <
          threshold
        ) {
          // Merge colors by averaging them and summing their counts
          mergedColor.color = [
            (mergedColor.color[0] * mergedColor.count +
              currentColor.color[0] * currentColor.count) /
              (mergedColor.count + currentColor.count),
            (mergedColor.color[1] * mergedColor.count +
              currentColor.color[1] * currentColor.count) /
              (mergedColor.count + currentColor.count),
            (mergedColor.color[2] * mergedColor.count +
              currentColor.color[2] * currentColor.count) /
              (mergedColor.count + currentColor.count),
          ];
          mergedColor.count += currentColor.count;
          merged = true;
        }
      });
      if (!merged) {
        mergedColors.push(currentColor);
      }
    });

    return mergedColors;
  }

  static kMeans(pixels, k, maxIter = 100) {
    // Initialize centroids randomly
    let centroids = [];
    for (let i = 0; i < k; i++) {
      centroids.push(pixels[Math.floor(Math.random() * pixels.length)]);
    }

    let assignments = new Array(pixels.length).fill(-1);
    let labelCounts = new Array(k).fill(0);
    let iteration = 0;
    let changed = true;

    while (changed && iteration < maxIter) {
      changed = false;
      labelCounts.fill(0);
      const newCentroids = new Array(k).fill(null).map(() => [0, 0, 0]);

      for (let i = 0; i < pixels.length; i++) {
        let minDist = Infinity;
        let bestCluster = -1;
        for (let j = 0; j < k; j++) {
          const dist = this.euclideanDistance(pixels[i], centroids[j]);
          if (dist < minDist) {
            minDist = dist;
            bestCluster = j;
          }
        }
        if (assignments[i] !== bestCluster) {
          changed = true;
          assignments[i] = bestCluster;
        }
        labelCounts[bestCluster]++;
        newCentroids[bestCluster][0] += pixels[i][0];
        newCentroids[bestCluster][1] += pixels[i][1];
        newCentroids[bestCluster][2] += pixels[i][2];
      }

      for (let j = 0; j < k; j++) {
        if (labelCounts[j] > 0) {
          centroids[j] = newCentroids[j].map((val) => val / labelCounts[j]);
        }
      }

      iteration++;
    }

    return { centroids, labelCounts };
  }

  static euclideanDistance(a, b) {
    return Math.sqrt(
      Math.pow(a[0] - b[0], 2) +
        Math.pow(a[1] - b[1], 2) +
        Math.pow(a[2] - b[2], 2)
    );
  }

  static rgbToHex(r, g, b) {
    const toHex = (c) => c.toString(16).padStart(2, "0");
    return `#${toHex(Math.round(r))}${toHex(Math.round(g))}${toHex(Math.round(b))}`;
  }
}

export default ColorAnalyzer;
