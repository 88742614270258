import { useEffect, useState } from "react";
import isUserPremium from "./isUserPremium";

const usePremiumStatus = (user, loading) => {
  const [premiumStatus, setPremiumStatus] = useState(null);
  // const [loadingStatus, setloadingStatus] = useState(true);
  const [loadingStatus, setloadingStatus] = useState(false);

  useEffect(() => {
    if (user) {
      const checkPremiumStatus = async function () {
        setloadingStatus(true);
        setPremiumStatus(await isUserPremium());
        setloadingStatus(false);
      };
      checkPremiumStatus();
    }
  }, [user]);

  return [premiumStatus, loadingStatus];
};

export default usePremiumStatus;
