import { useGesture } from "@use-gesture/react";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import "./Canvas.css";
import Brush from "./components/Brush";
import { incrementViewOrDownloadCount } from "../../../../lib/firebase/firebase";
import { generateSquarePoints } from "../../helpers/generateSquarePoints";

const INITIAL_SCALE = 1;
const INITIAL_POSITION = { x: 0, y: 0 };
const WHEEL_SCALE_FACTOR = 0.01;
const MIN_SCALE = 0.1;

const Canvas = ({
  svg,
  svgJoined,
  setSvg,
  toolSelected,
  layerSelected,
  color,
  eraserRadius,
  vaccumRadius,
  cursor,
}) => {
  const [currentScale, setCurrentScale] = useState(INITIAL_SCALE);
  const [position, setPosition] = useState(INITIAL_POSITION);
  const [touchesState, setTouchesState] = useState(0);
  const currentFile = useSelector((state) => state.files.currentFile);

  const deletePath = useCallback(
    (i) => {
      if (!svg || !svg.children || !svg.children[layerSelected]) {
        console.error("Invalid svg structure or layer selected");
        return;
      }

      try {
        // Create a deep copy of the svg object
        const tempSvg = JSON.parse(JSON.stringify(svg));

        // Verify the required properties exist
        if (!tempSvg.children[layerSelected]?.children) {
          console.error("Invalid layer structure");
          return;
        }

        // Remove the path at index i
        tempSvg.children[layerSelected].children.splice(i, 1);
        setSvg(tempSvg);
      } catch (error) {
        console.error("Error in deletePath:", error);
        // If JSON parsing fails, try a manual deep copy
        try {
          const tempSvg = {
            ...svg,
            children: svg.children.map((child) => ({
              ...child,
              children: child.children ? [...child.children] : [],
            })),
          };

          if (tempSvg.children[layerSelected]?.children) {
            tempSvg.children[layerSelected].children.splice(i, 1);
            setSvg(tempSvg);
          }
        } catch (fallbackError) {
          console.error("Fallback error handling failed:", fallbackError);
        }
      }
    },
    [svg, layerSelected, setSvg]
  );

  const simulateSquareClicks = useCallback((centerX, centerY, halfLength) => {
    try {
      const numDivisions = 4;
      const points = generateSquarePoints(
        centerX,
        centerY,
        halfLength,
        numDivisions
      );

      points.forEach(([x, y]) => {
        const el = document.elementFromPoint(x, y);
        if (!el) return;

        const evt = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
          clientX: x,
          clientY: y,
        });
        el.dispatchEvent(evt);
      });
    } catch (error) {
      console.error("Error simulating square clicks:", error);
    }
  }, []);

  const handleElementsInRadius = useCallback(
    (xy, radius, shouldCheckSize = false) => {
      const [x, y] = xy;
      const elements = Array.from(document.getElementsByTagName("path")).filter(
        (element) => {
          const rect = element.getClientRects()[0];
          if (!rect) return false;

          const distance = Math.sqrt(
            Math.pow(x - (rect.left + rect.right) / 2, 2) +
              Math.pow(y - (rect.top + rect.bottom) / 2, 2)
          );

          return shouldCheckSize
            ? distance <= radius && rect.width < radius && rect.height < radius
            : distance <= radius;
        }
      );

      if (elements.length > 0) {
        const index = elements[0].className.baseVal.split("-")[1];
        deletePath(index);
      }
    },
    [deletePath]
  );

  const bind = useGesture({
    onDrag: ({ xy, delta, touches }) => {
      setTouchesState(touches);

      if (touches === 2 || toolSelected === "move" || layerSelected === "all") {
        setPosition((prev) => ({
          x: prev.x + delta[0] ,
          y: prev.y + delta[1] ,
        }));
        return;
      }

      if (layerSelected !== "all" && touches === 1) {
        if (toolSelected === "erase") {
          simulateSquareClicks(xy[0], xy[1], eraserRadius / 2);
        } else if (toolSelected === "vacuum") {
          handleElementsInRadius(xy, vaccumRadius, true);
        }
      }
    },
    onPinch: ({ origin, offset }) => {
      const svg = document.getElementById("svgtest");
      const group = svg?.getElementById("transformGroup");
      if (!svg || !group) return;

      const x = origin[0] - svg.getBoundingClientRect().left;
      const y = origin[1] - svg.getBoundingClientRect().top;
      group.setAttribute("transform-origin", `${x} ${y}`);
      setCurrentScale(offset[0]);
    },
    onWheel: ({ event }) => {
      event.preventDefault();

      // Fallback to a known DOM element if event.currentTarget is null:
      const container = event.currentTarget || document.getElementById("svgtest");
      if (!container) return;

      // Safely call getBoundingClientRect:
      const svgRect = container.getBoundingClientRect
        ? container.getBoundingClientRect()
        : null;
      if (!svgRect) return;

      const oldScale = currentScale;
      const zoomFactor = 1 + (event.deltaY < 0 ? 0.1 : -0.1);
      const newScale = Math.max(MIN_SCALE, oldScale * zoomFactor);

      const offsetX = (event.clientX - svgRect.left) - position.x;
      const offsetY = (event.clientY - svgRect.top) - position.y;

      const newOffsetX = offsetX * (newScale / oldScale);
      const newOffsetY = offsetY * (newScale / oldScale);

      setPosition({
        x: (event.clientX - svgRect.left) - newOffsetX,
        y: (event.clientY - svgRect.top) - newOffsetY,
      });

      setCurrentScale(newScale);
    },
  });

  const changeColor = useCallback(
    (i) => {
      if (!svg || !svg.children) return;

      const tempSvg = { ...svg };
      if (tempSvg.children[i]) {
        tempSvg.children[i].attributes.fill = color;
        setSvg(tempSvg);
      }
    },
    [svg, color, setSvg]
  );

  const clickHandler = useCallback(
    (i) => {
      if (toolSelected === "fill") {
        changeColor(i);
      } else if (["cut", "erase"].includes(toolSelected)) {
        deletePath(i);
      }
    },
    [toolSelected, changeColor, deletePath]
  );

  useEffect(() => {
    if (currentFile.isPublic) {
      incrementViewOrDownloadCount(currentFile.id, "view");
    }
  }, [currentFile]);

  if (!svg?.attributes || !svgJoined?.attributes) {
    return <div className="svg-container" key={svg}></div>;
  }

  const cursorStyle = ["erase", "vacuum"].includes(toolSelected)
    ? "none"
    : cursor;

  return (
    <div
      {...bind()}
      className="svg-container"
      style={{ height: "100%", width: "100%", overflow: "hidden" }}
    >
      <svg
        id="svgtest"
        width="100%"
        height="100%"
        style={{ cursor: cursorStyle }}
      >
        <g
          id="transformGroup"
          transform={`translate(${position.x} ${position.y}) scale(${currentScale})`}
        >
          <svg
            viewBox={svg.attributes.viewBox}
            id="svgContainer"
            xmlns="http://www.w3.org/2000/svg"
            width={svg.attributes.width}
            height={svg.attributes.height}
          >
            {layerSelected === "all" ? (
              svgJoined.children?.map((child, i) => (
                <path
                  key={i}
                  d={child.attributes.d}
                  stroke="none"
                  fill={child.attributes.fill}
                  fillRule={child.attributes["fill-rule"]}
                />
              ))
            ) : (
              <>
                {svgJoined.children?.[layerSelected] && (
                  <path
                    d={svgJoined.children[layerSelected].attributes.d}
                    stroke="none"
                    fill={svgJoined.children[layerSelected].attributes.fill}
                    fillRule={
                      svgJoined.children[layerSelected].attributes["fill-rule"]
                    }
                  />
                )}
                {svg.children[layerSelected]?.children?.map((object, i) => (
                  <path
                    key={i}
                    className={`path-${i}`}
                    onClick={() => clickHandler(i)}
                    d={object.attributes.d}
                    strokeWidth="1"
                    stroke="none"
                    fill={object.attributes.fill}
                    fillOpacity="0%"
                  />
                ))}
              </>
            )}
          </svg>
        </g>
        {touchesState !== 2 &&
          (toolSelected === "erase" || toolSelected === "vacuum") && (
            <Brush
              radius={toolSelected === "erase" ? eraserRadius : vaccumRadius}
              toolSelected={toolSelected}
            />
          )}
      </svg>
    </div>
  );
};

export default Canvas;
