import { Directory } from "@capacitor/filesystem";
import { isPlatform } from "@ionic/core";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { stringify } from "svgson";
import { writeFile } from "../components/FileStorage";
import { updateSVGInStorage } from "../lib/firebase/firebase";
import AppContext from "../contexts/AppContext";
import { updateFile } from "../redux/slices/MyFiles";
import { updateUploadedFileInRedux } from "../redux/slices/UploadedFiles";

const useSaveSVG = (svgJoined, currentFile) => {
  const { svgStore } = useContext(AppContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const saveSVG = async () => {
    setLoading(true);
    setError(null);
    try {
      const svgToSave = stringify(svgJoined);
      const base64Data = btoa(svgToSave);
      let date = new Date();
      let milliseconds = date.getTime();

      if (isPlatform("ios") && !isPlatform("mobileweb")) {
        if (svgStore.uri) {
          const fileUri = svgStore.uri;
          await writeFile({
            path: fileUri,
            data: base64Data,
            recursive: true,
          });
        } else {
          await writeFile({
            path: `svgtrace-files/${milliseconds}.svg`,
            data: base64Data,
            directory: Directory.Documents,
            recursive: true,
          });
        }
      } else if (isPlatform("desktop") || isPlatform("mobileweb")) {
        if (svgStore.firebase) {
          const storageRef = svgStore.firebase;
          const dataUrl = `data:image/svg+xml;base64,${base64Data}`;
          const response = await updateSVGInStorage(storageRef, dataUrl);
          // if (currentFile.type === "generated")
          //   dispatch(updateFile({ ...response, id: currentFile.id }));
          // else
            dispatch(
              updateUploadedFileInRedux({ ...response, id: currentFile.id })
            );
        }
      }
      return true;
    } catch (e) {
      setError(e.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { saveSVG, loading, error, setLoading, setError };
};

export default useSaveSVG;
