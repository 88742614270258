import { faUser, faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonPopover,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { logOutOutline, personOutline } from "ionicons/icons";
import { useState } from "react";
import CrownIcon from "../assets/SvgEditor/Crown.svg";
import Logo from "../assets/SvgEditor/Logo.svg";
import { createPortalLinkFunc, signOutUser } from "../lib/firebase/firebase";
import { useSelector } from "react-redux";
import { useAuth } from "../contexts/AuthContext";
import googleicon from "../assets/google.svg";
import logo from "../assets/logo2.svg";
import { Tag } from "antd";

const Navbar = ({ fileName, showFileName = false }) => {
  const currentFile = useSelector((state) => state.files.currentFile);
  const { user } = useAuth();
  const isOwner = user?.uid === currentFile?.ownerUid;
  const [popoverState, setPopoverState] = useState({
    showPopover: false,
    event: undefined,
  });
  const [customerPortalLoading, setCustomerPortalLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCustomerPortalClick = async () => {
    setCustomerPortalLoading(true);
    try {
      const { data } = await createPortalLinkFunc({
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    } catch (error) {
      console.log(error);
    } finally {
      setCustomerPortalLoading(false);
    }
  };

  const handleSignOut = async () => {
    setLoading(true);
    try {
      await signOutUser();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IonGrid
        style={{
          width: "100%",
          backgroundColor: "#1E1E1E",
          fontFamily: "Clash Grotesk",
          padding: "0",
          maxHeight: "60px",
          paddingLeft: "16px",
          paddingRight: "20px",
        }}
      >
        <IonRow
          style={{
            justifyContent: "space-between",
            width: "100%",
            height: "60px",
          }}
        >
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
              
        <img src={logo} alt="logo" width="150px" />
        <FontAwesomeIcon
            style={{ marginRight: "5px" }}
            fontSize={"12px"}
            color="rgba(219, 165, 47, 1)"
            icon={faCrown}
          />

      

          </IonCol>
          {showFileName && (
            <IonCol
              style={{
                color: "#EBEBEB",
                fontSize: "16px",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {fileName || "File Name"}
              {currentFile.isPublic && !isOwner && "(Read Only)"}
            </IonCol>
          )}

          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "14px",
              paddingRight: "30px",
            }}
          >
            <div
              style={{
                backgroundColor: "#48C9BC",
                height: "32px",
                width: "32px",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "rgba(0, 0, 0, 0.50)",
                fontWeight: "500",
                fontSize: "22px",
                cursor: "pointer",
              }}
              onClick={(e) => setPopoverState({ showPopover: true, event: e })}
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setPopoverState({ showPopover: false, event: undefined })
        }
      >
        <IonList>
          <IonItem
            button
            onClick={handleCustomerPortalClick}
            disabled={customerPortalLoading}
          >
            <IonIcon icon={personOutline} slot="start" />
            {customerPortalLoading ? (
              <IonSpinner
                name="lines"
                style={{
                  width: "15px",
                  height: "15px",
                }}
              />
            ) : (
              "Account"
            )}
          </IonItem>
          <IonItem button onClick={handleSignOut} disabled={loading}>
            <IonIcon icon={logOutOutline} slot="start" />
            {loading ? (
              <IonSpinner
                name="lines"
                style={{
                  width: "15px",
                  height: "15px",
                }}
              />
            ) : (
              "Sign Out"
            )}
          </IonItem>
        </IonList>
      </IonPopover>
    </>
  );
};

export default Navbar;
