import {
  faBadgeDollar,
  faBookHeart,
  faEnvelopeOpen,
  faTextSize,
  faArrowLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { faLightbulb, faText } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonContent, IonPage, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import preview from "../assets/preview_pro.png";
import { useAuth } from "../contexts/AuthContext";
import { verifyEmail, signOutUser } from "../lib/firebase/firebase";
import { createCheckoutSession } from "../lib/stripe/createCheckoutSession";
import "./Auth/SignIn.css";
import LifeTimePro from "./Checkout/LifeTimePro";
// import AuthHeader from "../components/AuthHeader/AuthHeader";
import AuthHeader from "../pages/Auth/components/AuthHeader/AuthHeader";
import { Button } from "antd";

const Checkout = () => {
  const { user, userIsPremium } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("user", user);
    console.log("userIsPremium", userIsPremium);
    console.log("isEmailVerified", user.emailVerified);
  }, [user, userIsPremium]);

  const handleUpgrade = async (subscriptionType) => {
    console.log("Upgrade to Premium clicked");
    setLoading(true);
    await createCheckoutSession(user.uid, subscriptionType);
  };

  // make a button that resends the email verification
  const handleResendEmail = async () => {
    console.log("Resend email clicked");
    setLoading(true);

    await verifyEmail();

    setLoading(false);
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await signOutUser();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent className="background">
        {/* check if email is verified */}
        {!user.emailVerified && (
          <div className="container">
            <div className="form">
              {/* Add a top bar with a back arrow and sign out button inside the white card */}
              <div className="top-bar" style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>

                <Button
                  className="sign-out-button"
                  onClick={handleLogout}
           
                  style={{ 
                    background: 'none', 
                    border: 'none', 
                    color: '#007bff', 
                    textDecoration: 'underline', 
                    cursor: 'pointer' 
                  }}
                >
                  Sign Out
                </Button>
              </div>

              <AuthHeader
                text={{
                  heading: "Verify your email",
                  text: "Please verify your email then refresh the page.",
                }}
              />
              <IonButton
                className="submit-button-2"
                fill="clear"
                onClick={handleResendEmail}
                disabled={loading}
              >
                {loading ? <IonSpinner name="lines" /> : "Resend email"}
              </IonButton>
              <IonButton
                className="submit-button"
                onClick={() => window.location.reload()}
            
              >
               Refresh page
              </IonButton>
            </div>
          </div>
        )}
        {/* check if user is premium */}
        {false && user.emailVerified && !userIsPremium && (
          <div className="container_2">
            {/* // add an ion loading spinner here */}
            <IonSpinner
              name="lines"
              style={{ display: loading ? "block" : "none", margin: "auto" }}
            />

            <div
              className="ion-padding payment-parent"
              style={{
                display: loading ? "none" : "flex",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <div className="payment_div">
                <p className="headline">
                  Commercial use SVGs made simple. Try Free for 7 days.
                </p>
                <div className="points">
                  <div className="card-wrapper">
                    <div className="point-desgin">
                      <FontAwesomeIcon
                        className="p-icon"
                        icon={faLightbulb}
                        color="white"
                      />
                      <p>Unlimited use of 750,000+ images</p>
                    </div>
                    <div className="point-desgin">
                      <FontAwesomeIcon
                        className="p-icon"
                        icon={faText}
                        color="white"
                      />
                      <p>Unlimited use of 700+ fonts</p>
                    </div>
                  </div>
                  <div className="card-wrapper">
                    <div className="point-desgin">
                      <FontAwesomeIcon
                        className="p-icon"
                        icon={faBookHeart}
                        color="white"
                      />
                      <p>1,000+ ready-to-make projects</p>
                    </div>
                    <div className="point-desgin">
                      <FontAwesomeIcon
                        className="p-icon"
                        icon={faTextSize}
                        color="white"
                      />
                      <p>Exclusive Design Space features</p>
                    </div>
                  </div>
                  <div className="card-wrapper">
                    <div className="point-desgin">
                      <FontAwesomeIcon
                        className="p-icon"
                        icon={faEnvelopeOpen}
                        color="white"
                      />
                      <p>10% off svgtrace.com purchases*</p>
                    </div>
                    <div className="point-desgin">
                      <FontAwesomeIcon
                        className="p-icon"
                        icon={faBadgeDollar}
                        color="white"
                      />
                      <p>10% off quarterly coupon*</p>
                    </div>
                  </div>
                </div>

                <IonButton
                  className="submit-button"
                  onClick={() => handleUpgrade("designer")}
                >
                  Start Free Trial
                </IonButton>

                <p className="attention">
                  Pay as low as $7.99/mo after the trial end. Cancel any time.
                </p>
              </div>
            </div>
          </div>
        )}
        {/* {user.emailVerified && !userIsPremium && ( */}
        {/* <div className="container">
          <div className="ion-padding">
            <h2>Lifetime Access</h2>
            <p>
              Get Unlimited AI Enhanced Converts and an SVG Editor for just one
              payment of 12.99.
            </p>
            <img src={preview} alt="logo" width="100%" />
            <br />
            <center>
              <IonButton
                className="submit-button"
                onClick={() => {
                  handleUpgrade("lifetime");
                  setLoading(true);
                }}
              >
                {loading ? <IonSpinner name="lines" /> : "Check Out"}
              </IonButton>
            </center>
          </div>
        </div> */}

        <LifeTimePro />
        {/* )} */}
      </IonContent>
    </IonPage>
  );
};

export default Checkout;
