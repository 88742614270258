import { faPlusMinus } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button, Carousel, Row, Select } from "antd";
import { useRef, useState } from "react";

const { Option } = Select;

const tools = [
  {
    name: "Pan",
    // icon: faUpDownLeftRight,
    icon: faPlusMinus,
    description:
      "The Pan tool allows to move the image around the canvas and to zoom in and out. To pan the image, click and drag the image. To zoom pinch the image.",
    gif: "/assets/gifs/pan.gif",
  },
  {
    name: "Fill-Cut",
    icon: faPlusMinus,
    description:
      "The Fill-Cut tool serves as both fill and Cut for areas you tap on in the image. To fill an area, tap on the area. To delete an area, tap the area.",
    gif: "/assets/gifs/fillcut.gif",
  },
  {
    name: "Erase",
    // icon: faEraser,
    icon: faPlusMinus,
    description:
      "The erase tool erases any path it comes in contact with. This is best used if you want to erase a lot of things quickly.",
    gif: "/assets/gifs/erase.gif",
  },
  {
    name: "Vacuum",
    // icon: faVacuum,
    icon: faPlusMinus,

    description:
      "The vacuum tool erases any path it comes in contact with that is smaller then the brush size. This is best if you are trying to erase small details that might be near a larger object you want to keep.",
    gif: "/assets/gifs/vacuum.gif",
  },
  {
    name: "Auto-Fill",
    icon: faPlusMinus,
    // icon: faFill,
    description:
      "The auto-fill tool fills all of the areas inside the biggest enclosed path making.",
    gif: "/assets/gifs/autofill.gif",
  },
  {
    name: "Color",
    icon: faPlusMinus,
    // icon: faEyeDropper,
    description:
      "The color tool allows you to change the color of the whole layer.",
    gif: "/assets/gifs/color.gif",
  },
  {
    name: "Order",
    icon: faPlusMinus,
    // icon: faArrowsLeftRight,

    description:
      "The order tool allows you to change the order of your layers. Just tap the order tool and use the left and right arrows to change the order of your layers.",
    gif: "/assets/gifs/order.gif",
  },
  {
    name: "Copy",
    icon: faPlusMinus,
    // icon: faCopy,
    description:
      "The Copy tool copies the current layer and adds it to the end of the layer list. This is useful if you want to make a copy of a layer and then make changes to it.",
    gif: "/assets/gifs/copy.gif",
  },
  {
    name: "Delete",
    icon: faPlusMinus,
    // icon: faTrash,
    description:
      "The delete tool deletes the current layer. This is useful if you want to remove a layer from the image.",

    gif: "/assets/gifs/delete.gif",
  },
];

const Tutorial = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // Add a state variable to store the current index
  const carouselRef = useRef(null);

  const handleSelect = (value) => {
    setSelectedIndex(value); // Update the selectedIndex state when dropdown value changes
    carouselRef.current.goTo(value, false);
  };

  const handlePrev = () => {
    const prev = (selectedIndex - 1 + tools.length) % tools.length;
    setSelectedIndex(prev); // Update the selectedIndex state when left arrow is clicked
    carouselRef.current.prev();
    handleSelect(prev);
  };

  const handleNext = () => {
    const next = (selectedIndex + 1) % tools.length;
    setSelectedIndex(next); // Update the selectedIndex state when right arrow is clicked
    carouselRef.current.next();
    handleSelect(next);
  };

  return (
    <>
      <Button type="text" onClick={() => setShowModal(true)}>
        <Row justify="center">
          <FontAwesomeIcon icon={faInfoCircle} size={"xs"} />
        </Row>
        <Row justify="center" style={{ fontSize: "10px" }}>
          Tutorial
        </Row>
      </Button>

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        style={{ "--height": "650px" }}
      >
        <IonToolbar>
          <IonTitle>Tool Tutorial</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="ion-padding" style={{ margin: "15px" }}>
          <Select
            defaultValue={selectedIndex}
            value={selectedIndex}
            onChange={handleSelect}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            {tools.map((tool, index) => (
              <Option key={tool.name} value={index}>
                {tool.name}
              </Option>
            ))}
          </Select>

          <Carousel ref={carouselRef}>
            {tools.map((tool, index) => (
              <div key={tool.name}>
                <h3 style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={tool.icon}
                    style={{ marginRight: "5px" }}
                  />
                  {tool.name}
                </h3>
                <p>{tool.description}</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={tool.gif}
                    alt={`${tool.name} example`}
                    style={{ maxHeight: "300px" }}
                  />
                </div>
              </div>
            ))}
          </Carousel>

          <Row justify="center" style={{ marginTop: "20px" }}>
            <Button type="primary" onClick={handlePrev}>
              {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
            </Button>
            <Button
              type="primary"
              onClick={handleNext}
              style={{ marginLeft: "10px" }}
            >
              {/* <FontAwesomeIcon icon={faArrowRight} /> */}
              arrow
            </Button>
          </Row>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Tutorial;
