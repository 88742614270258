import React from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ChevronLeft } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const PreviewSvgModal = ({
  openPreviewModal,
  setOpenPreviewModal,
  svgPreview,
  submitBtn,
}) => {
  const [showInfo, setShowInfo] = React.useState(() => {
    const savedState = localStorage.getItem('previewModalInfoShown');
    return savedState === null;
  });

  const handleInfoDismiss = () => {
    setShowInfo(false);
    localStorage.setItem('previewModalInfoShown', 'true');
  };

  return (
    <div>
      <IonModal
        style={{
          "--height": "90vh",
          "--max-height": "649px",
          "--width": "1164px",
          "--max-width": "95%",
          "--border-radius": "8px",
        }}
        isOpen={openPreviewModal}
        onDidDismiss={() => setOpenPreviewModal(false)}
      >
        <IonToolbar
          style={{
            "--background": "#fff",
            height: "65px",
            display: "flex",
          }}
        >
          <IonTitle
            style={{
              fontSize: "22px",
              color: "#2A2A2A",
              padding: 0,
              marginLeft: "22px",
            }}
          >
            Preview
          </IonTitle>
          <IonButtons
            slot="end"
            style={{
              color: "#F453C4",
              marginRight: "20px",
              gap: "10px",
            }}
          >
            <IonButton
              style={{
                fontSize: "12px",
                fontWeight: "600",
                "--padding-start": "12px",
                "--padding-end": "12px",
                textTransform: "none",
                border: "1px solid #F453C4",
                borderRadius: "4px",
              }}
              onClick={() => setOpenPreviewModal(false)}
            >
              <ChevronLeft size={20} style={{ marginRight: "4px" }} />
   
            </IonButton>
            {submitBtn}
          </IonButtons>
        </IonToolbar>
        <IonContent
          style={{
            "--background": "#F6F6F6",
            borderTop: "1px solid #BEBEBE",
            borderBottom: "1px solid #BEBEBE",
            position: "relative",
          }}
        >
          {showInfo && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                backgroundColor: "#e7f3fe",
                padding: "8px 12px",
                borderRadius: "8px",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "#3178c6",
              }}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ marginRight: "8px", color: "#3178c6" }}
              />
              <span>
                The color selection has a large impact on the output.
                Try adjusting the selected colors to see what gives you the best
                results.
              </span>
              <button
                onClick={handleInfoDismiss}
                style={{
                  marginLeft: "12px",
                  background: "none",
                  border: "none",
                  color: "#3178c6",
                  cursor: "pointer",
                  padding: "4px",
                  fontSize: "16px",
                }}
              >
                ×
              </button>
            </div>
          )}
          <img src={svgPreview} style={{ width: "100%", height: "100%" }} />
        </IonContent>
      </IonModal>
    </div>
  );
};

export default PreviewSvgModal;
