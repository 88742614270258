import { Filesystem } from "@capacitor/filesystem";
import { isPlatform } from "@ionic/react";

export const writeFile = (jsonProps) => {
  // if platform is ios or android, use Filesystem.writeFile
  if (isPlatform("ios") || isPlatform("android") || isPlatform("capacitor")) {
    return Filesystem.writeFile(jsonProps);
  } else if (isPlatform("mobileweb")) {
    // if the path ends with .png, return null
    if (jsonProps.path.endsWith(".png")) {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    const mtime = new Date().getTime();
    const filename = jsonProps.path.split("/").pop();
    console.log("jsonProps: ", jsonProps);
    const atob = (str) => {
      return Buffer.from(str, "base64").toString("binary");
    };
    const data = atob(jsonProps.data);
    console.log("data: ", data);

    const svgStore = {
      time: mtime,
      uri: jsonProps.path,
      data: data,
      name: filename,
      type: "file",
    };

    const svgStoreString = JSON.stringify(svgStore);
    localStorage.setItem(jsonProps.path, svgStoreString);

    return new Promise((resolve, reject) => {
      resolve(svgStore);
    });
  }
};

export const readFile = (jsonProps) => {
  if (isPlatform("ios") || isPlatform("android") || isPlatform("capacitor")) {
    return Filesystem.readFile(jsonProps);
  } else if (isPlatform("mobileweb")) {
    console.log("jsonProps.path: ", jsonProps.path);
    const item = localStorage.getItem(jsonProps.path);
    const parsedItem = JSON.parse(item);
    console.log("The item: ", item);
    return new Promise((resolve, reject) => {
      resolve(parsedItem);
    });
  }
};
