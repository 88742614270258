import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { 
  addConvertedSVG, 
  updateConversionStatus 
} from '../../redux/slices/BulkConversion';
import { processAndAnalyzeImage } from '../Converter/components/AutoUploadImage';
import { processImage } from '../Converter/components/processImage';

export const useBulkImageProcessing = () => {
  const dispatch = useAppDispatch();
  const originalImages = useSelector(state => state.BulkConversion.originalImages);
  const [currentProcessingIndex, setCurrentProcessingIndex] = useState(null);

  useEffect(() => {
    const processNextImage = async () => {
      if (originalImages.length < 2 || currentProcessingIndex === null) {
        return;
      }

      const imageObj = originalImages[currentProcessingIndex];

      if (!imageObj) {
        setCurrentProcessingIndex(null);
        return;
      }

      if (imageObj.status === 'idle') {
        dispatch(updateConversionStatus({
          index: currentProcessingIndex,
          status: 'started'
        }));

        try {
          const { file, originalDimensions, crop, despeckle, colors } = 
            await processAndAnalyzeImage(imageObj.image);
          
          const convertedSVG = await processImage({
            colors,
            file,
            crop,
            despeckle,
            simplifyCurves: 0.2,
            smoothness: 1,
            ai: true,
            originalDimensions
          });

          dispatch(addConvertedSVG({
            svg: convertedSVG,
            index: currentProcessingIndex
          }));

          dispatch(updateConversionStatus({
            index: currentProcessingIndex,
            status: 'completed'
          }));

        } catch (error) {
          console.error('Error processing image:', error);
          dispatch(updateConversionStatus({
            index: currentProcessingIndex,
            status: 'failed'
          }));
        }

        setCurrentProcessingIndex(prevIndex => {
          if (prevIndex === null || prevIndex >= originalImages.length - 1) {
            return null;
          }
          return prevIndex + 1;
        });
      }
    };

    processNextImage();
  }, [originalImages, currentProcessingIndex, dispatch]);

  useEffect(() => {
    if (currentProcessingIndex === null && originalImages.length > 0) {
      setCurrentProcessingIndex(0);
    }
  }, [originalImages, currentProcessingIndex]);

  return {
    currentProcessingIndex,
    setCurrentProcessingIndex
  };
};