import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import CropPng from "./CropPng";
import DesktopLayerSelector from "./DesktopLayerSelector";
import DesktopTopBar from "./DesktopTopBar";
import MainCanvas from "./MainCanvas";
import { processImage } from "./processImage";
import { useDebounceEffect } from "./useDebounceEffect";
import Navbar from "../../WebNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import Submit from "./Submit";
import TickIcon from "../../../assets/Converter/Tick.svg";
import { ChevronRight } from "react-feather";

const SubmitButton = ({ previewLoading, ...props }) => (
  <Submit {...props}>
    {previewLoading ? (
      <span>Loading...</span>
    ) : (
      <>
        <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          Next: Open in Editor
          <ChevronRight size={20} style={{ marginRight: "4px" }} />
        </span>
      </>
    )}
  </Submit>
);

const ConverterDesktop = ({
  scale,
  setScale,
  file,
  setFile,
  clearFields,
  crop,
  setIsCropVisible,
  screenSize,
  setClickedColor,
  imageDimensions,
  setImageDimensions,
  originalDimensions,
  setCropGlobal,
  largeImageWarning,
  isCropVisible,
  setIsModalVisible,
  downloadSVGLink,
  isLoading,
  smoothness,
  setSmoothness,
  despeckle,
  setDespeckle,
  simplifyCurves,
  setSimplifyCurves,
  ai,
  setAi,
  colors,
  setColors,
  setSvgPreview,
  setPreviewReady,
  setSvg,
  setIsLoading,
  setDownloadSVGLink,
  setShareLink,
  svgPreview,
  saveToCloud,
  setLoadingConverter,
  setConverterOpen,
}) => {
  const [previewLoading, setPreviewLoading] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [showColorInfo, setShowColorInfo] = useState(() => {
    const savedState = localStorage.getItem("colorSelectInfoShown");
    return savedState === null;
  });

  const handleColorInfoDismiss = () => {
    setShowColorInfo(false);
    localStorage.setItem("colorSelectInfoShown", "true");
  };

  useEffect(() => setLoadingConverter(false), []);

  useDebounceEffect(
    () => {
      (async () => {
        setPreviewLoading(true);
        try {
          const result = await processImage({
            colors,
            file,
            crop,
            despeckle,
            simplifyCurves,
            smoothness,
            ai,
            originalDimensions,
          });

          setSvg(result.svg);
          setSvgPreview(result.svgPreview);
          setDownloadSVGLink(result.downloadSVGLink);
        } catch (error) {
          console.error("Error processing image:", error);
        } finally {
          setPreviewLoading(false);
        }
      })();
    },
    1000,
    [
      colors,
      file,
      crop,
      despeckle,
      simplifyCurves,
      smoothness,
      ai,
      originalDimensions,
    ]
  );

  const submitButtonProps = {
    setFile,
    setSvgPreview,
    setPreviewReady,
    setSvg,
    originalDimensions,
    crop,
    despeckle,
    simplifyCurves,
    smoothness,
    colors,
    file,
    setIsLoading,
    isLoading,
    setDownloadSVGLink,
    setShareLink,
    setIsModalVisible,
    ai,
    saveToCloud,
    previewLoading,
    loading: previewLoading,
    disabled: previewLoading,
  };

  return (
    <>
      <Navbar showFileName={false} />
      <DesktopTopBar
        scale={scale}
        setScale={setScale}
        file={file}
        clearFields={clearFields}
        crop={crop}
        setIsCropVisible={setIsCropVisible}
        previewLoading={previewLoading}
        setConverterOpen={setConverterOpen}
        setOpenPreviewModal={setOpenPreviewModal}
      />
      <Row
        style={{
          outline: "1px solid #f0f0f0",
          minWidth: "500px",
          maxHeight: "calc(100vh)",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <Col
          flex="188px"
          style={{
            height: "calc(100vh - 112px)",
            // make light grey outline
            borderStyle: "solid",
            borderColor: "#cfcfcf",
            // only make the left border visible
            borderLeftWidth: "1px",
            borderRightWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            overflowY: "scroll",
          }}
        >
          <DesktopLayerSelector
            colors={colors}
            setColors={setColors}
            smoothness={smoothness}
            setSmoothness={setSmoothness}
            despeckle={despeckle}
            setDespeckle={setDespeckle}
            imageDimensions={imageDimensions}
            simplifyCurves={simplifyCurves}
            setSimplifyCurves={setSimplifyCurves}
            svgPreview={svgPreview}
            previewLoading={previewLoading}
            file={file}
            setFile={setFile}
            isLoading={isLoading}
            openPreviewModal={openPreviewModal}
            setOpenPreviewModal={setOpenPreviewModal}
            submitBtn={<SubmitButton {...submitButtonProps} />}
          />
        </Col>

        <Col
          flex="auto"
          style={{
            maxWidth: "calc(100% - 188px)",
            height: "calc(100vh - 112px)",
          }}
        >
          <Col
            flex="auto"
            style={{
              backgroundColor: "#f0f0f0",
              height: "calc(100%)",
              overflowX: "scroll",
              cursor: "cursor",
            }}
          >
            <div style={{ height: "100%" }}>
              <div style={{ position: "relative", height: "100%" }}>
                {showColorInfo && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      backgroundColor: "#e7f3fe",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      zIndex: 1,
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      color: "#3178c6",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      style={{ marginRight: "8px", color: "#3178c6" }}
                    />
                    Click on image to select colors
                    <button
                      onClick={handleColorInfoDismiss}
                      style={{
                        marginLeft: "12px",
                        background: "none",
                        border: "none",
                        color: "#3178c6",
                        cursor: "pointer",
                        padding: "4px",
                        fontSize: "16px",
                      }}
                    >
                      ×
                    </button>
                  </div>
                )}
                <MainCanvas
                  screenSize={screenSize}
                  crop={crop}
                  clearFields={clearFields}
                  file={file}
                  scale={scale}
                  setScale={setScale}
                  setClickedColor={setClickedColor}
                />
              </div>
              <CropPng
                setScale={setScale}
                setImageDimensions={setImageDimensions}
                originalDimensions={originalDimensions}
                cropGlobal={crop}
                setCropGlobal={setCropGlobal}
                largeImageWarning={largeImageWarning}
                file={file}
                isModalVisible={isCropVisible}
                setIsModalVisible={setIsCropVisible}
                downloadSVGLink={downloadSVGLink}
                isLoading={isLoading}
              />
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default ConverterDesktop;
