import { useWindowSize } from "usehooks-ts";
import SvgEditorDesktop from "./components/SvgEditorDesktop";
import SvgEditorMobile from "./components/SvgEditorMobile";

const SvgEditor = () => {
  const { width: screenWidth } = useWindowSize();

  return screenWidth > 750 ? <SvgEditorDesktop /> : <SvgEditorMobile />;
};

export default SvgEditor;
