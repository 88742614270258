import { useState, useEffect } from "react";
import { parse } from "svgson";
import { useSelector } from "react-redux";

const useSvgUpdater = () => {
  const [svg, setSvg] = useState();
  const [svgJoined, setSvgJoined] = useState();
  const [svgHistory, setSvgHistory] = useState(new Array(50));
  const [historyIndex, setHistoryIndex] = useState(0);
  const [undoActions, setUndoActions] = useState(0);
  const [redoActions, setRedoActions] = useState(0);
  const [colors, setColors] = useState();
  const [changesMade, setChangesMade] = useState(false);

  const currentFile = useSelector((state) => state.files.currentFile);

  useEffect(() => {
    const fetchAndParseSVG = async () => {
      const response = await fetch(currentFile.url);
      const data = await response.text();
      console.log(data);
      const svg = await parse(data);
      updateSVGSplit(svg);
    };

    fetchAndParseSVG();
  }, [currentFile]);

  const updateSVGJoined = (split) => {
    var joinedPaths = Array(split.children.length).fill("");
    for (let i = 0; i < split.children.length; i++) {
      for (let j = 0; j < split.children[i].children.length; j++) {
        joinedPaths[i] += split.children[i].children[j].attributes.d;
      }
    }

    let joined = JSON.parse(JSON.stringify(split));
    for (let i = 0; i < joined.children.length; i++) {
      joined.children[i].attributes.d = joinedPaths[i];
      joined.children[i].children = [];
    }
    let colorLayers = [];
    joined.children.forEach((layer) => {
      colorLayers.push(layer.attributes.fill);
    });
    setColors(colorLayers);

    setSvgJoined(joined);
  };

  const updateSVGSplit = (joined) => {
    var splitPaths = Array(joined.children.length).fill("");
    for (let i = 0; i < joined.children.length; i++) {
      splitPaths[i] = joined.children[i].attributes.d;
    }
    let split = JSON.parse(JSON.stringify(joined));
    for (let i = 0; i < joined.children.length; i++) {
      split.children[i].attributes.d = "";
      split.children[i].children = [];
      var splitPath = splitPaths[i].split(/(?=[M])/);

      for (let j = 0; j < splitPath.length; j++) {
        if (splitPath[j] !== "") {
          split.children[i].children.push({
            name: "path",
            type: "element",
            attributes: {
              d: splitPath[j],
            },
            children: [],
          });
        }
      }
    }
    let colorLayers = [];
    split.children.forEach((layer) => {
      colorLayers.push(layer.attributes.fill);
    });
    setColors(colorLayers);
    updateSvg(split);
  };

  const updateSvg = (newSvg) => {
    if (undoActions > 0) {
      setChangesMade(true);
    }
    setUndoActions(undoActions + 1);
    setRedoActions(0);
    let items = Object.assign({}, svgHistory);
    let item = JSON.parse(JSON.stringify(newSvg));
    items[historyIndex] = item;
    setHistoryIndex((historyIndex + 1) % 50);
    setSvgHistory(items);
    setSvg(newSvg);
    updateSVGJoined(newSvg);
  };

  const mod = (n, m) => {
    return ((n % m) + m) % m;
  };

  const undo = () => {
    if (undoActions > 1) {
      let item = JSON.parse(
        JSON.stringify(svgHistory[mod(historyIndex - 2, 50)])
      );
      setSvg(item);
      updateSVGJoined(item);
      setHistoryIndex(mod(historyIndex - 1, 50));
      setUndoActions(undoActions - 1);
      setRedoActions(redoActions + 1);
    }
  };

  const redo = () => {
    if (redoActions > 0) {
      let item = JSON.parse(JSON.stringify(svgHistory[historyIndex % 50]));
      setSvg(item);
      updateSVGJoined(item);
      setHistoryIndex((historyIndex + 1) % 50);
      setRedoActions(redoActions - 1);
      setUndoActions(undoActions + 1);
    }
  };

  const undoAll = () => {
    if (undoActions > 0) {
      let item = JSON.parse(JSON.stringify(svgHistory[0]));
      setSvg(item);
      updateSVGJoined(item);
      setHistoryIndex(1); // Reset to the state after the initial one
      setUndoActions(1);
      setRedoActions(undoActions - 1);
    }
  };

  return {
    svg,
    svgJoined,
    svgHistory,
    historyIndex,
    undoActions,
    redoActions,
    colors,
    changesMade,
    setChangesMade,
    setHistoryIndex,
    setUndoActions,
    setRedoActions,
    setColors,
    updateSvg,
    undo,
    undoAll,
    redo,
    currentFile,
  };
};

export default useSvgUpdater;
