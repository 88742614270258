import { useEffect, useRef } from "react";

const LayerPreview = ({ svgJoined, index, width, height }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (typeof index !== "undefined") {
      const pathData = svgJoined.children[index].attributes["d"];

      // Create a temporary SVG element to measure the path
      const tempSvg = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      const tempPath = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );

      tempPath.setAttribute("d", pathData);
      tempSvg.appendChild(tempPath);
      document.body.appendChild(tempSvg);

      // Clean up the temporary SVG element
      document.body.removeChild(tempSvg);
    } else {
      const tempSvg = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );

      // Create temporary paths for all children
      svgJoined.children.forEach((child) => {
        const tempPath = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        tempPath.setAttribute("d", child.attributes["d"]);
        tempSvg.appendChild(tempPath);
      });

      document.body.appendChild(tempSvg);

      // Clean up the temporary SVG element
      document.body.removeChild(tempSvg);
    }
  }, [svgJoined.children, index]);

  return (
    <svg
      ref={svgRef}
      height={height || "50"}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        padding: "2px",
        borderRadius: "8px",
        backgroundColor: "#fff",
        maxWidth: "140px",
      }}
      viewBox={svgJoined.attributes.viewBox}
    >
      {typeof index !== "undefined" ? (
        <path
          d={svgJoined.children[index].attributes["d"]}
          stroke="none"
          fill={svgJoined.children[index].attributes["fill"]}
          fillRule={svgJoined.children[index].attributes["fill-rule"]}
        />
      ) : (
        svgJoined.children.map((child, i) => {
          return (
            <path
              key={i}
              d={child.attributes["d"]}
              stroke="none"
              fill={child.attributes["fill"]}
              fillRule={child.attributes["fill-rule"]}
            />
          );
        })
      )}
    </svg>
  );
};

export default LayerPreview;
