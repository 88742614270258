import { useContext } from "react";
import AppContext from "../../../contexts/AppContext";
import ColorAnalyzer from "./ColorAnalyzer";
import { Button } from "antd";
import process from "../helpers/runonnx";

// Helper function to process the image
export const processAndAnalyzeImage = (image64) => {
  return new Promise((resolve, reject) => {
    let targetImg = image64;
    if (!targetImg) {
      const svgXml = new XMLSerializer().serializeToString(
        document.getElementById("svgContainer")
      );
      targetImg = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgXml)))}`;
    }

    const img = new Image();
    img.onload = async () => {
      // Create a canvas to draw the image
      const canvas = document.createElement("canvas");
      canvas.width = Math.round(img.width);
      canvas.height = Math.round(img.height);
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Get image data
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      // Upscale if necessary
      if (canvas.width * canvas.height < 300 * 300) {
        console.log("Image smaller than 300x300, doing a 4x upscale");
        imageData = await process(imageData, 4);
        img.width = img.width * 4;
        img.height = img.height * 4;
      } else if (canvas.width * canvas.height < 600 * 600) {
        console.log("Image smaller than 600x600, doing a 2x upscale");
        imageData = await process(imageData, 2);
        img.width = img.width * 2;
        img.height = img.height * 2;
      } else {
        imageData = targetImg;
      }

      // Update dimensions after potential upscaling
      let dimensions;
      if (imageData instanceof ImageData) {
        dimensions = [
          imageData.width > 400 ? imageData.width : imageData.width * 2,
          imageData.height > 400 ? imageData.height : imageData.height * 2,
        ];
      } else {
        // Use img.width and img.height directly if imageData doesn't have width/height
        dimensions = [
          img.width > 400 ? img.width : img.width * 2,
          img.height > 400 ? img.height : img.height * 2,
        ];
      }

      const result = {
        file: imageData, // Convert the potentially upscaled image back to base64
        originalDimensions: dimensions,
        largeImageWarning: img.width * img.height > 2073600,
        crop: {
          unit: "px",
          x: 0,
          y: 0,
          width: img.width,
          height: img.height,
        },
        imageDimensions: [img.width, img.height],
        despeckle: img.width * 0.01,
      };

      ColorAnalyzer.processImage(img.src)
        .then((hexColors) => {
          result.colors = hexColors.map((color) => [color.slice(1), true]);
          resolve(result);
        })
        .catch((error) => {
          console.error("Error processing image with ColorAnalyzer:", error);
          reject(error);
        });
    };

    img.onerror = (event) => {
      console.error("There was a problem loading the image.", event);
      reject(event);
    };

    img.src = targetImg;
  });
};

const AutoUploadImage = (props) => {
  const { image64 } = useContext(AppContext);

  const handleClick = async () => {
    try {
      const result = await processAndAnalyzeImage(image64);
      console.log("result", result);
      props.setFile(result.file);
      props.setOriginalDimensions(result.originalDimensions);
      props.setLargeImageWarning(result.largeImageWarning);
      props.setCrop(result.crop);

      if (props.setImageDimensions) {
        props.setImageDimensions(result.imageDimensions);
      }

      if (props.setDespeckle) {
        props.setDespeckle(result.despeckle);
      }

      props.setColors(result.colors);
    } catch (error) {
      console.error("Error processing image:", error);
    }
  };

  return (
    <Button id="AutoConvertUpload" hidden onClick={handleClick}>
      Process Image
    </Button>
  );
};

export default AutoUploadImage;
