import { Col, Row, Spin } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Navbar from "../WebNavbar";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";

interface BulkConverterProps {
  setLoadingConverter: (loading: boolean) => void;
  setConverterOpen: (loading: boolean) => void;
  setCurrentProcessingIndex: (index: number | null) => void;
}

const BulkConverter = ({
  setLoadingConverter,
  setConverterOpen,
  setCurrentProcessingIndex,
}: BulkConverterProps) => {
  const originalImages = useSelector(
    (state: RootState) => state.BulkConversion.originalImages
  );
  const convertedSvgs = useSelector(
    (state: RootState) => state.BulkConversion.convertedSvgs
  );
  const selectedImageIndex = useSelector(
    (state: RootState) => state.BulkConversion.selectedImageIndex
  );
  useEffect(() => {
    setLoadingConverter(false);
  }, []);

  const renderContent = () => {
    if (selectedImageIndex === null) {
      return <div>No image selected</div>;
    }

    const status = originalImages[selectedImageIndex].status;
    const svg = convertedSvgs[selectedImageIndex];

    switch (status) {
      case "idle":
        return <div>Waiting to start conversion...</div>;
      case "started":
        return <Spin tip="Converting..." />;
      case "completed":
        return svg ? (
          <div dangerouslySetInnerHTML={{ __html: svg.svg }} />
        ) : (
          <div>Conversion failed</div>
        );
      case "failed":
        return <div>Conversion failed</div>;
      default:
        return <div>Unknown status</div>;
    }
  };

  return (
    <>
      <Navbar fileName="" showFileName={false} />
      <TopBar
        setConverterOpen={setConverterOpen}
        setCurrentProcessingIndex={setCurrentProcessingIndex}
      />
      <Row
        style={{
          outline: "1px solid #f0f0f0",
          minWidth: "500px",
          maxHeight: "calc(100vh)",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <Col
          flex="188px"
          style={{
            height: "calc(100vh - 112px)",
            // make light grey outline
            borderStyle: "solid",
            borderColor: "#cfcfcf",
            // only make the left border visible
            borderLeftWidth: "1px",
            borderRightWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            overflowY: "scroll",
          }}
        >
          <Sidebar />
        </Col>

        <Col
          flex="auto"
          style={{
            maxWidth: "calc(100% - 188px)",
            height: "calc(100vh - 112px)",
          }}
        >
          <Col
            flex="auto"
            style={{
              backgroundColor: "#f0f0f0",
              height: "calc(100%)",
              overflowX: "scroll",
              cursor: "default",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {renderContent()}
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default BulkConverter;
