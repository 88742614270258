import algoliasearch from "algoliasearch";

// Initialize Algolia client (replace 'YourApplicationID' and 'YourSearchOnlyApiKey' with your actual Algolia credentials)
const client = algoliasearch("VNZT5K7SCI", "a802cd95fb94fb3b6c2916b1161b93c8");
const index = client.initIndex("svgMetadata");

export const getPublicSVGsWithAlgolia = async () => {
  try {
    // Perform the search with filters and sorting
    const { hits } = await index.search("", {
      hitsPerPage: 20, // Limit to 20 results
      aroundLatLngViaIP: false, // Disable location-based sorting
      facets: ["*"], // If you're using facets
    });

    // console.log("hits", hits);
    // Convert hits to your desired format
    const svgFiles = hits.map((hit) => ({
      id: hit.objectID,
      ...hit,
    }));

    return svgFiles;
  } catch (error) {
    console.error("Error fetching SVGs:", error);
    throw error;
  }
};
