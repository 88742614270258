import { faEyeDropper } from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Input, Row } from "antd";
import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import IconButton from "./IconButton";

const ColorPicker = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [color, setColor] = useState(props.colors[props.layerSelected]);

  const handleInputChange = (event) => {
    setColor(event.target.value);
  };

  const saveColor = () => {
    let colorsCopy = [...props.colors];
    colorsCopy[props.layerSelected] = color;
    props.setColors(colorsCopy);

    let svgCopy = { ...props.svg };
    svgCopy.children[props.layerSelected].attributes.fill = color;
    props.updateSvg(svgCopy);

    setShowModal(false);
  };

  return (
    <>
      {props.children ? (
        <div onClick={() => setShowModal(true)}>{props.children}</div>
      ) : (
        <IconButton
          onClickHandler={() => setShowModal(true)}
          icon={faEyeDropper}
          text="Color"
        />
      )}

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        style={{ "--height": "480px" }}
      >
        <IonToolbar>
          <IonTitle>Set Color</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="ion-padding" style={{ margin: "15px" }}>
          <IonCol>
            <center>
              <HexColorPicker
                color={color}
                onChange={setColor}
                style={{ width: "90%" }}
              />
              <Input
                type="text"
                value={color}
                onChange={handleInputChange}
                style={{
                  marginTop: "10px",
                  width: "90%",
                  padding: "10px",
                  fontSize: "16px",
                }}
              />
              <Row
                style={{
                  paddingTop: "10px",
                  width: "90%",
                  overflow: "hidden",
                }}
              >
                {props.colors.map((color, index) => (
                  <div
                    key={index}
                    style={{
                      marginRight: "5px",
                      paddingTop: "20px",
                      borderStyle: "solid",
                      borderColor: "#f0f0f0",
                      backgroundColor: color,
                      width: "35px",
                      height: "35px",
                    }}
                    onClick={() => setColor(color)}
                  ></div>
                ))}
              </Row>
            </center>
          </IonCol>
          <IonButton
            color="secondary"
            expand="block"
            key="back"
            onClick={saveColor}
          >
            Save
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default ColorPicker;
