import ZoomInIcon from "../../../assets/Converter/ZoomIn.svg";
import ZoomOutIcon from "../../../assets/Converter/ZoomOut.svg";
import CropIcon from "../../../assets/SvgEditor/Crop.svg";

const Button = ({ icon, label, disable, onClick }) => {
  return (
    <button
      style={{
        display: "flex",
        gap: "4px",
        flexDirection: "column",
        alignItems: "center",
        background: "transparent",
        fontFamily: "Clash Grotesk",
        fontSize: "10px",
        whiteSpace: "nowrap",
        opacity: disable ? "0.4" : "1",
        minWidth: "50px",
      }}
      disabled={disable}
      onClick={onClick}
      className="hover-grey"
    >
      {icon && (
        <img src={icon} alt={label} style={{ width: "20px", height: "20px" }} />
      )}
      <span>{label}</span>
    </button>
  );
};

const DesktopToolbar = ({ setIsCropVisible }) => {
  const tools = [
    {
      icon: CropIcon,
      label: "Crop",
      onClick: () => setIsCropVisible(true),
      disable: false,
    },
    {
      icon: ZoomInIcon,
      label: "Zoom In",
      onClick: () => document.getElementById("zoomInButton").click(),
      disable: false,
    },
    {
      icon: ZoomOutIcon,
      label: "Zoom Out",
      onClick: () => document.getElementById("zoomOutButton").click(),
      disable: false,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        gap: "32px",
      }}
    >
      {tools.map((button, index) => (
        <Button
          key={index}
          icon={button.icon}
          label={button.label}
          disable={button.disable}
          onClick={button.onClick}
        />
      ))}
    </div>
  );
};

export default DesktopToolbar;
