export const BENEFITS = [
  "Unlimited AI Enhanced Converts.",
  "Built in SVG Editor.",
  "Commercial Use Image Generator.",
];

export const PLANS = [
  {
    type: "yearly",
    price: "US$95.88",
    perMonth: "(US$7.99/month)",
    label: "Yearly",
    savings: "Best Value - Save $60",
    subscriptionType: "designerYearly",
  },
  {
    type: "monthly",
    price: "US$12.99",
    label: "Monthly",
    subscriptionType: "designer",
  },
];
