import { alertController, loadingController } from "@ionic/core";
import { isPlatform } from "@ionic/react";
import process from "../../../../../components/Converter/helpers/runonnx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Modal, Upload, Input, Button, Checkbox } from "antd";
import { useState } from "react";
import "antd/dist/antd.css";
import "./Resizer.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { Dragger } = Upload;

const Resize = () => {
  const [showModal, setShowModal] = useState(false);
  const [fileToResize, setFileToResize] = useState(null);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [maintainAspectRatio, setMaintainAspectRatio] = useState(true);
  const [fillColor, setFillColor] = useState("#FFFFFF");
  const [originalDimensions, setOriginalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [errorMessage, setErrorMessage] = useState("");

  // Calculate aspect ratio once when original dimensions are set
  const aspectRatio = originalDimensions.width / originalDimensions.height;

  // Handle width change with aspect ratio maintenance
  const handleWidthChange = (e) => {
    const newWidth = e.target.value;
    setWidth(newWidth);

    if (maintainAspectRatio && newWidth) {
      const calculatedHeight = Math.round(parseInt(newWidth) / aspectRatio);
      setHeight(calculatedHeight.toString());
    }
  };

  // Handle height change with aspect ratio maintenance
  const handleHeightChange = (e) => {
    const newHeight = e.target.value;
    setHeight(newHeight);

    if (maintainAspectRatio && newHeight) {
      const calculatedWidth = Math.round(parseInt(newHeight) * aspectRatio);
      setWidth(calculatedWidth.toString());
    }
  };

  // Function to handle quick resize buttons
  const handleQuickResize = (factor) => {
    const newWidth = originalDimensions.width * factor;
    const newHeight = originalDimensions.height * factor;
    setWidth(newWidth.toString());
    setHeight(newHeight.toString());
  };

  const saveImage = async (dataUrl, fileName) => {
    const newFileName = `${fileName.split(".")[0]}_${width}px_${height}px.png`;

    if (
      isPlatform("desktop") ||
      isPlatform("mobileweb") ||
      isPlatform("electron")
    ) {
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = dataUrl;
      downloadLink.download = newFileName;

      const alert = await alertController.create({
        header: "Success!",
        subHeader: "The image have been resized.",
        buttons: [
          {
            text: "Download",
            handler: () => {
              downloadLink.click();
              document.body.removeChild(downloadLink);
            },
          },
        ],
      });

      await alert.present();
    }
  };

  const uploadAction = async (file) => {
    if (!["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
      console.log("Unsupported file format");
      return Upload.LIST_IGNORE;
    }
    setFileToResize(file);

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      setOriginalDimensions({ width: img.width, height: img.height });
      // Set initial dimensions to original dimensions
      setWidth(img.width.toString());
      setHeight(img.height.toString());
      setShowModal(true);
    };
  };

  const handleResize = async () => {
    if (!fileToResize || !width || !height) return;

    const fileSizeMB = fileToResize.size / 1000000;
    if (fileSizeMB > 5) {
      setErrorMessage("File size exceeds 5MB. Please choose a smaller file.");
      return;
    }

    const userWidth = parseInt(width, 10);
    const userHeight = parseInt(height, 10);

    if (userWidth < 10 || userHeight < 10) {
      setErrorMessage("Minimum dimensions are 10x10px.");
      return;
    }

    const maxWidth = Math.min(originalDimensions.width * 4, 1920);
    const maxHeight = Math.min(originalDimensions.height * 4, 1920);

    if (userWidth > maxWidth || userHeight > maxHeight) {
      setErrorMessage(`Maximum dimensions are ${maxWidth}x${maxHeight}px.`);
      return;
    }

    // Reset error message if no errors
    setErrorMessage("");

    const loading = await loadingController.create({
      message: "Preparing files...",
      id: "loading-msg",
    });
    await loading.present();

    const img = new Image();
    img.src = URL.createObjectURL(new Blob([fileToResize]));
    img.onload = async () => {
      let { width: originalWidth, height: originalHeight } = img;
      const aspectRatio = originalWidth / originalHeight;

      const userWidth = parseInt(width, 10);
      const userHeight = parseInt(height, 10);

      if (userWidth < 10 || userHeight < 10) {
        const alert = await alertController.create({
          header: "Invalid Dimensions",
          subHeader: "Minimum dimensions are 10x10px.",
          buttons: ["OK"],
        });
        await alert.present();
        loading.dismiss();
        return;
      }

      const maxWidth = Math.min(originalWidth * 4, 1920);
      const maxHeight = Math.min(originalHeight * 4, 1920);

      if (userWidth > maxWidth || userHeight > maxHeight) {
        Modal.error({
          title: "Invalid Dimensions",
          content: `Maximum dimensions are ${maxWidth}x${maxHeight}px.`,
        });
        loading.dismiss();
        return;
      }

      const isUpscaling =
        userWidth > originalWidth || userHeight > originalHeight;

      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");

      if (isUpscaling) {
        let scaleFactor = Math.max(
          userWidth / originalWidth,
          userHeight / originalHeight
        );
        const originalScaleFactor = scaleFactor;
        if (scaleFactor <= 2) scaleFactor = 2;
        else if (scaleFactor <= 4) scaleFactor = 4;

        canvas.width = originalWidth;
        canvas.height = originalHeight;
        context.drawImage(img, 0, 0, originalWidth, originalHeight);

        const imageData = context.getImageData(
          0,
          0,
          originalWidth,
          originalHeight
        );
        const result = await process(imageData, scaleFactor);

        if (originalScaleFactor === 2 || originalScaleFactor === 4) {
          await saveImage(result, fileToResize.name);
          loading.dismiss();
          return;
        }

        const resultImage = new Image();
        resultImage.src = result;
        await new Promise((resolve) => {
          resultImage.onload = resolve;
        });

        canvas.width = userWidth;
        canvas.height = userHeight;
        context.drawImage(resultImage, 0, 0, userWidth, userHeight);
      } else {
        canvas.width = userWidth;
        canvas.height = userHeight;
        context.drawImage(img, 0, 0, userWidth, userHeight);
      }

      if (userWidth / userHeight !== aspectRatio) {
        const paddedCanvas = document.createElement("canvas");
        const paddedContext = paddedCanvas.getContext("2d");

        paddedCanvas.width = userWidth;
        paddedCanvas.height = userHeight;

        // Use the selected fill color
        paddedContext.fillStyle = fillColor;
        paddedContext.fillRect(0, 0, userWidth, userHeight);

        const fitWidth = Math.min(userWidth, userHeight * aspectRatio);
        const fitHeight = Math.min(userHeight, userWidth / aspectRatio);

        const offsetX = (userWidth - fitWidth) / 2;
        const offsetY = (userHeight - fitHeight) / 2;
        paddedContext.drawImage(canvas, offsetX, offsetY, fitWidth, fitHeight);

        canvas = paddedCanvas;
      }
      const resizedImage = canvas.toDataURL("image/png");
      loading.dismiss();
      await saveImage(resizedImage, fileToResize.name);
    };

    setShowModal(false);
  };

  return (
    <>
      <Modal
        open={showModal}
        centered
        onCancel={() => setShowModal(false)}
        footer={null}
        width={480}
        closable={false}
        className="resize-modal"
        style={{
          borderRadius: "16px",
          overflow: "hidden",
        }}
      >
        <div className="custom-modal-container">
          <h2 className="custom-modal-title">Resize Image</h2>
          <p className="custom-modal-message">
            Original Dimensions: {originalDimensions.width}x
            {originalDimensions.height}px
          </p>

          {errorMessage && (
            <div className="error-message">
              <ExclamationCircleOutlined />
              {errorMessage}
            </div>
          )}

          <div className="quick-resize">
            <div className="quick-resize-label">Quick Resize</div>
            <div className="quick-resize-buttons">
              <button
                className="quick-resize-btn"
                onClick={() => handleQuickResize(2)}
              >
                2x Larger
              </button>
              <button
                className="quick-resize-btn"
                onClick={() => handleQuickResize(4)}
              >
                4x Larger
              </button>
            </div>
          </div>

          <div className="dimensions-group">
            <div className="dimension-input">
              <label className="dimension-label">Width (px)</label>
              <Input
                value={width}
                type="number"
                onChange={handleWidthChange}
                min={10}
              />
            </div>
            <div className="dimension-input">
              <label className="dimension-label">Height (px)</label>
              <Input
                value={height}
                type="number"
                onChange={handleHeightChange}
                min={10}
              />
            </div>
          </div>

          <div className="aspect-ratio-checkbox">
            <Checkbox
              checked={maintainAspectRatio}
              onChange={(e) => setMaintainAspectRatio(e.target.checked)}
            >
              Maintain aspect ratio
            </Checkbox>
          </div>

          {!maintainAspectRatio && (
            <div className="fill-color-picker">
              <label
                className="dimension-label"
                style={{ marginBottom: "8px", display: "block" }}
              >
                Fill Color
              </label>

              <div className="color-input-wrapper">
                <input
                  type="color"
                  value={fillColor}
                  onChange={(e) => setFillColor(e.target.value)}
                  className="color-input"
                />
              </div>
            </div>
          )}

          <div className="modal-footer">
            <button className="cancel-btn" onClick={() => setShowModal(false)}>
              Cancel
            </button>
            <button className="resize-btn" onClick={handleResize}>
              Resize Image
            </button>
          </div>
        </div>
      </Modal>
      <div hidden className="no-data">
        <h5>Resize Image</h5>
        <div className="custom-card">
          <div className="nft__item" style={{ height: "180px", margin: 0 }}>
            <div className="nft__item_wrap" style={{ height: `178px` }}>
              <Dragger
                id="ResizerInput"
                type="file"
                accept=".jpg, .jpeg, .png, .webp"
                multiple={false}
                beforeUpload={uploadAction}
                showUploadList={false}
                style={{ marginTop: "-20px" }}
              >
                <p className="ant-upload-drag-icon">
                  <FontAwesomeIcon icon={faUpload} size="3x" />
                </p>
                <p className="ant-upload-text">
                  Click or drag image to this area
                </p>
              </Dragger>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resize;
