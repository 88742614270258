import { IonAlert, IonButtons, IonLoading } from "@ionic/react";
import { useContext, useState } from "react";
import useSaveSVG from "../../../../hooks/useSaveSvg";
import AppContext from "../../../../contexts/AppContext";
import "./style.css";
import { resetBulkConversionState } from "../../../../redux/slices/BulkConversion";
import { useAppDispatch } from "../../../../redux/store";

const Close = ({
  changesMade,
  svgJoined,
  currentFile,
  children,
  styles,
  setIsCloseHovered,
}) => {
  const { setSvgStore } = useContext(AppContext);
  const dispatch = useAppDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const { saveSVG, loading, error, setError, setLoading } = useSaveSVG(
    svgJoined,
    currentFile
  );

  const handleSave = async () => {
    const success = await saveSVG();
    if (success) setSvgStore(undefined);
  };

  const handleClose = () => {
    if (!changesMade) {
      setSvgStore(undefined);
    } else {
      setShowAlert(true);
    }
    dispatch(resetBulkConversionState());
  };

  return (
    <>
      <IonButtons
        onClick={handleClose}
        style={{ ...styles, cursor: "pointer" }}
        onMouseEnter={() => setIsCloseHovered && setIsCloseHovered(true)}
        onMouseLeave={() => setIsCloseHovered && setIsCloseHovered(false)}
      >
        {children}
      </IonButtons>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header="Do you want to save?"
        subHeader="You will loose changes if you don’t save the progress"
        cssClass="close-alert"
        buttons={[
          {
            text: "Don't Save",
            cssClass: "alert-button-dont-save alert-button",
            handler: () => {
              setSvgStore(undefined);
            },
          },
          {
            text: "Save Changes",
            cssClass: "alert-button-save alert-button",
            handler: handleSave,
          },
        ]}
      />
      {error && (
        <IonAlert
          isOpen={!!error}
          onDidDismiss={() => setError(null)}
          header="Error"
          subHeader="An error occurred while saving"
          buttons={["OK"]}
        />
      )}
      <IonLoading
        isOpen={loading}
        message={"Saving..."}
        onDidDismiss={() => setLoading(false)}
      />
    </>
  );
};

export default Close;
