import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import { useState } from "react";
import { resetPassworEmail } from "../../lib/firebase/firebase";
import AuthHeader from "./components/AuthHeader/AuthHeader";
import AuthPrompt from "./components/AuthPrompt";
// import { ResetText } from "./constants";

// import signin.css
import "./SignIn.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // 13 is the key code for the Enter key
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      resetPassworEmail(email);
      setSuccess(true);
      setError("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent className="background">
        <div className="container">
          {success ? (
            <div className="form">
              <AuthHeader />
              <div className="email_sent">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color="green"
                  fontSize={"40px"}
                />
                <h5>We have sent you an email.</h5>
                <p>
                  An email with instructions to reset your password has been
                  sent to your email address.
                </p>
              </div>

              <AuthPrompt ctaText={"Back to Sign In"} link={"signin"} />
            </div>
          ) : (
            <>
              <AuthHeader
                text={{
                  heading: "Forgot Password?",
                  text: "Please enter your email address then we will send you an email with instructions to reset your password.",
                }}
              />
              <form className="form" onSubmit={handleSubmit}>
                {error && <IonText className="error">{error}</IonText>}
                <IonItem>
                  <IonLabel position="floating">
                    {" "}
                    <IonIcon
                      icon={mailOutline}
                      style={{
                        transform: "translateY(2px)",
                        paddingRight: "5px",
                      }}
                    />
                    Email
                  </IonLabel>
                  <IonInput
                    type="email"
                    value={email}
                    onIonChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown} // Add the keydown event listener
                    required
                  />
                </IonItem>
                <IonButton
                  expand="block"
                  type="submit"
                  disabled={loading}
                  className="submit-button"
                >
                  {loading ? <IonSpinner name="lines" /> : "Reset Password"}
                </IonButton>
              </form>
            </>
          )}
          {/* {success ? (
                        <IonText className="success">
                            An email with instructions to reset your password has
                            been sent to your email address.
                        </IonText>
                    ) : (
                        <form className="form" onSubmit={handleSubmit}>
                            {error && <IonText className="error">{error}</IonText>}
                            <IonItem>
                                <IonLabel position="floating">Email</IonLabel>
                                <IonInput
                                    type="email"
                                    value={email}
                                    onIonChange={(e) => setEmail(e.target.value)}
                                    onKeyDown={handleKeyDown} // Add the keydown event listener
                                    required
                                />
                            </IonItem>
                            <IonButton
                                expand="block"
                                type="submit"
                                disabled={loading}
                                className="submit-button"
                            >
                                {loading ? (
                                    <IonSpinner name="lines" />
                                ) : (
                                    "Reset Password"
                                )}
                            </IonButton>
                        </form>
                    )}
                    <div className="links" style={{ marginTop: "1rem", textAlign: "center" }}>
                        <IonRouterLink routerLink="/signin">
                            Back to Sign In
                        </IonRouterLink>
                    </div> */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
