import { Share } from "@capacitor/share";
import { isPlatform } from "@ionic/react";
import { InputNumber, Slider } from "antd";
import { useContext, useEffect, useState } from "react";
import { stringify } from "svgson";

import { Filesystem } from "@capacitor/filesystem";
import AppContext from "../../../contexts/AppContext";
import DownloadButton from "./DownloadButton";
import { useSelector } from "react-redux";
import { incrementViewOrDownloadCount } from "../../../lib/firebase/firebase";

function SVGtoPNGResizer({ svgJson, exportName }) {
  const { svgStore } = useContext(AppContext);
  const currentFile = useSelector((state) => state.files.currentFile);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [sliderDisabled, setSliderDisabled] = useState(true);
  const [inputWidth, setInputWidth] = useState(width);
  const [inputHeight, setInputHeight] = useState(height);
  const defaultWidth = 1500;

  const updateAspectRatio = (svgJson) => {
    const svgWidth = svgJson.attributes.viewBox.split(" ")[2];
    const svgHeight = svgJson.attributes.viewBox.split(" ")[3];
    const newAspectRatio = svgHeight / svgWidth;
    setAspectRatio(newAspectRatio);
    setSliderDisabled(false);
    setInputWidth(defaultWidth);
    setInputHeight(Math.round(defaultWidth * newAspectRatio));
    setWidth(defaultWidth);
    setHeight(Math.round(defaultWidth * newAspectRatio));
  };

  useEffect(() => {
    if (svgJson) {
      updateAspectRatio(svgJson);
    } else {
      setWidth(defaultWidth);
      setHeight(
        aspectRatio ? Math.round(defaultWidth * aspectRatio) : defaultWidth
      );
      setInputWidth(defaultWidth);
      setInputHeight(
        aspectRatio ? Math.round(defaultWidth * aspectRatio) : defaultWidth
      );
      setSliderDisabled(true);
    }
  }, [svgJson, defaultWidth]);

  useEffect(() => {
    if (aspectRatio) {
      setHeight(width * aspectRatio);
    }
  }, [aspectRatio, width]);

  const handleSliderChange = (value) => {
    setWidth(value);
    setHeight(value * aspectRatio);
    setInputWidth(value);
    setInputHeight(value * aspectRatio);
  };

  const handleInputNumberChange = (value, inputType) => {
    if (inputType === "width") {
      setInputWidth(value);
      setWidth(value);
      setHeight(value * aspectRatio);
      setInputHeight(value * aspectRatio);
    } else if (inputType === "height") {
      setInputHeight(value);
      setHeight(value);
      setWidth(value / aspectRatio);
      setInputWidth(value / aspectRatio);
    }
  };

  const handleExportPng = async () => {
    if (!svgJson) {
      return;
    }

    const canvas = document.createElement("canvas");
    const newHeight = width * aspectRatio;

    canvas.width = width;
    canvas.height = newHeight;

    const svgToSave = await stringify(svgJson);

    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svgToSave);

    img.onload = async function () {
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL("image/png");

      const base64Data = btoa(svgToSave);

      if (
        !isPlatform("mobileweb") &&
        (isPlatform("ios") || isPlatform("android"))
      ) {
        Filesystem.writeFile({
          path: svgStore.uri,
          data: base64Data,
          recursive: true,
        }).then(async (svgUri) => {
          let pngLocation = svgUri.uri.replace(".svg", ".png");
          console.log("pngLocation: ", pngLocation);

          Filesystem.writeFile({
            path: pngLocation,
            data: dataURL,
            recursive: true,
          }).then(async (pngUri) => {
            await Share.share({
              title: "Share PNG",
              url: pngUri.uri,
              dialogTitle: "Share PNG",
            });
          });
        });
        if (currentFile.isPublic)
          await incrementViewOrDownloadCount(currentFile.id, "download");
      } else if (isPlatform("desktop") || isPlatform("mobileweb")) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = dataURL;
        a.download = `${exportName || "File Name"}.png`;
        a.click();
        // Clean up and remove the link
        a.parentNode.removeChild(a);
        if (currentFile.isPublic)
          await incrementViewOrDownloadCount(currentFile.id, "download");
      }
    };
  };

  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        <Slider
          min={16}
          max={3000}
          step={1}
          value={width}
          onChange={handleSliderChange}
          tooltipVisible={false}
          disabled={sliderDisabled}
          trackStyle={{ backgroundColor: "#F453C4" }}
          handleStyle={{ border: "none", backgroundColor: "#F453C4" }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <InputNumber
            min={16}
            max={3000}
            step={1}
            value={inputWidth}
            onChange={(value) => handleInputNumberChange(value, "width")}
            formatter={(value) => `${Math.round(value)}`}
            onPressEnter={() => {
              setWidth(inputWidth);
              setHeight(inputWidth * aspectRatio);
            }}
            disabled={sliderDisabled}
          />
          <span
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            {" "}
            x{" "}
          </span>

          <InputNumber
            min={16}
            step={1}
            value={inputHeight}
            onChange={(value) => handleInputNumberChange(value, "height")}
            formatter={(value) => `${Math.round(value)}`}
            onPressEnter={() => {
              setHeight(inputHeight);
              setWidth(inputHeight / aspectRatio);
            }}
            disabled={sliderDisabled}
          />
          <span
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            px{" "}
          </span>
        </div>
      </div>
      <DownloadButton onClick={handleExportPng} />
    </div>
  );
}

export default SVGtoPNGResizer;
