import Jimp from "jimp";
import Potrace from "potrace";
import { closestColor, exactColor, rgbToHex } from "../helpers/helpers";
import process from "../helpers/runonnx";

const convertAlgorithm = "closest";

const processJimpImage = async (imageData, COLORS, numLayers, options) => {
  const { despeckle, simplifyCurves, smoothness, colors, originalDimensions } =
    options;
  console.log("Processing image with Jimp", imageData);
  const image = await Jimp.read(imageData);
  const height = image.getHeight();
  const width = image.getWidth();
  const colorLayers = new Array(numLayers);

  for (let i = 0; i < numLayers; i++) {
    colorLayers[i] = new Jimp(width, height, 0xffffffff);
  }

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      const rgba = Jimp.intToRGBA(image.getPixelColor(x, y));
      if (rgba.a < 25) continue;

      let pixelColorRGB;
      if (convertAlgorithm === "closest") {
        pixelColorRGB = closestColor(
          image.getPixelColor(x, y),
          COLORS,
          numLayers
        );
      } else if (convertAlgorithm === "exact") {
        pixelColorRGB = exactColor(
          image.getPixelColor(x, y),
          COLORS,
          numLayers
        );
        if (pixelColorRGB === false) continue;
      }

      for (let color_i = 0; color_i < colorLayers.length; color_i++) {
        if (
          pixelColorRGB.r === COLORS[color_i][0] &&
          pixelColorRGB.g === COLORS[color_i][1] &&
          pixelColorRGB.b === COLORS[color_i][2]
        ) {
          colorLayers[color_i].setPixelColor(0x000000ff, x, y);
        }
      }
    }
  }

  const traces = Array(numLayers);
  for (let i = 0; i < numLayers; i++) {
    traces[i] = new Potrace.Potrace();
    traces[i].setParameters({
      color: rgbToHex(COLORS[i][0], COLORS[i][1], COLORS[i][2]),
      turdSize: despeckle,
      optTolerance: simplifyCurves,
      alphaMax: smoothness,
    });
  }

  const promises = colorLayers.map(
    (layer, i) =>
      new Promise((resolve) => {
        layer.getBase64(Jimp.MIME_JPEG, (err, src) => {
          traces[i].loadImage(src, () => resolve());
        });
      })
  );

  await Promise.all(promises);

  const svgHead = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" version="1.1">`;
  const svgTail = "</svg>";
  let allPaths = "";
  let blackIndex = -1;

  for (let index = 0; index < colors.length; index++) {
    if (colors[index][0] === "000000") {
      blackIndex = index;
      continue;
    }
    console.log("tracing layer: ", colors[index][1]);
    allPaths += traces[index].getPathTag();
  }

  if (blackIndex !== -1) {
    console.log("tracing layer: ", colors[blackIndex][1]);
    allPaths = traces[blackIndex].getPathTag() + allPaths;
  }

  const newSVG = svgHead + allPaths + svgTail;
  const svgPreview = "data:image/svg+xml;base64," + window.btoa(newSVG);

  const containerWidth = window.innerWidth - 50;
  const containerHeight = (2 * window.innerHeight) / 3;

  let scaledImageHeight = originalDimensions[1];
  let scaledImageWidth = originalDimensions[0];

  let scale = Math.min(
    containerWidth / originalDimensions[0],
    containerHeight / originalDimensions[1]
  );

  if (scale < 1) {
    scaledImageHeight = Math.floor(originalDimensions[1] * scale);
    scaledImageWidth = Math.floor(originalDimensions[0] * scale);
  }

  const blob = new Blob([newSVG], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);

  return {
    svg: newSVG,
    svgPreview,
    downloadSVGLink: url,
    scaledImageHeight,
    scaledImageWidth,
  };
};

export const processImage = async (params) => {
  const {
    colors,
    file,
    crop,
    despeckle,
    simplifyCurves,
    smoothness,
    ai,
    originalDimensions,
  } = params;

  console.log("Starting to process image", params);

  let numLayers = colors.length;
  let COLORS = colors.map((color) => {
    const rgba = Jimp.intToRGBA(parseInt("0x" + color[0] + "00"));
    return [rgba.r, rgba.g, rgba.b];
  });

  const img = new Image();
  img.src = file;

  return new Promise((resolve, reject) => {
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext("2d");
      const size = 3000;
      let cropData = {
        x: crop.x,
        y: crop.y,
        width: crop.width,
        height: crop.height,
      };

      if (cropData.width > size || cropData.height > size) {
        console.log("bigger than ", size);
        const ratio = img.width / img.height;
        canvas.width = ratio > 1 ? size : size * ratio;
        canvas.height = ratio > 1 ? size / ratio : size;
        cropData.x = cropData.x * (canvas.width / img.width);
        cropData.y = cropData.y * (canvas.height / img.height);
        cropData.width = cropData.width * (canvas.width / img.width);
        cropData.height = cropData.height * (canvas.height / img.height);
        console.log("cropped  ", cropData);
      }
;
      console.log(canvas.width, canvas.height);
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(
        cropData.x,
        cropData.y,
        cropData.width,
        cropData.height
      );

      try {
        let processedImageData;
        if (cropData.width * cropData.height < 300 * 300 && ai) {
          console.log("smaller than 250*250, doing a 4x upscale");
          processedImageData = await process(imageData, 4);
        } else if (cropData.width * cropData.height < 600 * 600 && ai) {
          console.log("smaller than 500*500, doing a 2x upscale");
          processedImageData = await process(imageData, 2);
        } else {
          processedImageData = imageData;
        }

        const result = await processJimpImage(
          processedImageData,
          COLORS,
          numLayers,
          {
            despeckle,
            simplifyCurves,
            smoothness,
            colors,
            originalDimensions,
          }
        );

        resolve(result);
      } catch (error) {
        // reject(error);
        console.log(error);
      }
    };
  });
};
