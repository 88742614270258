import { getIdToken, getIdTokenResult } from "firebase/auth";
import { auth } from "../firebase/firebase";

export default async function isUserPremium() {
  await getIdToken(auth.currentUser, true);
  const decodedToken = await getIdTokenResult(auth.currentUser);

  const { stripeRole, lifetimePro } = decodedToken.claims;
  // if stripe role is null reutrn false
  // if (!stripeRole) {
  //     return false;
  //   }

  if (lifetimePro) {
    return "lifetime-pro";
  } else if (stripeRole === "premium") {
    return "premium";
  } else if (stripeRole === "designer-subscriber") {
    return "designer-subscriber";
  } else {
    return false;
  }
  // return false;
}
