import { IonLoading, isPlatform } from "@ionic/react";
import { Button, Col } from "antd";
import { useState } from "react";
import "../index.css";

const Submit = (props) => {
  const [showLoading, setShowLoading] = useState(false);

  const handleSubmit = async () => {
    setShowLoading(true);
    props.setIsLoading(true);
    try {
      if (isPlatform("desktop") || isPlatform("mobileweb")) {
        await props.saveToCloud(true);
      }
    } catch (error) {
      console.error("Error processing image:", error);
    } finally {
      setShowLoading(false);
      props.setIsLoading(false);
    }
  };

  return (
    <Col>
      <IonLoading
        isOpen={showLoading}
        message={"Saving..."}
        id="custom-loading"
      />
      <Button
        disabled={
          props.colors.length < 1 ||
          props.isLoading ||
          props.previewLoading ||
          props.disabled
        }
        onClick={handleSubmit}
        style={{
          background: "#F453C4",
          borderRadius: "4px",
          padding: "6px 12px",
          height: "32px",
          color: "#F8F8F8",
          fontSize: "12px",
          fontWeight: 600,
          fontFamily: "Clash Grotesk",
          display: "flex",
        }}
      >
        {props.children}
      </Button>
    </Col>
  );
};

export default Submit;
