import { IonLabel, IonRange } from "@ionic/react";

const CustomSlider = ({ label, value, max, step, onChange, min }) => {
  return (
    <div style={{ width: "100%" }}>
      <IonLabel
        style={{
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "120%",
        }}
      >
        {label}
      </IonLabel>
      <IonRange
        min={min}
        max={max}
        step={step}
        value={value}
        onIonKnobMoveEnd={(e) => {
          onChange(e.detail.value);
        }}
        style={{
          "--bar-background": "#8E8E8E",
          "--bar-background-active": "#8E8E8E",
          "--bar-height": "1px",
          "--bar-border-radius": "8px",
          "--knob-background": "#F453C4",
          "--knob-size": "18px",
          "--pin-background": "#e83e8c",
          "--height": "10px",
          "--knob-border-radius": "2px",
          "--knob-size": "10px",
          "--knob-box-shadow": "0px 0px 0px 1px #000",
          padding: 0,
        }}
      />
    </div>
  );
};

export default CustomSlider;
