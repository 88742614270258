import { faCoinFront } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonSpinner } from "@ionic/react";
import { Modal } from "antd";
import { useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { createCheckoutSession } from "../../../../lib/stripe/createCheckoutSession";
import "./GetCredits.css";
// import { faCoinFront } from "@fortawesome/pro-regular-svg-icons";

const data = [
  { range: 10, price: "4.99", priceIdentifier: "credits5" },
  { range: 50, price: "9.99", priceIdentifier: "credits10" },
  { range: 100, price: "14.99", priceIdentifier: "credits15" },
];
const GetCredits = ({ setGetCreditModal }) => {
  const { user } = useAuth();
  const [selected, setSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpgrade = async (subscriptionType) => {
    console.log("Upgrade to Premium clicked");
    setIsLoading(true);
    await createCheckoutSession(user.uid, subscriptionType);
  };
  return (
    <Modal
      centered
      open={true}
      width={600}
      footer={null}
      closable={true}
      onCancel={() => setGetCreditModal(false)}
    >
      <div className="modal-body">
        <h5>Get Credits</h5>
        <p className="credits-note">
          Note: Credits are required only for AI image generation.
        </p>
        <div className="cards">
          {data.map((item, i) => (
            <div
              key={item.range}
              className={`card ${selected == i ? "selected" : ""}`}
              onClick={() => setSelected(i)}
            >
              <div className="logo-part">
                <FontAwesomeIcon
                  icon={faCoinFront}
                  style={{
                    color: "#ffd700",
                    fontSize: "20px",
                  }}
                />
                <h5>{item.range}</h5>
              </div>
              <p>US${item.price}</p>
            </div>
          ))}
        </div>
        <p className="total">
          Total <span>US${data[selected].price}</span>
        </p>
        <IonButton
          className="checkout-button"
          onClick={() => handleUpgrade(data[selected].priceIdentifier)}
          disabled={isLoading}
        >
          {isLoading ? <IonSpinner name="lines" /> : "Check Out"}
        </IonButton>
      </div>
    </Modal>
  );
};

export default GetCredits;
