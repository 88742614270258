import { useGesture } from "@use-gesture/react";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import "./Canvas.css";
import Brush from "./components/Brush";
import { incrementViewOrDownloadCount } from "../../../../lib/firebase/firebase";
import { generateSquarePoints } from "../../helpers/generateSquarePoints";
import { MIN_SCALE, DRAG_THRESHOLD } from "../../CONST";
import EditorFooter from "../EditorFooter";

const Canvas = ({
  svg,
  svgJoined,
  setSvg,
  toolSelected,
  layerSelected,
  color,
  eraserRadius,
  vaccumRadius,
  cursor,
  currentScale,
  setCurrentScale,
  position,
  setPosition,
}) => {
  const [touchesState, setTouchesState] = useState(0);
  const currentFile = useSelector((state) => state.files.currentFile);
  const [draggingNode, setDraggingNode] = useState(null);
  console.log({ currentScale, position, touchesState, draggingNode });
  const [contextMenuData, setContextMenuData] = useState({
    visible: false,
    x: 0,
    y: 0,
    pathIndex: null,
    nodeIndex: null,
  });
  const [hoveredNode, setHoveredNode] = useState({
    pathIndex: null,
    nodeIndex: null,
    x: 0,
    y: 0,
  });
  const [selectedNode, setSelectedNode] = useState(null);
  const [mouseDownNode, setMouseDownNode] = useState(null);
  const [spacePressed, setSpacePressed] = useState(false);
  const [cPressed, setCPressed] = useState(false);

  const deletePath = useCallback(
    (i) => {
      if (!svg || !svg.children || !svg.children[layerSelected]) {
        console.error("Invalid svg structure or layer selected");
        return;
      }

      try {
        // Create a deep copy of the svg object
        const tempSvg = JSON.parse(JSON.stringify(svg));

        // Verify the required properties exist
        if (!tempSvg.children[layerSelected]?.children) {
          console.error("Invalid layer structure");
          return;
        }

        // Remove the path at index i
        tempSvg.children[layerSelected].children.splice(i, 1);
        setSvg(tempSvg);
      } catch (error) {
        console.error("Error in deletePath:", error);
        // If JSON parsing fails, try a manual deep copy
        try {
          const tempSvg = {
            ...svg,
            children: svg.children.map((child) => ({
              ...child,
              children: child.children ? [...child.children] : [],
            })),
          };

          if (tempSvg.children[layerSelected]?.children) {
            tempSvg.children[layerSelected].children.splice(i, 1);
            setSvg(tempSvg);
          }
        } catch (fallbackError) {
          console.error("Fallback error handling failed:", fallbackError);
        }
      }
    },
    [svg, layerSelected, setSvg]
  );

  const simulateSquareClicks = useCallback((centerX, centerY, halfLength) => {
    try {
      const numDivisions = 4;
      const points = generateSquarePoints(
        centerX,
        centerY,
        halfLength,
        numDivisions
      );

      points.forEach(([x, y]) => {
        const el = document.elementFromPoint(x, y);
        if (!el) return;

        const evt = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
          clientX: x,
          clientY: y,
        });
        el.dispatchEvent(evt);
      });
    } catch (error) {
      console.error("Error simulating square clicks:", error);
    }
  }, []);

  const handleElementsInRadius = useCallback(
    (xy, radius, shouldCheckSize = false) => {
      const [x, y] = xy;
      const elements = Array.from(document.getElementsByTagName("path")).filter(
        (element) => {
          const rect = element.getClientRects()[0];
          if (!rect) return false;

          const distance = Math.sqrt(
            Math.pow(x - (rect.left + rect.right) / 2, 2) +
              Math.pow(y - (rect.top + rect.bottom) / 2, 2)
          );

          return shouldCheckSize
            ? distance <= radius && rect.width < radius && rect.height < radius
            : distance <= radius;
        }
      );

      if (elements.length > 0) {
        const index = elements[0].className.baseVal.split("-")[1];
        deletePath(index);
      }
    },
    [deletePath]
  );

  const bind = useGesture({
    onDrag: ({ last, delta, movement, touches, xy, event }) => {
      setTouchesState(touches);

      if (draggingNode && toolSelected === "pathAdjust") {
        const [dx, dy] = delta;
        const [mx, my] = movement; // Total movement

        // Check if movement exceeds the threshold
        if (Math.abs(mx) > DRAG_THRESHOLD || Math.abs(my) > DRAG_THRESHOLD) {
          const pathIndex = draggingNode.pathIndex;
          const nodeIndex = draggingNode.nodeIndex;
          const isControlPoint = draggingNode.isControlPoint;

          // Get current path data
          const pathD =
            svg.children[layerSelected]?.children?.[pathIndex]?.attributes?.d;
          if (pathD) {
            const tempSvg = JSON.parse(JSON.stringify(svg));
            const commands = pathD.match(/[a-df-z][^a-df-z]*/gi) || [];
            let currentNodeCounter = -1;

            for (let i = 0; i < commands.length; i++) {
              const cmd = commands[i].trim();
              const type = cmd[0];
              const args = cmd
                .slice(1)
                .trim()
                .split(/[\s,]+/)
                .map(Number);

              if (type === "M" || type === "L") {
                currentNodeCounter++;
                if (!isControlPoint && currentNodeCounter === nodeIndex) {
                  // Update node position
                  const newX = args[0] + dx / currentScale;
                  const newY = args[1] + dy / currentScale;
                  commands[i] = `${type} ${newX} ${newY}`;
                  break;
                }
              } else if (type === "C") {
                currentNodeCounter++;
                if (currentNodeCounter === Math.floor(nodeIndex / 2)) {
                  const isOutgoing = draggingNode.isOutgoing;

                  if (isControlPoint) {
                    // Update control point position
                    if (isOutgoing) {
                      // Update first control point of current C command
                      args[0] += dx / currentScale;
                      args[1] += dy / currentScale;
                    } else {
                      // Update second control point of current C command
                      args[2] += dx / currentScale;
                      args[3] += dy / currentScale;
                    }
                  } else {
                    // Before updating, grab the old node location:
                    const oldNodeX = args[4];
                    const oldNodeY = args[5];

                    // We're moving the main node portion of this C command
                    const newX = oldNodeX + dx / currentScale;
                    const newY = oldNodeY + dy / currentScale;
                    args[4] = newX;
                    args[5] = newY;

                    // Check if the outgoing or incoming control point matched the old node
                    if (args[0] === oldNodeX && args[1] === oldNodeY) {
                      // The outgoing CP is exactly on top of the old node
                      args[0] = newX;
                      args[1] = newY;
                    }
                    if (args[2] === oldNodeX && args[3] === oldNodeY) {
                      // The incoming CP is exactly on top of the old node
                      args[2] = newX;
                      args[3] = newY;
                    }

                    // Also check the next command if it exists and is a C command
                    if (i + 1 < commands.length && commands[i + 1][0] === "C") {
                      const nextCmd = commands[i + 1];
                      const nextArgs = nextCmd
                        .slice(1)
                        .trim()
                        .split(/[\s,]+/)
                        .map(Number);

                      if (
                        nextArgs[0] === oldNodeX &&
                        nextArgs[1] === oldNodeY
                      ) {
                        nextArgs[0] = newX;
                        nextArgs[1] = newY;
                        commands[i + 1] = `C ${nextArgs.join(" ")}`;
                      }
                    }
                  }

                  commands[i] = `C ${args.join(" ")}`;
                  break;
                }
              }
            }

            tempSvg.children[layerSelected].children[pathIndex].attributes.d =
              commands.join(" ");
            setSvg(tempSvg, { skipHistory: !last });
          }
        }

        if (last) {
          setDraggingNode(null);
          setMouseDownNode(null);
        }
        return;
      }

      if (
        spacePressed ||
        touches === 2 ||
        toolSelected === "move" ||
        layerSelected === "all"
      ) {
        setPosition((prev) => ({
          x: prev.x + delta[0],
          y: prev.y + delta[1],
        }));
        return;
      }

      if (layerSelected !== "all" && touches === 1) {
        if (toolSelected === "erase") {
          simulateSquareClicks(xy[0], xy[1], eraserRadius / 2);
        } else if (toolSelected === "vacuum") {
          handleElementsInRadius(xy, vaccumRadius, true);
        }
      }

      if (last) {
        if (draggingNode && toolSelected === "pathAdjust") {
          setDraggingNode(null);
        }
        setMouseDownNode(null);
      }
    },
    onPinch: ({ origin, offset }) => {
      const svg = document.getElementById("svgtest");
      const group = svg?.getElementById("transformGroup");
      if (!svg || !group) return;

      const x = origin[0] - svg.getBoundingClientRect().left;
      const y = origin[1] - svg.getBoundingClientRect().top;
      group.setAttribute("transform-origin", `${x} ${y}`);
      setCurrentScale(offset[0]);
    },
    onWheel: ({ event }) => {
      event.preventDefault();

      // Fallback to a known DOM element if event.currentTarget is null:
      const container =
        event.currentTarget || document.getElementById("svgtest");
      if (!container) return;

      // Safely call getBoundingClientRect:
      const svgRect = container.getBoundingClientRect
        ? container.getBoundingClientRect()
        : null;
      if (!svgRect) return;

      const oldScale = currentScale;
      const zoomFactor = 1 + (event.deltaY < 0 ? 0.1 : -0.1);
      const newScale = Math.max(MIN_SCALE, oldScale * zoomFactor);

      const offsetX = event.clientX - svgRect.left - position.x;
      const offsetY = event.clientY - svgRect.top - position.y;

      const newOffsetX = offsetX * (newScale / oldScale);
      const newOffsetY = offsetY * (newScale / oldScale);

      setPosition({
        x: event.clientX - svgRect.left - newOffsetX,
        y: event.clientY - svgRect.top - newOffsetY,
      });

      setCurrentScale(newScale);
    },
    onPointerUp: () => {
      if (draggingNode && toolSelected === "pathMovePoints") {
        setDraggingNode(null);
      }
    },
  });

  const changeColor = useCallback(
    (i) => {
      if (!svg || !svg.children) return;

      const tempSvg = { ...svg };
      if (tempSvg.children[i]) {
        tempSvg.children[i].attributes.fill = color;
        setSvg(tempSvg);
      }
    },
    [svg, color, setSvg]
  );

  const handlePathEdit = useCallback(
    (pathIndex) => {
      if (toolSelected === "pathDeletePoints") {
        // console.log(`Deleting a path node at index: ${pathIndex}`);
      }
    },
    [toolSelected]
  );

  const addPointToPath = (pathIndex, clientX, clientY) => {
    try {
      // Convert clientX/clientY into the local SVG coordinate:
      const svgRect = document
        .getElementById("svgtest")
        ?.getBoundingClientRect();
      if (!svgRect || !svg?.children[layerSelected]?.children?.[pathIndex])
        return;

      const localX = (clientX - svgRect.left - position.x) / currentScale;
      const localY = (clientY - svgRect.top - position.y) / currentScale;

      const tempSvg = JSON.parse(JSON.stringify(svg));
      const pathObj = tempSvg.children[layerSelected].children[pathIndex];
      let d = pathObj.attributes.d;
      let commands = d.match(/[a-df-z][^a-df-z]*/gi) || [];

      // We'll keep track of all the node positions:
      let points = [];
      let moveCommandIndex = -1;

      // Build an array of [x, y, commandIndex]
      // so we can figure out which segment (between consecutive points) is nearest.
      for (let i = 0; i < commands.length; i++) {
        const cmd = commands[i].trim();
        const type = cmd[0];
        const args = cmd
          .slice(1)
          .trim()
          .split(/[\s,]+/)
          .map(Number);
        if (type === "M" || type === "L") {
          points.push({
            x: args[0],
            y: args[1],
            index: i,
          });
          if (type === "M" && moveCommandIndex < 0) {
            moveCommandIndex = i;
          }
        }
      }

      // Find the closest segment:
      let minDist = Infinity;
      let insertIndex = -1; // index in the points array
      for (let i = 0; i < points.length - 1; i++) {
        const p1 = points[i];
        const p2 = points[i + 1];

        // distance from click to the line segment p1->p2:
        // We'll do a simple approximate check with midpoint or a more precise approach.
        const mid = {
          x: (p1.x + p2.x) / 2,
          y: (p1.y + p2.y) / 2,
        };
        const dist = Math.hypot(localX - mid.x, localY - mid.y);
        if (dist < minDist) {
          minDist = dist;
          insertIndex = i;
        }
      }
      if (insertIndex < 0) return;

      // Insert new command after points[insertIndex].index
      // For example, if that command was L x2 y2, we'll replace it with:
      // L [the new point], L [the original point].
      const segmentCommandIndex = points[insertIndex + 1].index;
      const oldCmd = commands[segmentCommandIndex];
      // e.g. "L 100 200"

      // Create the new commands:
      const newCmd = `L ${localX} ${localY}`;
      // We overwrite the original L with our new L point:
      commands[segmentCommandIndex] = newCmd;
      // Then we insert a new command after it which points to p2.x, p2.y from oldCmd:
      const oldArgs = oldCmd
        .slice(1)
        .trim()
        .split(/[\s,]+/)
        .map(Number);
      const p2x = oldArgs[0];
      const p2y = oldArgs[1];
      commands.splice(segmentCommandIndex + 1, 0, `L ${p2x} ${p2y}`);

      // Reassign the updated path data
      pathObj.attributes.d = commands.join(" ");
      setSvg(tempSvg);
    } catch (error) {
      console.error("Error in addPointToPath:", error);
    }
  };

  const clickHandler = useCallback(
    (i, event) => {
      if (toolSelected === "fill") {
        changeColor(i);
      } else if (["cut", "erase"].includes(toolSelected)) {
        deletePath(i);
      } else if (toolSelected === "pathDeletePoints") {
        handlePathEdit(i);
      } else if (toolSelected === "pathAddPoints") {
        // Use the event to get the click coordinates
        addPointToPath(i, event.clientX, event.clientY);
      }
    },
    [toolSelected, changeColor, deletePath, handlePathEdit]
  );

  useEffect(() => {
    if (currentFile.isPublic) {
      incrementViewOrDownloadCount(currentFile.id, "view");
    }
  }, [currentFile]);

  useEffect(() => {
    // Listen for keydown and keyup to toggle cPressed
    const handleKeyDown = (e) => {
      if (e.key === "c" || e.code === "KeyC") {
        setCPressed(true);
      }
      if (e.code === "Space") {
        setSpacePressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "c" || e.code === "KeyC") {
        setCPressed(false);
      }
      if (e.code === "Space") {
        setSpacePressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (!svg?.attributes || !svgJoined?.attributes) {
    return <div className="svg-container" key={svg}></div>;
  }

  const cursorStyle = ["erase", "vacuum"].includes(toolSelected)
    ? "none"
    : cursor;

  // Update the parsePathData function to nullify control points that are exactly on the node
  const parsePathData = (d) => {
    const commands = d.match(/[a-df-z][^a-df-z]*/gi);
    const nodes = [];
    const controlPoints = [];
    let currentPoint = [0, 0];

    commands.forEach((command) => {
      const type = command[0];
      const args = command
        .slice(1)
        .trim()
        .split(/[\s,]+/)
        .map(Number);

      switch (type) {
        case "M":
          currentPoint = [args[0], args[1]];
          nodes.push([...currentPoint]);
          controlPoints.push([null, null]);
          break;
        case "L":
          currentPoint = [args[0], args[1]];
          nodes.push([...currentPoint]);
          controlPoints.push([null, null]);
          break;
        case "C":
          const incomingCP = [args[2], args[3]];
          // Set outgoing CP for the previous node
          if (nodes.length > 0) {
            // outgoing CP
            const outgoingCP = [args[0], args[1]];
            // If outgoing is exactly the same as the previous node, nullify it
            if (
              outgoingCP[0] === nodes[nodes.length - 1][0] &&
              outgoingCP[1] === nodes[nodes.length - 1][1]
            ) {
              controlPoints[controlPoints.length - 1][1] = null;
            } else {
              controlPoints[controlPoints.length - 1][1] = outgoingCP;
            }
          }
          // End point for this curve
          currentPoint = [args[4], args[5]];

          // If incoming CP is exactly the same as this node, nullify it
          let incomingValue = incomingCP;
          if (
            incomingCP[0] === currentPoint[0] &&
            incomingCP[1] === currentPoint[1]
          ) {
            incomingValue = null;
          }
          nodes.push([...currentPoint]);
          controlPoints.push([incomingValue, null]);
          break;
        default:
          break;
      }
    });

    return { nodes, controlPoints };
  };

  // Function to update a node or control point position
  const updatePoint = (
    pathIndex,
    pointIndex,
    newPosition,
    isControlPoint = false,
    skipHistory = false
  ) => {
    const tempSvg = JSON.parse(JSON.stringify(svg));
    const path = tempSvg.children[layerSelected].children[pathIndex];
    const d = path.attributes.d;
    const commands = d.match(/[a-df-z][^a-df-z]*/gi);

    if (isControlPoint) {
      let nodeCounter = 0;

      for (let i = 0; i < commands.length; i++) {
        const cmd = commands[i];
        const type = cmd[0];

        if (type === "C") {
          if (Math.floor(pointIndex / 2) === nodeCounter) {
            const args = cmd
              .slice(1)
              .trim()
              .split(/[\s,]+/)
              .map(Number);
            if (pointIndex % 2 === 0) {
              // First control point
              commands[i] =
                `C ${newPosition[0]} ${newPosition[1]} ${args[2]} ${args[3]} ${args[4]} ${args[5]}`;
            } else {
              // Second control point
              commands[i] =
                `C ${args[0]} ${args[1]} ${newPosition[0]} ${newPosition[1]} ${args[4]} ${args[5]}`;
            }
            break;
          }
          nodeCounter++;
        }
      }
    } else {
      // Handle regular node points
      let nodeCounter = 0;
      for (let i = 0; i < commands.length; i++) {
        const type = commands[i][0];
        if (
          (type === "M" || type === "L" || type === "C") &&
          nodeCounter === pointIndex
        ) {
          if (type === "C") {
            const args = commands[i]
              .slice(1)
              .trim()
              .split(/[\s,]+/)
              .map(Number);
            commands[i] =
              `C ${args[0]} ${args[1]} ${args[2]} ${args[3]} ${newPosition[0]} ${newPosition[1]}`;
          } else {
            commands[i] = `${type} ${newPosition[0]} ${newPosition[1]}`;
          }
          break;
        }
        if (type === "M" || type === "L" || type === "C") {
          nodeCounter++;
        }
      }
    }

    path.attributes.d = commands.join(" ");
    setSvg(tempSvg, { skipHistory });
  };

  const parseSegments = (d) => {
    const commands = d.match(/[a-df-z][^a-df-z]*/gi) || [];
    const segments = [];
    let currentPoint = [0, 0];

    commands.forEach((cmdStr, index) => {
      const type = cmdStr[0];
      const args = cmdStr
        .slice(1)
        .trim()
        .split(/[\s,]+/)
        .map(Number);

      switch (type) {
        case "M": {
          currentPoint = [args[0], args[1]];
          break;
        }
        case "L": {
          const nextPoint = [args[0], args[1]];
          segments.push({
            type: "L",
            from: [...currentPoint],
            to: nextPoint,
          });
          currentPoint = nextPoint;
          break;
        }
        case "C": {
          // cubic: p0 -> c1 -> c2 -> p1
          const c1 = [args[0], args[1]];
          const c2 = [args[2], args[3]];
          const p1 = [args[4], args[5]];
          segments.push({
            type: "C",
            from: [...currentPoint],
            c1,
            c2,
            to: p1,
          });
          currentPoint = p1;
          break;
        }
        default:
          break;
      }
    });

    return segments;
  };

  const deleteNodeFromPath = (pathIndex, nodeIndex) => {
    try {
      const tempSvg = JSON.parse(JSON.stringify(svg));
      const path = tempSvg.children[layerSelected].children[pathIndex];
      const d = path.attributes.d;
      let commands = d.match(/[a-df-z][^a-df-z]*/gi) || [];

      // We'll count each node (for M or L command, or final point of C).
      // When we reach the target nodeIndex, we remove that command
      // (for M/L) or modify the C command if it's the final node in a C.
      let currentNodeCounter = -1;

      for (let i = 0; i < commands.length; i++) {
        const cmd = commands[i].trim();
        const type = cmd[0];
        const args = cmd
          .slice(1)
          .trim()
          .split(/[\s,]+/)
          .map(Number);

        // M or L => single node in that command
        if (type === "M" || type === "L") {
          currentNodeCounter++;
          if (currentNodeCounter === nodeIndex) {
            // If it's an 'M' at the beginning (i === 0), removing it
            // can invalidate the path. You may need special rules.
            // For now, we'll remove it if it's not the very first command:
            if (!(type === "M" && i === 0)) {
              commands.splice(i, 1);
            }
            break;
          }
        }

        // C => has 3 points (two control points + one "node" point)
        // The last pair is the main node. We'll treat that as one node
        if (type === "C") {
          // Each C command has 6 numeric arguments => 3 pairs
          // The final pair is the actual node
          currentNodeCounter++;
          if (currentNodeCounter === nodeIndex) {
            // Remove entire C if desired, or try to degrade to an L.
            // For simplicity, let's remove the entire segment:
            commands.splice(i, 1);
            break;
          }
        }
      }

      path.attributes.d = commands.join(" ");
      setSvg(tempSvg);
    } catch (err) {
      console.error("Error deleting node:", err);
    }
  };

  // Add this new function near the other path manipulation functions
  const deleteControlPoint = (pathIndex, nodeIndex, isOutgoing) => {
    try {
      const tempSvg = JSON.parse(JSON.stringify(svg));
      const path = tempSvg.children[layerSelected].children[pathIndex];
      const d = path.attributes.d;
      const commands = d.match(/[a-df-z][^a-df-z]*/gi) || [];

      let currentNodeCounter = 0;

      for (let i = 0; i < commands.length; i++) {
        const cmd = commands[i].trim();
        const type = cmd[0];

        if (type === "C" && currentNodeCounter === Math.floor(nodeIndex / 2)) {
          const args = cmd
            .slice(1)
            .trim()
            .split(/[\s,]+/)
            .map(Number);
          const endPoint = [args[4], args[5]];

          if (isOutgoing) {
            // Remove first control point by setting it to the start point
            const startPoint =
              currentNodeCounter > 0
                ? getPointFromPreviousCommand(commands[i - 1])
                : [0, 0];
            commands[i] =
              `C ${startPoint[0]} ${startPoint[1]} ${args[2]} ${args[3]} ${endPoint[0]} ${endPoint[1]}`;
          } else {
            // Remove second control point by setting it to the end point
            commands[i] =
              `C ${args[0]} ${args[1]} ${endPoint[0]} ${endPoint[1]} ${endPoint[0]} ${endPoint[1]}`;
          }
          break;
        }

        if (type === "M" || type === "L" || type === "C") {
          currentNodeCounter++;
        }
      }

      path.attributes.d = commands.join(" ");
      setSvg(tempSvg);
    } catch (err) {
      console.error("Error deleting control point:", err);
    }
  };

  // Helper function to get the endpoint from a previous command
  const getPointFromPreviousCommand = (cmd) => {
    if (!cmd) return [0, 0];
    const type = cmd[0];
    const args = cmd
      .slice(1)
      .trim()
      .split(/[\s,]+/)
      .map(Number);

    switch (type) {
      case "M":
      case "L":
        return [args[0], args[1]];
      case "C":
        return [args[4], args[5]];
      default:
        return [0, 0];
    }
  };

  // First, let's extract the delete logic into a helper function
  const handlePointDeletion = (
    pathIndex,
    nodeIndex,
    isControlPoint,
    isIncoming
  ) => {
    if (isControlPoint) {
      deleteControlPoint(pathIndex, nodeIndex, !isIncoming);
    } else {
      deleteNodeFromPath(pathIndex, nodeIndex);
    }
  };

  // Add this new helper function near the other path manipulation functions
  const deleteNodeControlPoints = (pathIndex, nodeIndex) => {
    try {
      const tempSvg = JSON.parse(JSON.stringify(svg));
      const path = tempSvg.children[layerSelected].children[pathIndex];
      const d = path.attributes.d;
      const commands = d.match(/[a-df-z][^a-df-z]*/gi) || [];

      let currentNodeCounter = 0;

      for (let i = 0; i < commands.length; i++) {
        const cmd = commands[i].trim();
        const type = cmd[0];

        // Check if this is the node we want to modify
        if (type === "C" && currentNodeCounter === nodeIndex) {
          // Parse out the control points
          const args = cmd
            .slice(1)
            .trim()
            .split(/[\s,]+/)
            .map(Number);
          // [x1, y1, x2, y2, x3, y3]
          // x3,y3 is the endpoint for this command
          const [x1, y1, x2, y2, x3, y3] = args;

          // 1) Remove directions on the *current* node by
          //    setting control points to the endpoint (x3,y3)
          commands[i] = `C ${x3} ${y3} ${x3} ${y3} ${x3} ${y3}`;

          // 2) Remove directions on the *next* node if it also has
          //    control points referencing this same endpoint
          if (i + 1 < commands.length) {
            const nextCmd = commands[i + 1].trim();
            const nextType = nextCmd[0];

            if (nextType === "C") {
              const nextArgs = nextCmd
                .slice(1)
                .trim()
                .split(/[\s,]+/)
                .map(Number);
              // In the next command, the first control point(s) often form the handle from
              // this same node's end, so set them also to (x3,y3).
              // nextArgs => [nx1, ny1, nx2, ny2, nx3, ny3]
              nextArgs[0] = x3;
              nextArgs[1] = y3;
              nextArgs[2] = x3;
              nextArgs[3] = y3;

              // Keep the final endpoint for the next command:
              // nextArgs[4], nextArgs[5] remain the same
              commands[i + 1] =
                `C ${nextArgs[0]} ${nextArgs[1]} ${nextArgs[2]} ${nextArgs[3]} ${nextArgs[4]} ${nextArgs[5]}`;
            }
          }

          // Once we've handled the node index, we can break out
          break;
        }

        // Increment counter each time we encounter a node-type command
        if (type === "M" || type === "L" || type === "C") {
          currentNodeCounter++;
        }
      }

      // Update the 'd' attribute and trigger re-render
      path.attributes.d = commands.join(" ");
      setSvg(tempSvg);
    } catch (err) {
      console.error("Error deleting control points:", err);
    }
  };

  // ADD/UPDATE this helper function somewhere near parseSegments:
  const renderCurvePreviewSegments = (d, pathIndex) => {
    const segments = parseSegments(d);
    return segments.map((segment, i) => {
      if (segment.type === "L") {
        return (
          <line
            key={`segment-${pathIndex}-${i}`}
            x1={segment.from[0]}
            y1={segment.from[1]}
            x2={segment.to[0]}
            y2={segment.to[1]}
            stroke="#ff00ff"
            strokeWidth={1 / currentScale}
            fill="none"
          />
        );
      } else if (segment.type === "C") {
        const pathD = `M ${segment.from[0]} ${segment.from[1]} 
                       C ${segment.c1[0]} ${segment.c1[1]} 
                         ${segment.c2[0]} ${segment.c2[1]} 
                         ${segment.to[0]} ${segment.to[1]}`;
        return (
          <path
            key={`segment-${pathIndex}-${i}`}
            d={pathD}
            stroke="#ff00ff"
            strokeWidth={1 / currentScale}
            fill="none"
          />
        );
      }
      return null;
    });
  };

  // Then in your renderNodes function, UPDATE it like so:
  const renderNodes = () => {
    if (toolSelected !== "pathAdjust" || layerSelected === "all") {
      return null;
    }

    const pathData = svg.children[layerSelected]?.children?.map(
      (child) => child.attributes.d
    );
    if (!pathData) return null;

    return pathData.map((d, pathIndex) => {
      const { nodes, controlPoints } = parsePathData(d);

      return (
        <g key={`path-${pathIndex}`}>
          {renderCurvePreviewSegments(d, pathIndex)}

          {nodes.map((node, nodeIndex) => {
            const isHovered =
              hoveredNode.pathIndex === pathIndex &&
              hoveredNode.nodeIndex === nodeIndex;
            const isSelected =
              selectedNode?.pathIndex === pathIndex &&
              selectedNode?.nodeIndex === nodeIndex;
            const nodeControlPoints = controlPoints[nodeIndex];

            return (
              <g key={`node-${pathIndex}-${nodeIndex}`}>
                <rect
                  x={node[0] - (isSelected ? 3 : 2) / currentScale}
                  y={node[1] - (isSelected ? 3 : 2) / currentScale}
                  width={(isSelected ? 6 : 4) / currentScale}
                  height={(isSelected ? 6 : 4) / currentScale}
                  fill={isHovered ? color : isSelected ? "#3d5edb" : "#ff00ff"}
                  stroke="white"
                  strokeWidth={1 / currentScale}
                  cursor="pointer"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    if (cPressed) {
                      deleteNodeControlPoints(pathIndex, nodeIndex);
                      return;
                    }
                    if (e.shiftKey) {
                      deleteNodeFromPath(pathIndex, nodeIndex);
                    } else {
                      setSelectedNode({ pathIndex, nodeIndex });
                      setMouseDownNode({ pathIndex, nodeIndex });
                      if (toolSelected === "pathAdjust") {
                        setDraggingNode({
                          pathIndex,
                          nodeIndex: nodeIndex * 2,
                        });
                      }
                    }
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedNode({ pathIndex, nodeIndex });
                    setContextMenuData({
                      visible: true,
                      x: e.clientX,
                      y: e.clientY,
                      pathIndex,
                      nodeIndex,
                    });
                  }}
                  onMouseEnter={() =>
                    setHoveredNode({
                      pathIndex,
                      nodeIndex,
                      x: node[0],
                      y: node[1],
                    })
                  }
                  onMouseLeave={() =>
                    setHoveredNode({
                      pathIndex: null,
                      nodeIndex: null,
                      x: 0,
                      y: 0,
                    })
                  }
                />

                {isSelected && (
                  <>
                    {nodeControlPoints[0] && (
                      <>
                        <line
                          x1={node[0]}
                          y1={node[1]}
                          x2={nodeControlPoints[0][0]}
                          y2={nodeControlPoints[0][1]}
                          stroke={
                            isSelected
                              ? "rgba(61, 94, 219, 0.7)"
                              : "rgba(255, 0, 255, 0.7)"
                          }
                          strokeWidth={1 / currentScale}
                        />
                        <circle
                          cx={nodeControlPoints[0][0]}
                          cy={nodeControlPoints[0][1]}
                          r={3 / currentScale}
                          fill={isSelected ? "#3d5edb" : "#ff00ff"}
                          stroke="white"
                          strokeWidth={1 / currentScale}
                          cursor="pointer"
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            if (e.shiftKey) {
                              handlePointDeletion(
                                pathIndex,
                                nodeIndex * 2,
                                true,
                                true
                              );
                            } else {
                              setDraggingNode({
                                pathIndex,
                                nodeIndex: nodeIndex * 2,
                                isControlPoint: true,
                                isIncoming: true,
                              });
                            }
                          }}
                          onContextMenu={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContextMenuData({
                              visible: true,
                              x: e.clientX,
                              y: e.clientY,
                              pathIndex,
                              nodeIndex: nodeIndex * 2,
                              isControlPoint: true,
                              isIncoming: true,
                            });
                          }}
                        />
                      </>
                    )}

                    {nodeControlPoints[1] && (
                      <>
                        <line
                          x1={node[0]}
                          y1={node[1]}
                          x2={nodeControlPoints[1][0]}
                          y2={nodeControlPoints[1][1]}
                          stroke={
                            isSelected
                              ? "rgba(61, 94, 219, 0.7)"
                              : "rgba(255, 0, 255, 0.7)"
                          }
                          strokeWidth={1 / currentScale}
                        />
                        <circle
                          cx={nodeControlPoints[1][0]}
                          cy={nodeControlPoints[1][1]}
                          r={3 / currentScale}
                          fill={isSelected ? "#3d5edb" : "#ff00ff"}
                          stroke="white"
                          strokeWidth={1 / currentScale}
                          cursor="pointer"
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            if (e.shiftKey) {
                              handlePointDeletion(
                                pathIndex,
                                nodeIndex * 2 + 2,
                                true,
                                false
                              );
                            } else {
                              setDraggingNode({
                                pathIndex,
                                nodeIndex: nodeIndex * 2 + 2,
                                isControlPoint: true,
                                isOutgoing: true,
                              });
                            }
                          }}
                          onContextMenu={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setContextMenuData({
                              visible: true,
                              x: e.clientX,
                              y: e.clientY,
                              pathIndex,
                              nodeIndex: nodeIndex * 2 + 2,
                              isControlPoint: true,
                              isOutgoing: true,
                            });
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </g>
            );
          })}
        </g>
      );
    });
  };

  // Update the renderContextMenu function to include the new option
  const renderContextMenu = () => {
    if (!contextMenuData.visible) return null;
    const { x, y, pathIndex, nodeIndex, isControlPoint, isIncoming } =
      contextMenuData;

    return (
      <div
        style={{
          position: "fixed",
          left: x,
          top: y,
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "4px",
          zIndex: 9999,
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div
          style={{ padding: "4px 8px", cursor: "pointer" }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#f0f0f0")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "white")
          }
          onClick={() => {
            handlePointDeletion(
              pathIndex,
              nodeIndex,
              isControlPoint,
              isIncoming
            );
            setContextMenuData((prev) => ({ ...prev, visible: false }));
          }}
        >
          Delete {isControlPoint ? "Control Point" : "Node"} (Shift+Click)
        </div>
        {/* Only show Delete Control Points option for nodes (not control points) */}
        {!isControlPoint && (
          <div
            style={{ padding: "4px 8px", cursor: "pointer" }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#f0f0f0")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "white")
            }
            onClick={() => {
              deleteNodeControlPoints(pathIndex, nodeIndex);
              setContextMenuData((prev) => ({ ...prev, visible: false }));
            }}
          >
            Corner (C+Click)
          </div>
        )}
        <div
          style={{ padding: "4px 8px", cursor: "pointer" }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#f0f0f0")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "white")
          }
          onClick={() => {
            setContextMenuData((prev) => ({ ...prev, visible: false }));
          }}
        >
          Cancel
        </div>
      </div>
    );
  };

  return (
    <div
      {...bind()}
      className="svg-container"
      style={{ height: "100%", width: "100%", overflow: "hidden" }}
      onMouseDown={(e) => {
        if (contextMenuData.visible) {
          setContextMenuData((prev) => ({ ...prev, visible: false }));
        }
        if (e.target.tagName.toLowerCase() !== "circle") {
          setSelectedNode(null);
        }
      }}
    >
      <svg
        id="svgtest"
        width="100%"
        height="100%"
        style={{ cursor: cursorStyle }}
      >
        <g
          id="transformGroup"
          transform={`translate(${position.x} ${position.y}) scale(${currentScale})`}
        >
          <svg
            viewBox={svg.attributes.viewBox}
            id="svgContainer"
            xmlns="http://www.w3.org/2000/svg"
            width={svg.attributes.width}
            height={svg.attributes.height}
          >
            {layerSelected === "all" ? (
              svgJoined.children?.map((child, i) => (
                <path
                  key={i}
                  d={child.attributes.d}
                  stroke="none"
                  fill={child.attributes.fill}
                  fillRule={child.attributes["fill-rule"]}
                />
              ))
            ) : (
              <>
                {svgJoined.children?.[layerSelected] && (
                  <path
                    d={svgJoined.children[layerSelected].attributes.d}
                    stroke="none"
                    fill={svgJoined.children[layerSelected].attributes.fill}
                    fillRule={
                      svgJoined.children[layerSelected].attributes["fill-rule"]
                    }
                  />
                )}
                {svg.children[layerSelected]?.children?.map((object, i) => (
                  <path
                    key={i}
                    className={`path-${i}`}
                    onClick={(e) => clickHandler(i, e)}
                    d={object.attributes.d}
                    strokeWidth="1"
                    stroke="none"
                    fill={object.attributes.fill}
                    fillOpacity="0%"
                  />
                ))}
                {renderNodes()}
              </>
            )}
          </svg>
        </g>
        {touchesState !== 2 &&
          (toolSelected === "erase" || toolSelected === "vacuum") && (
            <Brush
              radius={toolSelected === "erase" ? eraserRadius : vaccumRadius}
              toolSelected={toolSelected}
            />
          )}
      </svg>
      {renderContextMenu()}
      <EditorFooter
        currentScale={currentScale}
        setCurrentScale={setCurrentScale}
        setPosition={setPosition}
      />
    </div>
  );
};

export default Canvas;
