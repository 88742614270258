import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import Checkout from "../Checkout";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { user, userIsPremium } = useAuth();
  const [userIsPremiumState, setUserIsPremiumState] = useState(null);

  useEffect(() => {
    if (user && userIsPremium) {
      setUserIsPremiumState(true);
    } else {
      setUserIsPremiumState(false);
    }
  }, [user, userIsPremium]);

  return (
    <>
      {userIsPremiumState !== null && (
        <Route
          {...rest}
          render={(props) =>
            userIsPremiumState ? (
              <Component {...props} />
            ) : user ? (
              <>
                <Checkout />
              </>
            ) : (
              <Redirect to="/signin" />
            )
          }
        />
      )}
    </>
  );
}
