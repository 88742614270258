import { notification } from "antd";
import { useContext } from "react";
import AppContext from "../../contexts/AppContext";
import DallePromptForm from "./components/DallePromptForm";
import "./styles.css";

const ImageGenerator = () => {
  const { image64 } = useContext(AppContext); // Using the context to get the image data and source
  const [, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      <DallePromptForm image64={image64} />
    </>
  );
};

export default ImageGenerator;
