import { configureStore } from "@reduxjs/toolkit";
import files from "./slices/MyFiles";
import CurrentTab from "./slices/CurrentTab";
import UploadedFiles from "./slices/UploadedFiles";
import CommunityFiles from "./slices/CommunityFiles";
import BulkConversion from "./slices/BulkConversion";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    files: files,
    uploadedFiles: UploadedFiles,
    currentTab: CurrentTab,
    communityFiles: CommunityFiles,
    BulkConversion,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
