import {
  IonButtons,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button, Col, Row } from "antd";
import { Crop } from "react-feather";
import AdvancedSettings from "./AdvancedSettings";
import ColorPreview from "./ColorPreview";
import CropPng from "./CropPng";
import MainCanvas from "./MainCanvas";
import Submit from "./Submit";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Tutorial from "../Tutorial";
import { useAppDispatch } from "../../../redux/store";
import { resetBulkConversionState } from "../../../redux/slices/BulkConversion";
import { processImage } from "./processImage";
import { useDebounceEffect } from "./useDebounceEffect";

const ConverterMobile = ({
  screenSize,
  crop,
  setCrop,
  setFile,
  file,
  scale,
  setScale,
  setClickedColor,
  setImageDimensions,
  originalDimensions,
  largeImageWarning,
  downloadSVGLink,
  isLoading,
  setIsCropVisible,
  isCropVisible,
  smoothness,
  setSmoothness,
  despeckle,
  setDespeckle,
  simplifyCurves,
  setSimplifyCurves,
  imageDimensions,
  ai,
  setAi,
  colors,
  setColors,
  setSvgPreview,
  setPreviewReady,
  setSvg,
  setShareLink,
  setIsModalVisible,
  setIsLoading,
  setDownloadSVGLink,
  setLoadingConverter,
  setConverterOpen,
  clearFields,
  saveToCloud,
}) => {
  const dispatch = useAppDispatch();
  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => setLoadingConverter(false), []);

  useDebounceEffect(
    () => {
      (async () => {
        setPreviewLoading(true);
        try {
          const result = await processImage({
            colors,
            file,
            crop,
            despeckle,
            simplifyCurves,
            smoothness,
            ai,
            originalDimensions,
          });

          setSvg(result.svg);
          setSvgPreview(result.svgPreview);
          setDownloadSVGLink(result.downloadSVGLink);
        } catch (error) {
          console.error("Error processing image:", error);
        } finally {
          setPreviewLoading(false);
        }
      })();
    },
    1000,
    [
      colors,
      file,
      crop,
      despeckle,
      simplifyCurves,
      smoothness,
      ai,
      originalDimensions,
    ]
  );

  return (
    <>
      <div id="color" hidden></div>
      <div id="status" hidden></div>
      <IonHeader id="colorPickerHeader" style={{ backgroundColor: "#f0f0f0" }}>
        <IonToolbar>
          <IonButtons slot="start" style={{ marginLeft: "20px" }}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              onClick={() => {
                clearFields();
                setConverterOpen(false);
                dispatch(resetBulkConversionState());
              }}
            ></FontAwesomeIcon>
          </IonButtons>
          <IonTitle>Select Colors</IonTitle>

          <IonButtons slot="end" style={{ marginRight: "20px" }}>
            <Tutorial />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonGrid id="middleBox" style={{ height: "100%", padding: "0px" }}>
          <IonRow style={{ height: "100%", backgroundColor: "#f0f0f0" }}>
            <MainCanvas
              screenSize={screenSize}
              crop={crop}
              setFile={setFile}
              file={file}
              scale={scale}
              setScale={setScale}
              setClickedColor={setClickedColor}
            />
            <CropPng
              setScale={setScale}
              setImageDimensions={setImageDimensions}
              originalDimensions={originalDimensions}
              cropGlobal={crop}
              setCropGlobal={setCrop}
              largeImageWarning={largeImageWarning}
              file={file}
              isModalVisible={isCropVisible}
              setIsModalVisible={setIsCropVisible}
              downloadSVGLink={downloadSVGLink}
              isLoading={isLoading}
            />
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar style={{ color: "#f0f0f0" }}>
          <Row
            justify="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              width: "100%",
            }}
          >
            <AdvancedSettings
              smoothness={smoothness}
              despeckle={despeckle}
              simplifyCurves={simplifyCurves}
              setSmoothness={setSmoothness}
              setDespeckle={setDespeckle}
              setSimplifyCurves={setSimplifyCurves}
              imageDimensions={imageDimensions}
              ai={ai}
              setAi={setAi}
            />
            <Col style={{ paddingRight: "10px" }}>
              <Button
                type="text"
                onClick={() => {
                  setIsCropVisible(true);
                }}
                style={{ width: "100%" }}
              >
                <Row justify="center">
                  <Crop size={14} style={{}} />
                </Row>
                <Row
                  justify="center"
                  style={{ fontSize: "10px", maxWidth: "20px" }}
                >
                  Crop
                </Row>
              </Button>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              width: "100%",
            }}
          >
            <Col
              justify="space-between"
              style={{
                maxWidth: `calc(95%)`,
                overflowX: "auto",
                marginTop: "-15px",
              }}
            >
              <IonRow style={{ minWidth: `${(colors.length + 1) * 50}px` }}>
                <ColorPreview colors={colors} setColors={setColors} />
              </IonRow>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "40px",
              width: "100%",
              marginTop: "-5px",
            }}
          >
            <Submit
              setFile={setFile}
              setSvgPreview={setSvgPreview}
              setPreviewReady={setPreviewReady}
              setSvg={setSvg}
              originalDimensions={originalDimensions}
              crop={crop}
              despeckle={despeckle}
              simplifyCurves={simplifyCurves}
              smoothness={smoothness}
              colors={colors}
              file={file}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setDownloadSVGLink={setDownloadSVGLink}
              setShareLink={setShareLink}
              setIsModalVisible={setIsModalVisible}
              ai={ai}
              saveToCloud={saveToCloud}
              disabled={previewLoading}
            >
              Convert
            </Submit>
          </Row>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ConverterMobile;
