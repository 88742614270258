import {
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLoading,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Crop, Save } from "react-feather";
import useSaveSVG from "../../../hooks/useSaveSvg";
import AutoUploadImage from "../../Converter/components/AutoUploadImage";
import useSvgUpdater from "../SvgUpdater";
import Canvas from "./Canvas";
import Close from "./Close/Close";
import CropImage from "./CropSvg";
import ExportFile from "./ExportFile";
import LayerSelector from "./LayerSelector";
import ToolBar from "./ToolBar";
import TopBar from "./TopBar";
import Tutorial from "./Tutorial";
import { faArrowUpFromBracket, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SvgEditorMobile = () => {
  const {
    svg,
    svgJoined,
    undoActions,
    redoActions,
    colors,
    changesMade,
    setChangesMade,
    setColors,
    updateSvg,
    undo,
    redo,
    currentFile,
  } = useSvgUpdater();

  const [cursor, setCursor] = useState(`crosshair`);
  const [layerSelected, setLayerSelected] = useState("all");
  const [toolbarState, setToolbarState] = useState("default");
  const [toolSelected, setToolSelected] = useState("move");
  const [radius, setRadius] = useState(25);

  const [file, setFile] = useState("");
  const [originalDimensions, setOriginalDimensions] = useState(undefined);
  const [isCropVisible, setIsCropVisible] = useState(false);
  const [largeImageWarning, setLargeImageWarning] = useState(false);

  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: null,
    y: null,
    width: null,
    height: null,
  });

  const { saveSVG, loading: savingInProgress } = useSaveSVG(
    svgJoined,
    currentFile
  );

  useEffect(() => {
    if (layerSelected === "all") {
      setToolbarState("default");
      setToolSelected("move");
    }
  }, [layerSelected]);

  return (
    <>
      <IonLoading isOpen={savingInProgress} message={"Saving..."} />
      <AutoUploadImage
        setOriginalDimensions={setOriginalDimensions}
        setCrop={setCrop}
        setLargeImageWarning={setLargeImageWarning}
        setFile={setFile}
        setColors={setColors}
        layerSelected={layerSelected}
      />
      <IonHeader>
        <IonToolbar>
          <Close
            changesMade={changesMade}
            svgJoined={svgJoined}
            currentFile={currentFile}
          >
            <FontAwesomeIcon icon={faX} style={{ marginLeft: "20px" }} />
          </Close>
          <IonRow>
            <TopBar
              undo={undo}
              redo={redo}
              undoActions={undoActions}
              redoActions={redoActions}
            />
            <Button
              type="text"
              disabled={!changesMade || savingInProgress}
              onClick={async () => {
                await saveSVG();
                setChangesMade(false);
              }}
            >
              <Row justify="center">
                <Save size={14} />
              </Row>
            </Button>
            <Button
              type="text"
              onClick={() => {
                document.getElementById("AutoConvertUpload").click();
                setIsCropVisible(true);
              }}
            >
              <Row justify="center">
                <Crop size={14} />
              </Row>
            </Button>
            <IonTitle>Editor</IonTitle>
          </IonRow>
          <Col slot="end">
            <Tutorial svgJoined={svgJoined} />
          </Col>
          <Col slot="end">
            <ExportFile svgJoined={svgJoined}>
              <Row justify="center">
                <FontAwesomeIcon icon={faArrowUpFromBracket} size={"sm"} />
              </Row>
              <Row justify="center" style={{ fontSize: "10px" }}>
                Export
              </Row>
            </ExportFile>
          </Col>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid id="middleBox" style={{ height: "100%", padding: "0px" }}>
          <IonRow style={{ height: "100%" }}>
            <Col
              flex="auto"
              style={{
                backgroundColor: "#f0f0f0",
                height: "calc(100%)",
                overflowX: "scroll",
                cursor: cursor,
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <div style={{ height: "100%" }}>
                <Canvas
                  svg={svg}
                  svgJoined={svgJoined}
                  setSvg={updateSvg}
                  layerSelected={layerSelected}
                  toolSelected={toolSelected}
                  radius={radius}
                  setCursor={setCursor}
                />

                <CropImage
                  originalDimensions={originalDimensions}
                  setOriginalDimensions={setOriginalDimensions}
                  cropGlobal={crop}
                  setCropGlobal={setCrop}
                  largeImageWarning={largeImageWarning}
                  file={file}
                  isModalVisible={isCropVisible}
                  setIsModalVisible={setIsCropVisible}
                  updateSvg={updateSvg}
                  svg={svg}
                />
              </div>
            </Col>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <Row
            justify="center"
            style={{ paddingTop: "5px", paddingBottom: "5px", width: "100%" }}
          >
            {colors && (
              <LayerSelector
                setLayerSelected={setLayerSelected}
                colors={colors}
                layerSelected={layerSelected}
              />
            )}
          </Row>
          <ToolBar
            colors={colors}
            layerSelected={layerSelected}
            setColors={setColors}
            setLayerSelected={setLayerSelected}
            setToolSelected={setToolSelected}
            setToolbarState={setToolbarState}
            svg={svg}
            toolSelected={toolSelected}
            toolbarState={toolbarState}
            updateSvg={updateSvg}
            radius={radius}
            setRadius={setRadius}
          />
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default SvgEditorMobile;
