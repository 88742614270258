import { IonIcon } from "@ionic/react";
import { checkmarkCircle, ellipseOutline } from "ionicons/icons";
import { PLANS } from "./constants";

const PlanSelection = ({ selectedPlan, onPlanSelect }) => (
  <div className="plan-selection">
    {PLANS.map((plan) => (
      <div
        key={plan.type}
        className={`plan-option ion-activatable ripple-parent ${
          selectedPlan === plan.type ? "selected" : ""
        }`}
        onClick={() => onPlanSelect(plan.type)}
      >
        <div className="plan-content">
          <div className="plan-details">
            <IonIcon
              icon={
                selectedPlan === plan.type ? checkmarkCircle : ellipseOutline
              }
              className="plan-icon"
            />
            <span className="plan-label">{plan.label}</span>
            {plan.savings && (
              <span className="best-value-badge">{plan.savings}</span>
            )}
          </div>
          <div className="plan-price">
            <span className="price">{plan.price}</span>
            {plan.perMonth && (
              <span className="per-month">{plan.perMonth}</span>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default PlanSelection;
