import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { logoApple } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  handleSignIn,
  signInWithApple,
  signInWithGoogle,
} from "../../lib/firebase/firebase";
import AuthHeader from "./components/AuthHeader/AuthHeader";
import AuthPrompt from "./components/AuthPrompt";
// import logo
import googleicon from "../../assets/google.svg";
import logo from "../../assets/logo1.svg";

// import font awesome pro icon
import { faCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import antd tag
import { Tag } from "antd";
// import signin.css
import { lockClosedOutline, mailOutline } from "ionicons/icons";
import "./SignIn.css";

const SignIn = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getReadableError = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        return "The email you have entered is not valid. Please check your email and try again.";
      case "auth/user-disabled":
        return "Your account has been disabled. Please contact support for help.";
      case "auth/user-not-found":
        return "No account found with this email. Please check your email or create a new account.";
      case "auth/wrong-password":
        return "Incorrect password entered. Please try again.";
      default:
        return errorCode;
    }
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // 13 is the key code for the Enter key
      onSubmit();
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      // setLoading(true);
      const result = await signInWithGoogle();
      const user = result.user;
      if (result.additionalUserInfo?.isNewUser) {
        console.log("New user signed up with Google:", user);
        // You can add additional sign-up logic here, e.g., redirecting to a profile completion page
        history.push("/complete-profile");
      } else {
        console.log("Existing user signed in with Google:", user);
        // Optionally handle cases where the user already exists
        history.push("/");
      }
    } catch (error) {
      console.error("Error during Google sign-up:", error);
      setError("Failed to sign up with Google. Please try again.");
    } finally {
      // setLoading(false);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      // setLoading(true);
      const result = await signInWithApple(); // This function needs to be implemented in your firebase interactions
      const user = result.user;
      if (result.additionalUserInfo?.isNewUser) {
        console.log("New user signed up with Apple:", user);
        history.push("/");
      } else {
        console.log("Existing user signed in with Apple:", user);
        history.push("/");
      }
    } catch (error) {
      console.error("Error during Apple sign-up:", error);
      setError("Failed to sign up with Apple. Please try again.");
    } finally {
      // setLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!email || !password) {
      setError("Please fill in all fields");
      return;
    }
    try {
      setLoading(true);
      await handleSignIn(email, password);
    } catch (error) {
      // Handle sign-in error here
      console.error("Sign-in error:", error);
      setError(getReadableError(error.code));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) history.push("/");
  }, [user]);

  return (
    <IonPage>
      <IonContent className="background">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="logo" width="200px" />

            <Tag color="gold">
              <FontAwesomeIcon icon={faCrown} size="1x" /> PRO
            </Tag>
          </div>
          <AuthHeader
            text={{
              heading: "Welcome Back!",
              text: "Its good to see you again. Please login to your account.",
            }}
          />

          {error && <IonText className="error">{error}</IonText>}
          <form className="form" style={{ marginBottom: "10px" }}>
            <IonItem>
              <IonLabel position="floating">
                <IonIcon
                  icon={mailOutline}
                  style={{ transform: "translateY(2px)", paddingRight: "5px" }}
                />{" "}
                Email
              </IonLabel>
              <IonInput
                type="email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value)}
                required
              />
            </IonItem>

            <IonItem>
              <IonLabel position="floating">
                <IonIcon
                  icon={lockClosedOutline}
                  style={{ transform: "translateY(2px)", paddingRight: "5px" }}
                />
                Password
              </IonLabel>
              <IonInput
                type="password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value)}
                onKeyDown={handleKeyDown} // Add the keydown event listener
                required
              />
            </IonItem>
            <div className="links">
              <IonRouterLink
                routerLink="/forgot-password"
                className="forgot_password"
              >
                Forgot Password?
              </IonRouterLink>
            </div>

            <IonButton
              expand="block"
              onClick={onSubmit}
              disabled={loading}
              className="submit-button"
            >
              {loading ? <IonSpinner name="lines" /> : "Login"}
            </IonButton>
            {/* <div className="links">
                  <IonRouterLink routerLink="/signup">
                    Don't have an account? Signup
                  </IonRouterLink>
                  <IonRouterLink routerLink="/forgot-password">
                    Forgot Password?
                  </IonRouterLink>
                </div> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#ccc" }}
              ></div>
              <div style={{ padding: "0 10px" }}>OR</div>
              <div
                style={{ flex: 1, height: "1px", backgroundColor: "#ccc" }}
              ></div>
            </div>
            {/* Existing login form elements... */}
            <div className="social-buttons-container">
              <IonButton
                color="default"
                style={{
                  fontSize: "11px",
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
                onClick={handleGoogleSignIn} // Updated to use the new handler for sign-up
              >
                <IonIcon icon={googleicon} style={{ marginRight: "5px" }} />{" "}
                Google
              </IonButton>
              <IonButton
                color="default"
                style={{
                  fontSize: "11px",
                  width: "100%",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
                onClick={handleAppleSignIn}
              >
                <IonIcon icon={logoApple} style={{ marginRight: "5px" }} />{" "}
                Apple
              </IonButton>
            </div>
            {/* Rest of the existing form and components... */}
          </form>
          <AuthPrompt
            text={"Don't have an account? "}
            ctaText={"Sign Up"}
            link={"signup"}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
